import "./App.css";
import Main from "./Component/Main";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Privacy from "./Component/Privacy";
import Contact from "./Component/Contact";
import Terms from "./Component/Terms";
import Subscribe from "./Component/Subscribe";
import Cookie from "./Component/Cookie";
import Podcasts from "./Component/Podcasts";
import Membership from "./Component/Membership";
import About from "./Component/About";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/about" element={<About />} />
        <Route path="/membership" element={<Membership />} />
        <Route path="/podcasts" element={<Podcasts />} />
        <Route path="/pricing" element={<Subscribe />} />
        <Route path="/cookie" element={<Cookie />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
      </Routes>
    </Router>
  );
}

export default App;
