import "../css/header.css";
import React from "react";
import { Link } from "react-router-dom";

function Header() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <div
        data-animation="default"
        data-collapse="medium"
        data-duration={400}
        data-easing="ease"
        data-easing2="ease"
        role="banner"
        className="navbar w-nav"
      >
        <div className="navbar_container">
          <Link
            onClick={toTop}
            to="/"
            aria-current="page"
            className="navbar_logo-link w-nav-brand w--current"
            aria-label="home"
          >
            <img
              src="/head/logo.png"
              loading="lazy"
              alt=""
              className="navbar_logo-image"
            />
          </Link>
          <div
            style={{ justifyContent: "end" }}
            className="navbar_button-wrapper"
          >
            <Link
              onClick={toTop}
              className="button w-button"
              data-planurl="v2_kickstarter_monthly"
              to="/pricing"
              data-pricing-trigger=""
              data-w-id="d229deb7-cb8f-dcee-bb00-0e0027872fe4"
              data-track-event="Lead Form Viewed"
              data-signupsource="kickstarter69"
              data-elementreferrer="Nav Trial"
              data-engagement-click={20}
            >
              Subscribe →
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
