import React from "react";
import "../css/privacy.css";
import Footer from "./Footer";
import Header from "./Header";

function Cookie() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <body>
      <div className="page-wrapper">
        <Header />
        <main className="main-wrapper">
          <section className="section_header">
            <div className="padding-global padding-section-medium">
              <div className="container-large">
                <div className="breadcrumb_wrapper">
                  <a href="/" className="breadcrumb_link text-style-muted">
                    Policies
                  </a>
                  <div className="text-style-muted">→</div>
                  <a
                    href="/cookie"
                    aria-current="page"
                    className="breadcrumb_link w--current"
                  >
                    Policies
                  </a>
                </div>
                <div className="padding-bottom padding-large" />
                <div className="max-width-large">
                  <h1 className="heading-style-h3-feature">Cookie Notice</h1>
                  <div className="padding-bottom padding-small" />
                  <div className="w-embed">
                    <div>Last Edit: Jan 10, 2024</div>
                  </div>
                </div>
                <div className="padding-bottom padding-large" />
                <div
                  fs-richtext-element="rich-text"
                  className="text-rich-text w-richtext"
                >
                  <h3>
                    ‍<strong>I. Introduction</strong>
                  </h3>
                  <p>
                    Kajabi, LLC uses cookies and similar technologies on its
                    Platform. You can find out how we use cookies and how to
                    control them in this Cookie Notice. Capitalized terms used
                    in this Cookie Notice (but not defined here) will have the
                    meanings given to them in our Privacy Notice.
                  </p>
                  <h3>
                    ‍
                    <strong>
                      II. What Are Cookies, Pixels, and Local Storage?
                    </strong>
                  </h3>
                  <p>
                    Cookies are small files that websites place on your computer
                    as you browse the web. Like many websites, Kajabi uses
                    cookies to discover how people are using our Platform and to
                    make them work better. Cookies do lots of different jobs,
                    like letting you navigate between pages efficiently,
                    remembering your preferences, and generally improving the
                    user experience. Cookies and other technologies may also be
                    used to measure visitors to the Platform and overall
                    performance, as well as assist us with marketing on other
                    platforms.
                  </p>
                  <p>
                    A cookie can be classified by its lifespan and the domain to
                    which it belongs. By lifespan, a cookie is either a session
                    cookie, which is erased when the user closes the browser, or
                    persistent cookie, which remains on the user’s
                    computer/device for a pre-defined period of time. We use
                    both session and persistent cookies, and enable first and
                    third-party cookies. Session cookies are destroyed when you
                    terminate your browsing session by shutting down your
                    browser. Stored cookies often have a predetermined
                    expiration date after which they disappear from your hard
                    drive. As for the domain to which it belongs, there are
                    either: first-party cookies, which are set by us, or
                    third-party cookies stored by a different domain to the
                    visited page’s domain.
                  </p>
                  <p>
                    Other similar technologies also measure activity on the
                    Platform. A pixel is a small amount of code on a web page or
                    in an email notification. Like most online companies, we use
                    pixels to learn whether you’ve interacted with certain web
                    or email content. This helps us measure and improve our
                    Platform and personalize your experience. Local storage is
                    an industry-standard technology that allows a website or
                    application to store information locally on your device. We
                    use local storage to customize what we show you based on
                    your past interactions with our Platform.
                  </p>
                  <h3>
                    ‍<strong>III. How And Why Do We Use Cookies?</strong>
                  </h3>
                  <p>
                    ‍<em>Strictly Necessary Cookies</em>
                  </p>
                  <p>
                    These cookies are necessary for the Platform to function and
                    cannot be switched off in our systems. They are usually only
                    set in response to actions made by you, which amount to a
                    request for access to our Platform, such as setting your
                    privacy preferences, logging in or filling in forms. You can
                    set your browser to block or alert you about these cookies,
                    but if you do, some parts of the Platform will not work.
                  </p>
                  <p>
                    ‍<em>Functional Cookies</em>
                  </p>
                  <p>
                    These cookies enable the Platform to provide enhanced
                    functionality and personalization. They may be set by us or
                    by third-party providers. If you do not allow these cookies,
                    then some or all of these services may not function
                    properly.
                  </p>
                  <p>
                    ‍<em>Performance Cookies</em>
                  </p>
                  <p>
                    These cookies allow us to count visits and traffic sources
                    so we can measure and improve the performance of our
                    Platform. They help us to know which pages are the most and
                    least popular and see how visitors navigate the Platform.
                    Performance cookies are used to help us with our analytics,
                    including to compile statistics and analytics about your use
                    of and interaction with the Platform.
                  </p>
                  <p>
                    We use third-party tools and technologies to help us gather
                    this information.
                  </p>
                  <p>
                    ‍<em>Targeting Cookies</em>
                  </p>
                  <p>
                    Kajabi partners with third parties, as explained here, to
                    promote our services and market to you on third-party
                    websites, such as social media platforms, after you visit
                    our Platform. By setting targeting cookies on our Platform,
                    our marketing partners can build a profile of your interests
                    and show you relevant advertisements on other websites.
                    These third-party websites may use the personal information
                    that they receive from us to enhance their profiles of you,
                    so please visit their respective websites, privacy notices
                    and settings.
                  </p>
                  <p>
                    Note that if you do not allow these cookies, you will
                    experience less targeted advertising, but this will not
                    eliminate all advertising, such as contextual advertising.
                  </p>
                  <p>
                    For more information about online advertising and your
                    choices, please visit{" "}
                    <a href="https://youradchoices.com/">
                      https://youradchoices.com/
                    </a>
                    . You can also opt-out of the use of your data for
                    interest-based ads served by other advertising networks on
                    your mobile device or computer.
                  </p>
                  <p>
                    By clicking on the AdChoices icon &nbsp;on any
                    interest-based ad.
                  </p>
                  <p>
                    By visiting{" "}
                    <a href="https://youradchoices.com/control">
                      https://youradchoices.com/control
                    </a>
                    .
                  </p>
                  <h3>
                    <strong>IV. What cookies does Kajabi use?</strong>
                  </h3>
                  <p>The following is a list of the cookies we use:</p>
                  <div
                    fs-richtext-component="cookies"
                    className="cookie-wrapper"
                  >
                    <div className="cookie-row is-header">
                      <div id="w-node-b7f5a333-a1dd-d701-3b2b-f0e0394eb92c-260fd489">
                        Cookie Name
                      </div>
                      <div id="w-node-b7f5a333-a1dd-d701-3b2b-f0e0394eb92e-260fd489">
                        Description
                      </div>
                      <div id="w-node-b7f5a333-a1dd-d701-3b2b-f0e0394eb930-260fd489">
                        Purpose
                      </div>
                      <div id="w-node-b7f5a333-a1dd-d701-3b2b-f0e0394eb932-260fd489">
                        Cookie Category
                      </div>
                    </div>
                    <div className="cookie-list">
                      <div className="cookie-collection w-dyn-list">
                        <div role="list" className="w-dyn-items">
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  rs_ga
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie is used to persist session state.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie is used to persist session state.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Analytics &amp; Customization
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  _sp_id*
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie stores a unique identifier for
                                  each user, a unique identifier for the users
                                  current session, the number of visits a user
                                  has made to the site, the timestamp of the
                                  users first visit, the timestamp of their
                                  previous visit and the timestamp of their
                                  current visit.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie stores a unique identifier for
                                  each user, a unique identifier for the users
                                  current session, the number of visits a user
                                  has made to the site, the timestamp of the
                                  users first visit, the timestamp of their
                                  previous visit and the timestamp of their
                                  current visit.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Analytics &amp; Customization
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  _tldtest__*
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  Registers unique ID that is used to generate
                                  statistics.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  Registers unique ID that is used to generate
                                  statistics.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Analytics &amp; Customization
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  rl_anonymous_id
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie is used store the anonymous ID.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie is used store the anonymous ID.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Analytics &amp; Customization
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  _tldtest_*
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  Registers unique ID that is used to generate
                                  statistics.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  Registers unique ID that is used to generate
                                  statistics.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Analytics &amp; Customization
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  AnalyticsSyncHistory
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  Used to store information about the time a
                                  sync took place with the lms_analytics cookie.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  Used to store information about the time a
                                  sync took place with the lms_analytics cookie.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Analytics &amp; Customization
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  _shopify_s
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie is associated with Shopify's
                                  analytics suite.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie is associated with Shopify's
                                  analytics suite.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Analytics &amp; Customization
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  _shopify_sa_t
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie is used for analytics relating to
                                  marketing &amp; referrals.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie is used for analytics relating to
                                  marketing &amp; referrals.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Analytics &amp; Customization
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  TapAd_TS
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie is set through our site by our
                                  advertising partners.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie is set through our site by our
                                  advertising partners.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  AMP_TLDTEST
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  Registers statistical data on users' behaviour
                                  on the website. Used for internal analytics by
                                  the website operator.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  Registers statistical data on users' behaviour
                                  on the website. Used for internal analytics by
                                  the website operator.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Analytics &amp; Customization
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  _ga_*
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  Used to persist session state.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  Used to throttle request rate.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Analytics &amp; Customization
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  _shopify_sa_p
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie is used for analytics relating to
                                  marketing &amp; referrals.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie is used for analytics relating to
                                  marketing &amp; referrals.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Analytics &amp; Customization
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  _shopify_y
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie is associated with Shopify's
                                  analytics suite.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie is associated with Shopify's
                                  analytics suite.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Analytics &amp; Customization
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  _ga_*
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie is used to persist session state.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie is used to persist session state.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Analytics &amp; Customization
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  _ga
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  Used to distinguish users.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  Used to distinguish users.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Analytics &amp; Customization
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  AMP_MKTG_*
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  Registers statistical data on users' behaviour
                                  on the website. Used for internal analytics by
                                  the website operator.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  Registers statistical data on users' behaviour
                                  on the website. Used for internal analytics by
                                  the website operator.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Analytics &amp; Customization
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  personalization_id
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie is used to deliver adverts more
                                  relevant to you and your interests.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie is used to deliver adverts more
                                  relevant to you and your interests.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  _ga
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  Used to distinguish users.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  Used to distinguish users.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Analytics &amp; Customization
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  msToken
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  Collects information on user behaviour on
                                  multiple websites. This information is used in
                                  order to optimize the relevance of
                                  advertisement on the website.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  Collects information on user behaviour on
                                  multiple websites. This information is used in
                                  order to optimize the relevance of
                                  advertisement on the website.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  msToken
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  Collects information on user behaviour on
                                  multiple websites. This information is used in
                                  order to optimize the relevance of
                                  advertisement on the website.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  Collects information on user behaviour on
                                  multiple websites. This information is used in
                                  order to optimize the relevance of
                                  advertisement on the website.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  ttwid
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie is used to track user’s
                                  interaction with embedded content.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie is used to track user’s
                                  interaction with embedded content.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  _ttp
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie is used to measure and improve the
                                  performance of your advertising campaigns and
                                  to personalize the user's experience on site.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie is used to measure and improve the
                                  performance of your advertising campaigns and
                                  to personalize the user's experience on site.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  _kjb_session
                                </div>
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489"
                                  className="w-dyn-bind-empty"
                                />
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489"
                                  className="w-dyn-bind-empty"
                                />
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  TapAd_DID
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  Cookie used for marketing purpose of the
                                  website.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  Cookie used for marketing purpose of the
                                  website.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  arc_id
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie is used to uniquely identify users
                                  across sessions or visits to the website
                                </div>
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489"
                                  className="w-dyn-bind-empty"
                                />
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  sp
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  Stores a server-side collector generated
                                  unique identifier for a user that is sent with
                                  all subsequent tracking event events. Can be
                                  used as a first party cookie is the collector
                                  is on the same domain as the site.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  Used to implement audio-content from Spotify
                                  on the website. Can also be used to register
                                  user interaction and preferences in context
                                  with audio-content - This can serve statistics
                                  and marketing purposes.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  UserMatchHistory
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie is used as ads ID syncing.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie is used as ads ID syncing.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  ar_debug
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie is used to store and track
                                  conversions.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie is used to store and track
                                  conversions.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  tt_sessionId
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie is used by the social networking
                                  service, for tracking the use of embedded
                                  services.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie is used by the social networking
                                  service, for tracking the use of embedded
                                  services.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  tt_pixel_session_index
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie is used by the social networking
                                  service, for tracking the use of embedded
                                  services.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie intention is to display ads that
                                  are relevant and engaging for the individual
                                  user and thereby more valuable for publishers
                                  and third party advertisers.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  wpm-test-cookie
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This is an advertising cookie from Kajabi.
                                  Kajabi is a knowledge e-commerce platform that
                                  helps entrepreneurs build, market, and sell
                                  educational content online.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This is an advertising cookie from Kajabi.
                                  Kajabi is a knowledge e-commerce platform that
                                  helps entrepreneurs build, market, and sell
                                  educational content online.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  tracking_feature_flag
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This is an advertising cookie from Kajabi.
                                  Kajabi is a knowledge e-commerce platform that
                                  helps entrepreneurs build, market, and sell
                                  educational content online.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This is an advertising cookie from Kajabi.
                                  Kajabi is a knowledge e-commerce platform that
                                  helps entrepreneurs build, market, and sell
                                  educational content online.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  tt_appInfo
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie is used by the social networking
                                  service for tracking the use of embedded
                                  services.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie is used by the social networking
                                  service for tracking the use of embedded
                                  services.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  time_zone
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This is an advertising tracker from Jollychic.
                                  Jollychic is an e-commerce platform that sells
                                  clothing and accessories online.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This is an advertising tracker from Jollychic.
                                  Jollychic is an e-commerce platform that sells
                                  clothing and accessories online.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  rbuid
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  Represents the unique user ID assigned by the
                                  website for tracking purpose.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  The goal is to display advertisements that are
                                  relevant and interesting to the individual
                                  user and therefore more valuable to
                                  third-party publishers and advertisers.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  rudder_*
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This is an advertising cookie from Kajabi.
                                  Kajabi is a knowledge e-commerce platform that
                                  helps entrepreneurs build, market, and sell
                                  educational content online.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This is an advertising cookie from Kajabi.
                                  Kajabi is a knowledge e-commerce platform that
                                  helps entrepreneurs build, market, and sell
                                  educational content online.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  rudder.*
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This is an advertising cookie from Kajabi.
                                  Kajabi is a knowledge e-commerce platform that
                                  helps entrepreneurs build, market, and sell
                                  educational content online.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This is an advertising cookie from Kajabi.
                                  Kajabi is a knowledge e-commerce platform that
                                  helps entrepreneurs build, market, and sell
                                  educational content online.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  is_eu
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  Returns true or false depending on whether the
                                  country is a recognized member of the European
                                  Union.
                                </div>
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489"
                                  className="w-dyn-bind-empty"
                                />
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  rs_ga_4XSF0VL22B
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This is an advertising cookie from Kajabi.
                                  Kajabi is a knowledge e-commerce platform that
                                  helps entrepreneurs build, market, and sell
                                  educational content online.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This is an advertising cookie from Kajabi.
                                  Kajabi is a knowledge e-commerce platform that
                                  helps entrepreneurs build, market, and sell
                                  educational content online.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  rl_auth_token
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This is an advertising cookie from Kajabi.
                                  Kajabi is a knowledge e-commerce platform that
                                  helps entrepreneurs build, market, and sell
                                  educational content online.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This is an advertising cookie from Kajabi.
                                  Kajabi is a knowledge e-commerce platform that
                                  helps entrepreneurs build, market, and sell
                                  educational content online.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  ip_country
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This is an advertising tracker from Beeketing.
                                  Marketing Platform for E-Commerce.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This is an advertising tracker from Beeketing.
                                  Marketing Platform for E-Commerce.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  TapAd_3WAY_SYNCS
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie is set through our site by our
                                  advertising partners.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie is set through our site by our
                                  advertising partners.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  li_adsId
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie helps identify and track users for
                                  advertising purposes, enabling the site and
                                  advertising partners to offer more
                                  personalized and relevant advertising.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie helps identify and track users for
                                  advertising purposes, enabling the site and
                                  advertising partners to offer more
                                  personalized and relevant advertising.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  https://analytics.twitter.com/i/adsct?bci=3&amp;eci=2&amp;event_id=959dcee5-c913-4c49-87fc-fb6cf8cd57bb&amp;events=%5B%5B%22pageview%22%2C%7B%7D%5D%5D&amp;integration=advertiser&amp;p_id=Twitter&amp;p_user_id=0&amp;pl_id=8ea00868-61c4-405e-bc51-1b78b200ad0f&amp;tw_document_href=https%3A%2F%2Fkajabi.com%2Fcreator-stories%2Fkatie-ferro&amp;tw_iframe_status=0&amp;tw_order_quantity=0&amp;tw_sale_amount=0&amp;txn_id=nvaxb&amp;type=javascript&amp;version=2.3.30
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This is an advertising cookie from Kajabi.
                                  Kajabi is a knowledge e-commerce platform that
                                  helps entrepreneurs build, market, and sell
                                  educational content online.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This is an advertising cookie from Kajabi.
                                  Kajabi is a knowledge e-commerce platform that
                                  helps entrepreneurs build, market, and sell
                                  educational content online.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  dicbo_id
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie is set through the site by its
                                  advertising partner. It is used by those
                                  companies to build a profile of your interests
                                  and show you relevant adverts on other sites.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie is set through the site by its
                                  advertising partner. It is used by those
                                  companies to build a profile of your interests
                                  and show you relevant adverts on other sites.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  form2_visited
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This is an advertising cookie from Kajabi.
                                  Kajabi is a knowledge e-commerce platform that
                                  helps entrepreneurs build, market, and sell
                                  educational content online.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This is an advertising cookie from Kajabi.
                                  Kajabi is a knowledge e-commerce platform that
                                  helps entrepreneurs build, market, and sell
                                  educational content online.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  countryCode
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This is an advertising cookie from NBA. NBA is
                                  a sports company that specializes in
                                  basketball,entertainment, live events &amp;
                                  Programming.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This is an advertising cookie from NBA. NBA is
                                  a sports company that specializes in
                                  basketball,entertainment, live events &amp;
                                  Programming.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  https://t.co/i/adsct?bci=3&amp;eci=2&amp;event_id=27dd58f7-296f-4417-88b8-00999ec5f1e9&amp;events=%5B%5B%22pageview%22%2C%7B%7D%5D%5D&amp;integration=advertiser&amp;p_id=Twitter&amp;p_user_id=0&amp;pl_id=70cad7f9-b7df-42eb-97ea-a5b74255e3cf&amp;tw_document_href=https%3A%2F%2Fkajabi.com%2Finfo%2Fdata-request&amp;tw_iframe_status=0&amp;tw_order_quantity=0&amp;tw_sale_amount=0&amp;txn_id=nvaxb&amp;type=javascript&amp;version=2.3.30
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This is an advertising cookie from Kajabi.
                                  Kajabi is a knowledge e-commerce platform that
                                  helps entrepreneurs build, market, and sell
                                  educational content online.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This is an advertising cookie from Kajabi.
                                  Kajabi is a knowledge e-commerce platform that
                                  helps entrepreneurs build, market, and sell
                                  educational content online.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  dd_cookie_test_ffe2eeb2-2fd2-4067-84b6-7260f495c1e0
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This is an advertising cookie from Kajabi.
                                  Kajabi is a knowledge e-commerce platform that
                                  helps entrepreneurs build, market, and sell
                                  educational content online.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This is an advertising cookie from Kajabi.
                                  Kajabi is a knowledge e-commerce platform that
                                  helps entrepreneurs build, market, and sell
                                  educational content online.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  alert
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This is an advertising cookie from Wayra.
                                  Wayra is a globally connected and
                                  technologically open innovation hub.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This is an advertising cookie from Wayra.
                                  Wayra is a globally connected and
                                  technologically open innovation hub.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  _tracking_consent
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This is an advertising tracker from Afends.
                                  Afends is an online organic hemp fashion and
                                  sustainable clothing store for men and women
                                  with a concept store facility.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This is an advertising tracker from Afends.
                                  Afends is an online organic hemp fashion and
                                  sustainable clothing store for men and women
                                  with a concept store facility.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  ajs_user_id
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie is used to track a known user
                                  across multiple visits.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  Collects data on visitor's preferences and
                                  behavior on the website. This information is
                                  used to make content and advertisement more
                                  relevant to the specific visitor.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  _uetvid
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie is used as anonymized visitor ID,
                                  representing a unique visitor.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  Collects data on visitor behaviour from
                                  multiple websites, in order to present more
                                  relevant advertisement - This also allows the
                                  website to limit the number of times that they
                                  are shown the same advertisement.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  ajs_anonymous_id
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie is used to track the user across
                                  multiple visits.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  Enables Segment to track user activities.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  _ttp
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie is used to measure and improve the
                                  performance of your advertising campaigns and
                                  to personalize the user's experience on site.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This is an advertising cookie from Kajabi.
                                  Kajabi is a knowledge commerce platform which
                                  helps people package and sell their knowledge
                                  about any subject matter to others.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  _gcl_au
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie is used by advertisers to measure
                                  user activity and the performance of their ad
                                  campaigns.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  Experimenting with advertisement efficiency
                                  across websites using their services.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  _rdt_uuid
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie collects data on the user's visits
                                  to the website, such as what pages have been
                                  loaded. The registered data is used for
                                  targeted ads.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie helps build a profile of your
                                  interests and show you relevant ads.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  _tt_enable_cookie
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie is used to measure and improve the
                                  performance of the advertising campaigns and
                                  to personalize the user's experience
                                  (including ads).
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This is an advertising cookie from Kajabi.
                                  Kajabi is a knowledge commerce platform which
                                  helps people package and sell their knowledge
                                  about any subject matter to others.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  _pin_unauth_ls
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie is a first party cookie placed by
                                  the Pinterest tag when we are unable to match
                                  the user. It contains a unique UUID to group
                                  actions across pages.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie is a first party cookie placed by
                                  the Pinterest tag when we are unable to match
                                  the user. It contains a unique UUID to group
                                  actions across pages.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  _fbp
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie identifies browsers for the
                                  purposes of providing advertising and site
                                  analytics services.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  Used to deliver a series of advertisement
                                  products such as real time bidding from
                                  third-party advertisers.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  NID
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie is used for purposes to show ads
                                  in services for signed-out users.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie is used for purposes to show ads
                                  in services for signed-out users.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  _nccapi_url
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  Likely stores an API URL for network requests.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  Likely stores an API URL for network requests.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  YSC
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie is used to remember user input and
                                  associate a user’s actions.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie is used to remember user input and
                                  associate a user’s actions.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Performance &amp; Functionality
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  __tt_embed__mounting
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie registers data on the performance
                                  of the website’s embedded video-content.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie registers data on the performance
                                  of the website’s embedded video-content.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  __tt_embed__storage_test
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie registers data on the performance
                                  of the website’s embedded video-content.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie registers data on the performance
                                  of the website’s embedded video-content.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  uuid
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  The UUID enables you to track users over
                                  different visits (on your first-party domain),
                                  so that they can deliver relevant advertising
                                  to you.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  Collects data on the user's visits to the
                                  website, such as what pages have been loaded.
                                  The registered data is used for targeted ads.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  IDE
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This is used for serving targeted
                                  advertisements that are relevant to you across
                                  the web.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This is used for serving targeted
                                  advertisements that are relevant to you across
                                  the web.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  test_cookie
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This is used for serving targeted
                                  advertisements that are relevant to you across
                                  the web.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This is used for serving targeted
                                  advertisements that are relevant to you across
                                  the web.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  RB.uid
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  The goal is to display advertisements that are
                                  relevant and interesting to the individual
                                  user and therefore more valuable to
                                  third-party publishers and advertisers.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  The goal is to display advertisements that are
                                  relevant and interesting to the individual
                                  user and therefore more valuable to
                                  third-party publishers and advertisers.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  MSPTC
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie registers data on the visitor. The
                                  information is used to optimize advertisement
                                  relevance.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie registers data on the visitor. The
                                  information is used to optimize advertisement
                                  relevance.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  __tld__
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie is used to track visitors on
                                  multiple websites, in order to present
                                  relevant advertisement based on the visitor's
                                  preferences.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie is used to track visitors on
                                  multiple websites, in order to present
                                  relevant advertisement based on the visitor's
                                  preferences.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  __tld__
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie is used to track visitors on
                                  multiple websites, in order to present
                                  relevant advertisement based on the visitor's
                                  preferences.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie collects information that is used
                                  either in aggregate form to help us understand
                                  how our Website is being used or how effective
                                  our marketing campaigns are, or to help us
                                  customise our Website for you.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  _pinterest_ct_ua
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie is Identical to _pin_unauth, but
                                  as a third party cookie.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie is Identical to _pin_unauth, but
                                  as a third party cookie.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  dd_cookie_test_c0093104-25cf-4253-adba-2634d65a8c77
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This is an advertising cookie from Kajabi.
                                  Kajabi is a knowledge e-commerce platform that
                                  helps entrepreneurs build, market, and sell
                                  educational content online.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This is an advertising cookie from Kajabi.
                                  Kajabi is a knowledge e-commerce platform that
                                  helps entrepreneurs build, market, and sell
                                  educational content online.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  MUID
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie registers a unique ID to recognise
                                  returning visitors. The ID is used for
                                  targeted advertising.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie registers a unique ID to recognise
                                  returning visitors. The ID is used for
                                  targeted advertising.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  ab
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  Collects data on user visits to the website,
                                  such as what pages have been accessed. The
                                  registered data is used to categorise the user
                                  interest and demographic profiles in terms of
                                  resales for targeted marketing.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  Stores information about ad campaigns to show
                                  targeted ads to the visitor.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  LAST_RESULT_ENTRY_KEY
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie is used to store the user's video
                                  player preferences using embedded YouTube
                                  video.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  Stores the user's video player preferences
                                  using embedded YouTube video.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Performance &amp; Functionality
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  _kjb_ua_components
                                </div>
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489"
                                  className="w-dyn-bind-empty"
                                />
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489"
                                  className="w-dyn-bind-empty"
                                />
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  lidc
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  To facilitate data center selection.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  To facilitate data center selection.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Performance &amp; Functionality
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  remote_sid
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie is used to allow us to embed
                                  videos.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie is used to allow us to embed
                                  videos.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Performance &amp; Functionality
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  _sp_root_domain_test_*
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie is used to store information about
                                  your preferences and to personalise the
                                  website.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie is used to store information about
                                  your preferences and to personalise the
                                  website.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Performance &amp; Functionality
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  kjb_signup_id
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This is an advertising cookie from Kajabi.
                                  Kajabi is a knowledge e-commerce platform that
                                  helps entrepreneurs build, market, and sell
                                  educational content online.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This is an advertising cookie from Kajabi.
                                  Kajabi is a knowledge e-commerce platform that
                                  helps entrepreneurs build, market, and sell
                                  educational content online.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  fr
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie is used to deliver, measure and
                                  improve the relevancy of ads.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie is used to deliver, measure and
                                  improve the relevancy of ads.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Advertising
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  VISITOR_INFO1_LIVE
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie is used to measure user's
                                  bandwidth to determine whether user gets the
                                  new player interface or the old.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie is used to measure user's
                                  bandwidth to determine whether user get the
                                  new player interface or the old.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Performance &amp; Functionality
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  requests
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This is a video embed service use to host
                                  video content on the site.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This is a video embed service use to host
                                  video content on the site.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Performance &amp; Functionality
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  nextId
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  Registers a unique ID to keep statistics of
                                  what videos from YouTube the user has seen.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  Registers a unique ID to keep statistics of
                                  what videos from YouTube the user has seen.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Performance &amp; Functionality
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  keep_alive
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  Used in connection with buyer localization.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  Used in connection with buyer localization.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Performance &amp; Functionality
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  VISITOR_PRIVACY_METADATA
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie is used to remember user input and
                                  associate a user’s actions.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie is used to remember user input and
                                  associate a user’s actions.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Performance &amp; Functionality
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  TESTCOOKIESENABLED
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie used to save individual comfort
                                  settings you have selected and keep them for
                                  your current and future visits to the site.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie used to save individual comfort
                                  settings you have selected and keep them for
                                  your current and future visits to the site.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Performance &amp; Functionality
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  hmt_id
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  Used for strictly necessary anonymous
                                  service-related statistics, and for other
                                  technical purposes like assisting in
                                  accessibility support.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  Used for strictly necessary anonymous
                                  service-related statistics, and for other
                                  technical purposes like assisting in
                                  accessibility support.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Performance &amp; Functionality
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  wistia
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  Wistia is a video hosting platform used by our
                                  video player on the Site. The information
                                  collected is used to see where the engagement
                                  with a vIDeo stopped if the vIDeo was replayed
                                  and allows playback continuation.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  Wistia is a video hosting platform used by our
                                  video player on the Site. The information
                                  collected is used to see where the engagement
                                  with a vIDeo stopped if the vIDeo was replayed
                                  and allows playback continuation.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Performance &amp; Functionality
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  test_rudder_cookie
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie checks whether the cookie storage
                                  of a browser is accessible or not.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie checks whether the cookie storage
                                  of a browser is accessible or not.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Performance &amp; Functionality
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  muc_ads
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie is used to collect data on user
                                  behaviour and interaction in order to optimize
                                  the website and make advertisement on the
                                  website more relevant.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie is used to support the website's
                                  technical functions.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Performance &amp; Functionality
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  li_sugr
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  Used to make a probabilistic match of a user's
                                  identity outside the designated countries.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  Used to make a probabilistic match of a user's
                                  identity outside the designated countries.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Performance &amp; Functionality
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  sp
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  Stores a server-side collector generated
                                  unique identifier for a user that is sent with
                                  all subsequent tracking event events. Can be
                                  used as a first party cookie is the collector
                                  is on the same domain as the site.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  Stores a server-side collector generated
                                  unique identifier for a user that is sent with
                                  all subsequent tracking event events. Can be
                                  used as a first party cookie is the collector
                                  is on the same domain as the site.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Performance &amp; Functionality
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  snowplowOutQueue_*
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie is used to match the concept of
                                  cookie expiry within Local Storage. This
                                  ensures a consistent behaviour between cookie
                                  and local storage.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie is used to match the concept of
                                  cookie expiry within Local Storage. This
                                  ensures a consistent behaviour between cookie
                                  and local storage.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Performance &amp; Functionality
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  rl_user_id
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  Stores the user ID set via the identify API.
                                  All the subsequent event payloads will contain
                                  this data unless cleared from the storage.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  Stores the user ID set via the identify API.
                                  All the subsequent event payloads will contain
                                  this data unless cleared from the storage.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Performance &amp; Functionality
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  rl_group_trait
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  This cookie Stores the user group traits
                                  object set via the group API.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  This cookie Stores the user group traits
                                  object set via the group API.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Performance &amp; Functionality
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  rl_trait
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  Stores the user traits object set via the
                                  identify API. All the subsequent event
                                  payloads will contain this data unless cleared
                                  from the storage.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  Stores the user traits object set via the
                                  identify API. All the subsequent event
                                  payloads will contain this data unless cleared
                                  from the storage.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Performance &amp; Functionality
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="listitem"
                            className="cookie-item w-dyn-item"
                          >
                            <div>
                              <div className="cookie-row">
                                <div
                                  id="w-node-_765b547a-907b-b858-27ec-866595974f7e-260fd489"
                                  className="cookie-name"
                                >
                                  rl_page_init_referring_domain
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f80-260fd489">
                                  Stores the initial referring domain of the
                                  page when a user visits a site for the first
                                  time. All the subsequent event payloads will
                                  contain this data.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f82-260fd489">
                                  Stores the initial referring domain of the
                                  page when a user visits a site for the first
                                  time. All the subsequent event payloads will
                                  contain this data.
                                </div>
                                <div id="w-node-_765b547a-907b-b858-27ec-866595974f84-260fd489">
                                  Performance &amp; Functionality
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h3>
                    <strong>V. Cookie Options</strong>
                  </h3>
                  <p>
                    You have a choice about the placement of cookies from our
                    Platform. You can use your web browser to directly block all
                    cookies, or just third-party cookies, through your browser
                    settings.{" "}
                  </p>
                  <p>
                    Using your browser settings to block all cookies, including
                    strictly necessary ones, may interfere with proper site
                    operation. Guidance on how to control cookies in popular
                    browsers is contained{" "}
                    <a href="https://www.allaboutcookies.org/">here</a>:
                  </p>
                  <ul role="list">
                    <li>
                      <a href="https://www.allaboutcookies.org/manage-cookies/internet-explorer11.html">
                        Microsoft Internet Explorer
                      </a>
                    </li>
                    <li>
                      <a href="https://www.allaboutcookies.org/manage-cookies/safari.html">
                        MacOS Safari
                      </a>
                    </li>
                    <li>
                      <a href="https://www.allaboutcookies.org/manage-cookies/firefox2-plus.html">
                        Mozilla Firefox
                      </a>
                    </li>
                    <li>
                      <a href="https://www.allaboutcookies.org/manage-cookies/google-chrome.html">
                        Google Chrome
                      </a>
                    </li>
                    <li>
                      <a href="https://www.allaboutcookies.org/manage-cookies/remove-flash-cookies.html">
                        Adobe (Flash Cookies)
                      </a>
                    </li>
                  </ul>
                  <p>
                    You can also find additional information on cookie controls
                    and advertisement here:
                  </p>
                  <ul role="list">
                    <li>
                      <a href="https://optout.networkadvertising.org/?c=1#!%2F">
                        Network Advertising Initiative
                      </a>
                    </li>
                    <li>
                      <a href="https://youradchoices.com/">
                        Digital Advertising Alliance
                      </a>
                    </li>
                    <li>
                      <strong>Google</strong> allows users to opt out of
                      tracking by Google Analytics and Google Analytics
                      Demographics and Interest Reporting services. You can
                      adjust your setting{" "}
                      <a href="https://geekthis.net/post/block-google-analytics/">
                        here
                      </a>
                      , or download the
                      <br />
                      <a href="https://tools.google.com/dlpage/gaoptout">
                        Google Analytics Opt-Out Browser Add-on
                      </a>
                      .
                    </li>
                    <li>
                      <strong>LinkedIn</strong> allows users and non-users to
                      opt-out of targeted advertising{" "}
                      <a href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out">
                        here
                      </a>
                      .
                    </li>
                    <li>
                      <strong>Facebook</strong> adheres to the Self-Regulatory
                      Principles for Online Behavioral Advertising and
                      participate in the opt-out programs established by the{" "}
                      <a href="https://optout.aboutads.info/?c=2&lang=EN">
                        Digital Advertising Alliance
                      </a>
                      , the{" "}
                      <a href="https://youradchoices.ca/">
                        Digital Advertising Alliance of Canada
                      </a>{" "}
                      and the{" "}
                      <a href="https://www.youronlinechoices.com/">
                        European Interactive Digital Advertising Alliance
                      </a>
                      . You can opt out from all participating companies through
                      these sites.
                    </li>
                  </ul>
                  <h3>
                    ‍<strong>VI. Changes To This Policy</strong>
                  </h3>
                  <p>
                    Kajabi may revise this Cookie Notice, from time to time. If
                    we make revisions that materially change or affect whether
                    or how we collect or use personal information we will notify
                    you by email (if, as a user of our Platform, you have
                    provided us your email address) or by means of a notice on
                    our Platform prior to the change becoming effective.
                    Therefore, you should review the Platform periodically so
                    that you are up-to-date on our most current policies and
                    practices.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </main>

        <Footer />
      </div>
    </body>
  );
}

export default Cookie;
