import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/main.css";

function Subscribe() {
  const handleSubmit = () => {
    alert(
      "Congratulations on your successful subscription! Your relevant subscription record will be recorded in our back office. Please contact us to discuss program services further!"
    );
  };

  return (
    <body>
      <div className="page-wrapper">
        <Header />
        <main className="main-wrapper">
          <header className="section_header background-color-offwhite has-top-padding overflow-hidden">
            <div className="padding-global padding-section-small">
              <div className="container-large">
                <div className="split-header_component">
                  <div id="w-node-_93e157af-8cf7-e509-7088-52efe91d14d8-4cfd8969">
                    <h1
                      id="w-node-af5b861c-0d0f-aeb1-0014-b39bea9671ee-4cfd8969"
                      className="heading-style-h2"
                    >
                      <div words-rotate-in="" text-split="">
                        Our plans are designed to support your plans.
                      </div>
                    </h1>
                  </div>
                </div>
                <div className="padding-bottom padding-xlarge" />
                <div
                  data-w-id="48cc915b-aede-a36e-e676-f5a67b0c9823"
                  style={{
                    opacity: 1,
                    transform:
                      "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                    transformStyle: "preserve-3d",
                  }}
                  data-current="Annual Plans"
                  data-easing="ease"
                  data-duration-in={300}
                  data-duration-out={100}
                  className="pricing_tabs w-tabs"
                >
                  <div className="pricing_tabs-content w-tab-content">
                    <div
                      data-w-tab="Annual Plans"
                      className="pricing_tabs-pane w-tab-pane w--tab-active"
                      id="w-tabs-1-data-w-pane-0"
                      role="tabpanel"
                      aria-labelledby="w-tabs-1-data-w-tab-0"
                    >
                      <div
                        id="pricing-swiper_annual"
                        className="pricing_tabs-wrapper"
                      >
                        <div className="swiper-wrapper pricing-cards">
                          <div
                            id="w-node-eb5353ca-39ce-9cb6-c463-5c3608a3696a-4cfd8969"
                            className="swiper-slide pricing-cards"
                          >
                            <div
                              id="w-node-_915d12f1-d512-ae71-dab0-46667ef38d01-4cfd8969"
                              className="pricing-cards_wrapper is-featured-pricing-card"
                            >
                              <div className="pricing-cards_title-wrapper">
                                <h2 className="pricing-cards_title">Growth</h2>
                              </div>
                              <p>
                                Expand your business and get serious about
                                growing.
                              </p>
                              <div className="pricing-cards_price-wrapper">
                                <div className="pricing-cards_price">
                                  $29.9{" "}
                                  <span className="pricing-cards_price-subtitle">
                                    /day*
                                  </span>
                                </div>
                              </div>
                              <button
                                onClick={handleSubmit}
                                data-growth-annual=""
                                className="button w-button"
                                data-planurl="kajabi_growth_annual"
                                data-pricing-trigger=""
                                aria-label="Growth Annual Trial"
                                data-w-id="c627a802-bcbc-b4b9-adb9-c63b9ad9d2f4"
                                data-track-event="Lead Form Viewed"
                                data-signupsource="kajabi_growth_annual"
                                data-elementreferrer="Body Trial"
                                id="pricing-growth-annual"
                              >
                                Get Started →
                              </button>

                              <div className="pricing-cards_features-wrapper">
                                <div className="text-size-regular">
                                  Standout&nbsp;Features:
                                </div>
                                <ul className="pricing-cards_features-list w-list-unstyled">
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-_915d12f1-d512-ae71-dab0-46667ef38d14-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div
                                      id="w-node-_915d12f1-d512-ae71-dab0-46667ef38d15-4cfd8969"
                                      className="text-size-small"
                                    >
                                      1 Website
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-_915d12f1-d512-ae71-dab0-46667ef38d1c-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div
                                      id="w-node-_915d12f1-d512-ae71-dab0-46667ef38d1d-4cfd8969"
                                      className="text-size-small"
                                    >
                                      15 Products
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-_85d21780-1a59-0de0-e2a3-68511b62097c-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div
                                      id="w-node-_85d21780-1a59-0de0-e2a3-68511b62097d-4cfd8969"
                                      className="text-size-small"
                                    >
                                      25,000 Contacts
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-d24315f1-f8ae-26fa-b07f-5f6e1fbb0f13-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div className="text-size-small">
                                      Unlimited Marketing Emails
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-_5a000f8a-89b3-11b8-7615-3db461c8c199-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div className="text-size-small">
                                      Unlimited Landing Pages
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-_9ca0004b-39e9-588f-93fd-b2c0853f2ccc-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div
                                      id="w-node-_9ca0004b-39e9-588f-93fd-b2c0853f2ccd-4cfd8969"
                                      className="text-size-small"
                                    >
                                      Group Onboarding Call
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-_58ed7c61-5a15-ff1c-72b1-c4c93a6ce691-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div
                                      id="w-node-_58ed7c61-5a15-ff1c-72b1-c4c93a6ce692-4cfd8969"
                                      className="text-size-small"
                                    >
                                      Kajabi AI
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-_411f6587-0c12-e15d-5269-eb4c4b83edc8-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div
                                      id="w-node-_411f6587-0c12-e15d-5269-eb4c4b83edc9-4cfd8969"
                                      className="text-size-small"
                                    >
                                      Creator Studio
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-_47af0b3b-cc35-9529-9f8d-ac851d626285-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div className="text-size-small">
                                      3rd Party Integrations
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-_1781a390-c882-ef12-9132-3f19eb61a4ba-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div
                                      id="w-node-_1781a390-c882-ef12-9132-3f19eb61a4bb-4cfd8969"
                                      className="text-size-small"
                                    >
                                      24/7 Support
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-_915d12f1-d512-ae71-dab0-46667ef38d20-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div
                                      id="w-node-_915d12f1-d512-ae71-dab0-46667ef38d21-4cfd8969"
                                      className="text-size-small"
                                    >
                                      Affiliate Programs
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-_2355bf30-5af8-0268-92b0-1364975c78b8-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div
                                      id="w-node-_2355bf30-5af8-0268-92b0-1364975c78b9-4cfd8969"
                                      className="text-size-small"
                                    >
                                      Custom Domain
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-_78e10899-dc15-867c-85a5-d927afd9ccc7-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div
                                      id="w-node-_78e10899-dc15-867c-85a5-d927afd9ccc8-4cfd8969"
                                      className="text-size-small"
                                    >
                                      Custom Branding
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-c69cb13c-f1fc-6f42-945d-2bfe0a79099d-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div
                                      id="w-node-c69cb13c-f1fc-6f42-945d-2bfe0a79099e-4cfd8969"
                                      className="text-size-small"
                                    >
                                      Multiple Admin Users
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-_62f2dd7e-023a-5a1a-72cb-28614e675ee3-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div
                                      id="w-node-_62f2dd7e-023a-5a1a-72cb-28614e675ee4-4cfd8969"
                                      className="text-size-small"
                                    >
                                      Webhooks
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div
                            id="w-node-a6165742-013e-73d8-faa6-6d5f1bed0471-4cfd8969"
                            className="swiper-slide pricing-cards"
                          >
                            <div
                              id="w-node-_84649c08-b50d-9f76-ec60-aa670a1b0443-4cfd8969"
                              className="pricing-cards_wrapper"
                            >
                              <div className="pricing-cards_title-wrapper">
                                <h2 className="pricing-cards_title">
                                  Kickstarter
                                </h2>
                              </div>
                              <p>
                                Get the foundations right to set up your
                                business.
                              </p>
                              <div className="pricing-cards_price-wrapper">
                                <div className="pricing-cards_price">
                                  $9.9{" "}
                                  <span className="pricing-cards_price-subtitle">
                                    /day*
                                  </span>
                                </div>
                              </div>
                              <button
                                onClick={handleSubmit}
                                className="button is-black w-button"
                                data-planurl="v2_kickstarter_annual"
                                data-kickstarter-annual=""
                                data-pricing-trigger=""
                                aria-label="Kickstarter Annual Trial"
                                data-w-id="1d61c3ce-97e2-5705-02a3-234a68c99d1b"
                                data-track-event="Lead Form Viewed"
                                data-signupsource="kickstarter69"
                                data-elementreferrer="Body Trial"
                                id="pricing-kickstarter-annual"
                              >
                                Get Started →
                              </button>
                              <div className="pricing-cards_features-wrapper">
                                <div className="text-size-regular">
                                  Standout Features:
                                </div>
                                <ul className="pricing-cards_features-list w-list-unstyled">
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-a2a9d299-4385-7ede-852d-b08c288878ed-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div
                                      id="w-node-a2a9d299-4385-7ede-852d-b08c288878ee-4cfd8969"
                                      className="text-size-small"
                                    >
                                      1 Website
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-_5490f97d-1aad-0ff4-81a1-f9b57bf54a56-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div
                                      id="w-node-_5490f97d-1aad-0ff4-81a1-f9b57bf54a57-4cfd8969"
                                      className="text-size-small"
                                    >
                                      1 Product +&nbsp;1 Community
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-_391fac26-efe5-e865-2bdf-6b9a738cb4e7-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div
                                      id="w-node-_391fac26-efe5-e865-2bdf-6b9a738cb4e8-4cfd8969"
                                      className="text-size-small"
                                    >
                                      250 Contacts
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-dc779873-eae9-c110-de60-4b645fd21df8-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div
                                      id="w-node-dc779873-eae9-c110-de60-4b645fd21df9-4cfd8969"
                                      className="text-size-small"
                                    >
                                      7,500 Marketing Emails
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-d6aa8c35-a482-4e98-7df6-65763e3215ad-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div
                                      id="w-node-d6aa8c35-a482-4e98-7df6-65763e3215ae-4cfd8969"
                                      className="text-size-small"
                                    >
                                      Unlimited Landing Pages
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-_5490f97d-1aad-0ff4-81a1-f9b57bf54a52-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div
                                      id="w-node-_5490f97d-1aad-0ff4-81a1-f9b57bf54a53-4cfd8969"
                                      className="text-size-small"
                                    >
                                      Group Onboarding Call
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-_5490f97d-1aad-0ff4-81a1-f9b57bf54a4a-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div
                                      id="w-node-_5490f97d-1aad-0ff4-81a1-f9b57bf54a4b-4cfd8969"
                                      className="text-size-small"
                                    >
                                      Kajabi AI
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-_5490f97d-1aad-0ff4-81a1-f9b57bf54a4e-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div
                                      id="w-node-_5490f97d-1aad-0ff4-81a1-f9b57bf54a4f-4cfd8969"
                                      className="text-size-small"
                                    >
                                      Creator Studio
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div
                            id="w-node-_1395c97c-1dac-4a6e-57f1-6777bd813045-4cfd8969"
                            className="swiper-slide pricing-cards"
                          >
                            <div
                              id="w-node-_9d3c6f24-b887-abdc-d688-a7d758ae1fbd-4cfd8969"
                              className="pricing-cards_wrapper"
                            >
                              <div className="pricing-cards_title-wrapper">
                                <h2 className="pricing-cards_title">Basic</h2>
                              </div>
                              <p>Everything you need to start your business.</p>
                              <div className="pricing-cards_price-wrapper">
                                <div className="pricing-cards_price">
                                  $19.9{" "}
                                  <span className="pricing-cards_price-subtitle">
                                    /day*
                                  </span>
                                </div>
                              </div>
                              <button
                                onClick={handleSubmit}
                                data-basic-annual=""
                                className="button is-black w-button"
                                data-planurl="kajabi_basic_annual"
                                data-pricing-trigger=""
                                aria-label="Basic Annual Trial"
                                data-w-id="76cb1013-8cae-0415-1029-9c1ee52c1fe6"
                                data-track-event="Lead Form Viewed"
                                data-signupsource="kajabi_basic_annual"
                                data-elementreferrer="Body Trial"
                                id="pricing-basic-annual"
                              >
                                Get Started →
                              </button>
                              <div className="pricing-cards_features-wrapper">
                                <div className="text-size-regular">
                                  Standout Features:
                                </div>
                                <ul className="pricing-cards_features-list w-list-unstyled">
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-_62c85e4e-a2ec-1971-8469-ac7bacc3d0cf-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div
                                      id="w-node-_62c85e4e-a2ec-1971-8469-ac7bacc3d0d0-4cfd8969"
                                      className="text-size-small"
                                    >
                                      1 Website
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-_0861b7cc-706c-7d47-a34a-60b212684875-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div className="text-size-small">
                                      3 Products
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-f9cf74b9-7cc4-0701-f4af-556aff5e9c79-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div className="text-size-small">
                                      10,000 Contacts
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-_047200a1-cf28-ed89-b028-af9cea2fae98-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div className="text-size-small">
                                      Unlimited Marketing Emails
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-_0e2d69e4-9879-a168-c718-32a6d6c446ed-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div className="text-size-small">
                                      Unlimited Landing Pages
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-f663b214-59d4-b889-f7bd-27c9ce6ca337-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div
                                      id="w-node-f663b214-59d4-b889-f7bd-27c9ce6ca338-4cfd8969"
                                      className="text-size-small"
                                    >
                                      Group Onboarding Call
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-_6ac20488-312d-4e35-f99c-b6ff0c14aeac-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div
                                      id="w-node-_6ac20488-312d-4e35-f99c-b6ff0c14aead-4cfd8969"
                                      className="text-size-small"
                                    >
                                      Kajabi AI
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-_8720e576-b9a9-d1df-9f30-1fb1c0ea518e-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div
                                      id="w-node-_8720e576-b9a9-d1df-9f30-1fb1c0ea518f-4cfd8969"
                                      className="text-size-small"
                                    >
                                      Creator Studio
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-_2feb9f0c-b65f-55ce-ddf2-11758b8e9397-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div className="text-size-small">
                                      3rd Party Integrations
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div
                            id="w-node-_53096c1b-a8b9-fcf8-2f29-ab625b2e6495-4cfd8969"
                            className="swiper-slide pricing-cards"
                          >
                            <div
                              id="w-node-b53ef5fe-03ae-cff6-551c-6cfba7d41836-4cfd8969"
                              className="pricing-cards_wrapper"
                            >
                              <div className="pricing-cards_title-wrapper">
                                <h2 className="pricing-cards_title">Pro</h2>
                              </div>
                              <p>Scale your business with advanced features.</p>
                              <div className="pricing-cards_price-wrapper">
                                <div className="pricing-cards_price">
                                  $40{" "}
                                  <span className="pricing-cards_price-subtitle">
                                    /day*
                                  </span>
                                </div>
                              </div>
                              <button
                                onClick={handleSubmit}
                                data-pro-annual=""
                                className="button is-black w-button"
                                data-planurl="kajabi_pro_annual"
                                data-pricing-trigger=""
                                aria-label="Pro Annual Trial"
                                data-w-id="052684b4-5636-e2e4-addd-d1aec39bc219"
                                data-track-event="Lead Form Viewed"
                                data-signupsource="kajabi_pro_annual"
                                data-elementreferrer="Body Trial"
                                id="pricing-pro-annual"
                              >
                                Get Started →
                              </button>
                              <div className="pricing-cards_features-wrapper">
                                <div className="text-size-regular">
                                  Standout Features:
                                </div>
                                <ul className="pricing-cards_features-list w-list-unstyled">
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-db1d437d-fd79-3cdd-7239-4b05f43f098d-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div
                                      id="w-node-db1d437d-fd79-3cdd-7239-4b05f43f098e-4cfd8969"
                                      className="text-size-small"
                                    >
                                      3 Websites
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-db1d437d-fd79-3cdd-7239-4b05f43f0991-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div
                                      id="w-node-db1d437d-fd79-3cdd-7239-4b05f43f0992-4cfd8969"
                                      className="text-size-small"
                                    >
                                      100 Products
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-db1d437d-fd79-3cdd-7239-4b05f43f0995-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div
                                      id="w-node-db1d437d-fd79-3cdd-7239-4b05f43f0996-4cfd8969"
                                      className="text-size-small"
                                    >
                                      100,000 Contacts
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-db1d437d-fd79-3cdd-7239-4b05f43f0999-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div className="text-size-small">
                                      Unlimited Marketing Emails
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-_94fc7400-f027-472e-e328-1a008f6ac628-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div className="text-size-small">
                                      Unlimited Landing Pages
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-db1d437d-fd79-3cdd-7239-4b05f43f099d-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div
                                      id="w-node-db1d437d-fd79-3cdd-7239-4b05f43f099e-4cfd8969"
                                      className="text-size-small"
                                    >
                                      Group Onboarding Call
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-db1d437d-fd79-3cdd-7239-4b05f43f09a1-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div
                                      id="w-node-db1d437d-fd79-3cdd-7239-4b05f43f09a2-4cfd8969"
                                      className="text-size-small"
                                    >
                                      Kajabi AI
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-db1d437d-fd79-3cdd-7239-4b05f43f09a5-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div
                                      id="w-node-db1d437d-fd79-3cdd-7239-4b05f43f09a6-4cfd8969"
                                      className="text-size-small"
                                    >
                                      Creator Studio
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-db1d437d-fd79-3cdd-7239-4b05f43f09a9-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div className="text-size-small">
                                      3rd Party Integrations
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-db1d437d-fd79-3cdd-7239-4b05f43f09ad-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div
                                      id="w-node-db1d437d-fd79-3cdd-7239-4b05f43f09ae-4cfd8969"
                                      className="text-size-small"
                                    >
                                      24/7 Support
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-db1d437d-fd79-3cdd-7239-4b05f43f09b1-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div
                                      id="w-node-db1d437d-fd79-3cdd-7239-4b05f43f09b2-4cfd8969"
                                      className="text-size-small"
                                    >
                                      Affiliate Programs
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-db1d437d-fd79-3cdd-7239-4b05f43f09b5-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div
                                      id="w-node-db1d437d-fd79-3cdd-7239-4b05f43f09b6-4cfd8969"
                                      className="text-size-small"
                                    >
                                      Custom Domain
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-db1d437d-fd79-3cdd-7239-4b05f43f09b9-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div
                                      id="w-node-db1d437d-fd79-3cdd-7239-4b05f43f09ba-4cfd8969"
                                      className="text-size-small"
                                    >
                                      Custom Branding
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-db1d437d-fd79-3cdd-7239-4b05f43f09bd-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div
                                      id="w-node-db1d437d-fd79-3cdd-7239-4b05f43f09be-4cfd8969"
                                      className="text-size-small"
                                    >
                                      Multiple Admin Users
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-db1d437d-fd79-3cdd-7239-4b05f43f09c1-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div
                                      id="w-node-db1d437d-fd79-3cdd-7239-4b05f43f09c2-4cfd8969"
                                      className="text-size-small"
                                    >
                                      Webhooks
                                    </div>
                                  </li>
                                  <li className="pricing-cards_features-item">
                                    <div
                                      id="w-node-_3afc53f3-7387-b6c0-691d-44bc5eca80bf-4cfd8969"
                                      className="checkmark_icon w-embed"
                                    >
                                      <svg
                                        width="100%"
                                        height="100%"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.1483 5.46078L7.14828 14.4608C7.09604 14.5131 7.034 14.5546 6.96572 14.5829C6.89743 14.6112 6.82423 14.6258 6.75031 14.6258C6.67639 14.6258 6.6032 14.6112 6.53491 14.5829C6.46662 14.5546 6.40458 14.5131 6.35234 14.4608L2.41484 10.5233C2.3093 10.4177 2.25 10.2746 2.25 10.1253C2.25 9.97605 2.3093 9.83289 2.41484 9.72734C2.52039 9.6218 2.66355 9.5625 2.81281 9.5625C2.96208 9.5625 3.10523 9.6218 3.21078 9.72734L6.75031 13.2676L15.3523 4.66484C15.4579 4.5593 15.601 4.5 15.7503 4.5C15.8996 4.5 16.0427 4.5593 16.1483 4.66484C16.2538 4.77039 16.3131 4.91355 16.3131 5.06281C16.3131 5.21208 16.2538 5.35523 16.1483 5.46078Z"
                                          fill="#FF3E14"
                                        />
                                      </svg>
                                    </div>
                                    <div
                                      id="w-node-_3afc53f3-7387-b6c0-691d-44bc5eca80c0-4cfd8969"
                                      className="text-size-small"
                                    >
                                      Custom Code Editor
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </main>

        <Footer />
      </div>
    </body>
  );
}

export default Subscribe;
