import React from "react";
import "../css/privacy.css";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";

function Terms() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <body>
      <div className="page-wrapper">
        <Header />
        <main className="main-wrapper">
          <section className="section_header">
            <div className="padding-global padding-section-medium">
              <div className="container-large">
                <div className="breadcrumb_wrapper">
                  <Link
                    onClick={toTop}
                    to="/"
                    className="breadcrumb_link text-style-muted"
                  >
                    Policies
                  </Link>
                  <div className="text-style-muted">→</div>
                  <Link
                    onClick={toTop}
                    to="/terms"
                    aria-current="page"
                    className="breadcrumb_link w--current"
                  >
                    Policies
                  </Link>
                </div>
                <div className="padding-bottom padding-large" />
                <div className="max-width-large">
                  <h1 className="heading-style-h3-feature">
                    Platform Terms of Service
                  </h1>
                  <div className="padding-bottom padding-small" />
                  <div className="w-embed">
                    <div>Last Edit: May 06, 2024</div>
                  </div>
                  <div className="padding-bottom padding-large" />
                  <div className="button-group">
                    <Link
                      onClick={toTop}
                      to="#"
                      className="button w-condition-invisible w-button"
                    >
                      Open PDF
                    </Link>
                    <Link
                      onClick={toTop}
                      to="#"
                      className="button w-condition-invisible w-button"
                    >
                      Open PDF
                    </Link>
                  </div>
                </div>
                <div className="padding-bottom padding-large" />
                <div
                  fs-richtext-element="rich-text"
                  className="text-rich-text w-richtext"
                >
                  <p>
                    Please read these Platform Terms of Service (“Terms”)
                    carefully. By accessing or using the Platform (defined
                    below) or signing up for an account, you agree that you have
                    reviewed and understood, and, as a condition of your access
                    to or use of the Platform, you agree to be bound by these
                    Terms, which are a legal agreement between you and Kajabi,
                    LLC and our affiliated entities (collectively, “Kajabi”,
                    “we”, “us”, or “our”).&nbsp;
                  </p>
                  <p>
                    THESE TERMS CONTAIN AN{" "}
                    <Link
                      onClick={toTop}
                      to="/privacy/terms#12-resolving-disputes-arbitration-agreement-governing-law"
                    >
                      ARBITRATION CLAUSE AND A WAIVER OF RIGHTS TO PARTICIPATE
                      IN CLASS ACTIONS OR CLASS ARBITRATIONS
                    </Link>
                    .&nbsp; EXCEPT FOR CERTAIN TYPES OF DISPUTES MENTIONED IN
                    THE ARBITRATION CLAUSE, YOU AND KAJABI AGREE THAT ALL
                    DISPUTES RELATING TO THESE TERMS OR YOUR USE OF OUR PRODUCT
                    WILL BE RESOLVED BY MANDATORY BINDING ARBITRATION, WHICH
                    MEANS THAT YOU WAIVE ANY RIGHT TO HAVE THOSE DISPUTES
                    DECIDED BY A JUDGE OR JURY, AND YOU WAIVE ANY RIGHT TO
                    PARTICIPATE IN A CLASS-ACTION LAWSUIT OR CLASS-WIDE
                    ARBITRATION.
                  </p>
                  <p>
                    Let’s review a few definitions that should help you
                    understand these Terms. Kajabi offers an online platform
                    that allows you to create and host your online digital
                    business (each, a “Site”, and collectively, “Sites”), to
                    reach and manage your end users, customers, and subscribers
                    (your “Customers”), and to create, send, and manage
                    marketing campaigns (each a “Campaign,” and collectively,
                    “Campaigns”), among other things. Our platform is operated
                    by the Kajabi group of companies and is offered through our
                    website,{" "}
                    <Link onClick={toTop} to="https://kajabi.com">
                      https://kajabi.com
                    </Link>
                    , and any other website or mobile application owned,
                    operated, or controlled by us (the “Kajabi Site,” and
                    together with the insights, analytics, and other features we
                    make available through our products and services, is
                    collectively referred to as the “Platform” or “Product”).
                    Any new features or tools added to the current offering will
                    be deemed part of the Platform and subject to these Terms.
                  </p>
                  <p>
                    Kajabi has employees, affiliates, officers, directors,
                    independent contractors, vendors, agents, and
                    representatives (our “Team”). As a customer of Kajabi or a
                    representative of an entity that’s a customer of Kajabi, we
                    refer to you as a Kajabi “Hero” (or “you”). A “Contact” is
                    any person you, as a Hero, may contact through our Platform
                    or anyone on your distribution list about whom you have
                    given us information or anyone who has otherwise interacted
                    with you via the Platform. For example, if you are a Hero, a
                    subscriber to your email marketing campaigns, or a user of
                    your website or courses would be considered a Contact.
                    “Content” means your trademarks, copyright content, any
                    products or services you sell through the Platform, and any
                    software (including machine images), data, text, audio,
                    video or images, coaching offerings, and personal data
                    uploaded, collected, posted, stored, displayed, distributed,
                    or transmitted on or in connection with your account or your
                    Site.&nbsp;&nbsp;
                  </p>
                  <p>
                    These Terms, along with our Acceptable Use Policy, Privacy
                    Policy, and the Kajabi Data Protection Addendum (including
                    the Standard Contractual Clauses (“SCCs”)), and all other
                    policies found in our{" "}
                    <Link onClick={toTop} to="/privacy">
                      Legal and Policy Center
                    </Link>{" "}
                    (collectively, the “Kajabi Policies”), define the terms and
                    conditions under which you’re allowed to use the Platform.
                    Our Privacy Policy and Cookie Statement describe how we’ll
                    treat your account and the data we collect and process about
                    you, your Customers, and your Contacts while you’re a Kajabi
                    Hero. If you don’t agree to the Kajabi Policies, you must
                    immediately discontinue using the Platform.
                  </p>
                  <p>
                    Additional terms and restrictions available on our Kajabi
                    Site at{" "}
                    <Link onClick={toTop} to="/privacy">
                      /privacy
                    </Link>
                    &nbsp; (“Additional Terms”) apply to some specific features
                    of the Platform, including some that are offered as add-ons
                    to your Kajabi account (“Add-ons”). Unless expressly stated
                    otherwise, these Terms apply to the use of Add-ons. Heroes
                    agree to the applicable Additional Terms when they add the
                    corresponding Add-on or use the corresponding feature. The
                    account of some Heroes may have access to unique features of
                    the Platform based on their historical usage or status that
                    may not be available to all Heroes.
                  </p>
                  <p>
                    Kajabi uses Google Calendar to provide certain features of
                    the Platform, and, as a result, we are contractually
                    obligated to make our Heroes aware of specific terms related
                    to using such features. By signing up for an account and
                    using the Platform, you agree to be bound by the Google
                    Calendar{" "}
                    <Link onClick={toTop} to="/terms">
                      terms
                    </Link>{" "}
                    (including the{" "}
                    <Link onClick={toTop} to="/privacy">
                      Google Privacy Policy
                    </Link>
                    ).
                  </p>
                  <p>
                    If you download an App to access the Platform through the
                    Apple App Store, Google Play, or other app store or
                    distribution platform (“App Provider”), you acknowledge and
                    agree that: (i) these Terms are between us and not with the
                    App Provider, and that we are responsible for the Platform,
                    not the App Provider; (ii) the App Provider has no
                    obligation to furnish maintenance and support services or
                    handle any warranty claims; (iii) the App Provider is not
                    responsible for addressing any claims you or any third party
                    have relating to the Platform; and (iv) the App Provider is
                    a third party beneficiary of these Terms as related to your
                    use of the Platform, and the App Provider will have the
                    right to enforce these Terms as related to your use of the
                    Platform against you.
                  </p>
                  <p>
                    Kajabi may modify or change these Terms at any time. When we
                    change these Terms, we will post the revised Terms{" "}
                    <Link onClick={toTop} to="/privacy">
                      here
                    </Link>
                    . Any changes will be effective immediately or on the
                    published effective date. Your use of the Platform after any
                    modification or amendment means you agree to the new Terms.
                    If you do not agree to the new Terms, you must stop using
                    the Platform.
                  </p>
                  <p>
                    If you have any questions about these Terms, please contact
                    us at{" "}
                    <Link onClick={toTop} to="mailto:service@philippa.store">
                      service@philippa.store
                    </Link>
                    .
                  </p>
                  <h2>
                    ‍<strong>Platform Terms of Service Agreement&nbsp;</strong>‍
                  </h2>
                  <p>
                    Thank you for using Kajabi’s Platform. Our Heroes are the
                    most important aspect of our business, and we appreciate you
                    looking to Kajabi to further your business.&nbsp; If you are
                    using the Platform on behalf of an entity or other
                    organization, you agree to these Terms on behalf of that
                    entity or organization, and you represent that you have such
                    authority. “Hero”, “you”, and “your” will refer to that
                    entity or organization.
                  </p>
                  <p>
                    When you subscribe to our Platform, or otherwise use or
                    access the Platform, you agree to be bound by these Terms,
                    all other Kajabi Policies, and all applicable laws, rules,
                    and regulations.&nbsp; Kajabi is not an educational
                    institution or content provider.&nbsp; Our Heroes, users,
                    entrepreneurs, customers, experts, and partners are not our
                    employees, contractors, or representatives.&nbsp; We are not
                    responsible for any interactions between you and your
                    Customers or other Contacts, other than providing the
                    Platform.&nbsp; We are in no way liable for any disputes,
                    claims, losses, injuries, or damages arising from your
                    relationship with your Customers or other Contacts,
                    including their reliance upon any information or Content you
                    provide.
                  </p>
                  <h3>
                    ‍<strong>1. Your Kajabi Account</strong>
                  </h3>
                  <h4>
                    <strong>‍</strong>
                    <em>1.1. Registering an Account&nbsp;&nbsp;</em>‍
                  </h4>
                  <p>
                    1. To access and use the Platform, you must subscribe to
                    Kajabi and register for a Kajabi account (“Account”). To
                    complete your Account registration, you must provide us with
                    your full legal name, business address, phone number, a
                    valid email address (your “Primary Email Address”), and any
                    other information requested. Kajabi may reject a
                    subscription for an Account, or cancel an existing Account,
                    for any reason, at our sole discretion.
                  </p>
                  <p>
                    2. You confirm that you are using the Platform or any other
                    services provided by Kajabi to carry on a business activity
                    and not for personal, household, or family purposes.
                  </p>
                  <h4>
                    ‍<em>1.2. Eligibility&nbsp; </em>
                  </h4>
                  <p>
                    <em>‍</em>To use the Platform or any other Kajabi product or
                    service and open an Account, you must:
                  </p>
                  <p>
                    1. Be at least (18) years old (or the applicable age of your
                    jurisdiction) and able to enter into contracts.&nbsp;
                  </p>
                  <p>
                    2. If you are not an individual, warrant that you are
                    validly formed and existing under the laws of your
                    jurisdiction of formation, have full power and authority to
                    enter into these Terms, and have duly authorized your agent
                    to bind you to these Terms.
                  </p>
                  <p>3. Complete the Account registration process.</p>
                  <p>
                    4. Agree to these Terms and all other terms, conditions, and
                    policies linked in these Terms.
                  </p>
                  <p>
                    5. Provide true, complete, and up-to-date contact and
                    billing information.
                  </p>
                  <p>
                    6. You may only use the Platform as permitted by applicable
                    law. You may not use the Platform if you are located in a
                    country embargoed by the United States (such as Cuba, Iran,
                    North Korea, Syria, Russia, the Crimea, Donetsk, or Luhansk
                    regions) or if you are on the U.S. Department of Commerce’s
                    Denied Persons List or Entity List or the U.S. Treasury
                    Department’s list of Specially Designated Nationals.&nbsp;
                  </p>
                  <p>
                    By using the Platform, you represent and warrant (make a
                    legal promise) that you meet all of the requirements listed
                    above and will not use the Platform in a way that violates
                    any laws or regulations.
                  </p>
                  <p>
                    Kajabi may refuse service to anyone for any reason, close
                    Accounts of any Hero, or change eligibility requirements at
                    any time.
                  </p>
                  <h4>
                    ‍<em>1.3. Account Management</em>‍
                  </h4>
                  <p>
                    1. Subject to Section 1.3.2, the Hero signing up for the
                    Platform by opening an Account will be the contracting party
                    for our Terms, and will be the person who is authorized to
                    use any corresponding Account we may provide to the Hero in
                    connection with the Platform. You are responsible for
                    ensuring that your name and/or the full legal name of your
                    business is clearly visible on your Site.
                  </p>
                  <p>
                    2. If you are signing up for the Platform on behalf of your
                    employer or other entity, your employer or such other entity
                    will be the Hero. You must use your employer-issued email
                    address and represent that you have the authority to bind
                    your employer or such entity to these Terms.
                  </p>
                  <p>
                    3. You may not share an Account. Kajabi Sites can only be
                    associated with one Hero unless you have received Kajabi’s
                    prior written consent. A Hero can have multiple Sites.&nbsp;
                  </p>
                  <p>
                    4. Based on your subscription plan, you can permit employees
                    or independent contractors acting on your behalf and at your
                    direction (each, an “Admin User”) to access or use your
                    Account and/or the Platform, provided their use is for your
                    benefit only and remains in compliance with these Terms.
                    Each Admin User must provide a full legal name and a valid
                    email address. With Admin Users, you can set permissions and
                    let other people work in your Account while determining the
                    level of access by an Admin User to specific business
                    information. You will be responsible and liable for all
                    Admin Users’ access and use of your Account and/or the
                    Platform and their compliance with these Terms. In addition,
                    you, not Kajabi, will be responsible and liable for all
                    access, activity, and use of your Account and/or the
                    Platform, whether or not you authorized such activity. You
                    are responsible for safeguarding your password and any other
                    credentials used by any Admin User to access your Account.
                    Kajabi will not be liable for any loss or damage from your
                    failure to maintain the security of your Account and access
                    credentials. For more information, visit our{" "}
                    <Link
                      onClick={toTop}
                      to="https://help.kajabi.com/hc/en-us/articles/360036862774-How-to-Add-Admin-Users-for-Your-Site"
                    >
                      Help Center
                    </Link>
                    . Kajabi recommends that you use multi-factor authentication
                    (also known as MFA) to keep your Account safe, and may, in
                    its sole discretion, require your usage of MFA to access
                    your Account or specific features. To learn how to set up
                    MFA, visit our{" "}
                    <Link
                      onClick={toTop}
                      to="https://help.kajabi.com/hc/en-us/articles/8293506425115-How-to-Set-Up-Multi-Factor-Authentication-MFA-"
                    >
                      Help Center
                    </Link>
                    . If you become aware of any unauthorized access to your
                    Account, you should notify Kajabi immediately at{" "}
                    <Link onClick={toTop} to="mailto:service@philippa.store">
                    service@philippa.store
                    </Link>
                    .&nbsp;
                  </p>
                  <p>
                    5. You agree that Kajabi can provide all disclosures and
                    notices, including tax forms, to you by posting such
                    disclosures and notices in your Kajabi Dashboard or emailing
                    them to the Primary Email Address. You must monitor the
                    Primary Email Address and ensure it can send and receive
                    messages. Any disclosures and notices shall be considered to
                    be received by you within twenty-four (24) hours of the time
                    it is posted to your Kajabi Dashboard or emailed to you.
                    Your email communications with Kajabi can only be
                    authenticated if they come from your Primary Email Address.
                    You must keep your Primary Email Address and your contact
                    and payment details associated with your Account current and
                    accurate.
                  </p>
                  <p>
                    6. Technical support regarding the Platform is only provided
                    to Kajabi Heroes. Kajabi does not provide support for custom
                    code. Please send technical support questions to{" "}
                    <Link onClick={toTop} to="mailto:service@philippa.store">
                    service@philippa.store
                    </Link>
                    . Kajabi may assign you a customer success manager (“CSM”).
                    The CSM may review your use of the Platform and your Content
                    to help you to more effectively use the Platform, including
                    by providing reporting and usage insight. Kajabi will not be
                    responsible for any recommendations made by the CSM or
                    implementing any recommendations made by the CSM, and you
                    must implement any such recommendations.
                  </p>
                  <p>
                    7. Remember to backup all information in your Account.&nbsp;
                    You are responsible for maintaining, protecting, and backing
                    up your Content and Customer and Contact information. To the
                    extent permitted by applicable law, Kajabi will not be
                    liable for any failure to store, or for loss or corruption
                    of, such information.
                  </p>
                  <p>
                    8. Kajabi may terminate your Account and delete any Content
                    or other data contained in it if there has been no account
                    activity (such as a log-in event) for over 12 months.
                    However, we will attempt to warn you by email before
                    terminating your Account to allow you to log in to your
                    Account so that it remains active.
                  </p>
                  <h4>
                    ‍<em>1.4. Ownership Disputes&nbsp; </em>
                  </h4>
                  <p>
                    <em>‍</em>In a dispute regarding Account ownership, Kajabi
                    reserves the right to request documentation to determine or
                    confirm Account ownership. Documentation may include,
                    without limitation, a scanned copy of your business license,
                    organizational documents, government-issued photo ID, the
                    last four digits of the credit card on file, etc.&nbsp;
                    Kajabi retains the right to determine, in our sole judgment,
                    rightful Account ownership and to transfer an Account to the
                    rightful owner. If we cannot reasonably determine the
                    rightful Account owner, we reserve the right to deactivate
                    an Account until the dispute is resolved.
                  </p>
                  <h4>
                    <em>1.5 Parking Your Account</em>
                  </h4>
                  <p>
                    You may choose to park or pause your Account at any time.
                    Parking your Account temporarily suspends your Account to
                    give you time away from building your business on Kajabi
                    without canceling your Kajabi Account and losing your
                    Content. While your Account is parked, Kajabi will retain
                    your Content and you will have limited access allowing you
                    to reactivate your Account, adjust your Account, or update
                    your billing information. You will not have access to
                    customize, build, or add to your Content or Site while your
                    Account is parked. Your Site will be offline and will not be
                    accessible to your Contacts or Customers and your Customers
                    will not have access to any Content they have purchased
                    through your Site. Kajabi is in no way liable for any
                    disputes, claims, losses, injuries, or damages of any kind
                    that may arise from any impacts on your relationship with
                    your Customers as a result of parking your Account. You
                    agree to indemnify Kajabi for any such disputes with your
                    Customers per Section 11.
                    <br />
                  </p>
                  <p>
                    Kajabi (in its sole discretion) may park your Account if you
                    are delinquent in paying your Fees per Section 2.8.
                    <br />
                  </p>
                  <p>
                    You will be charged a monthly Subscription Fee to park your
                    Account and you can reactivate or cancel at any time.{" "}
                  </p>
                  <h3>
                    ‍<strong>2. Fees and Payments</strong>
                    <em>‍</em>
                  </h3>
                  <h4>
                    <em>2.1. Fees</em>
                  </h4>
                  <p>
                    <em>‍</em>1. You agree to pay Kajabi all applicable fees for
                    using the Platform per the pricing and payment terms
                    presented to you. Use of the Platform is on a subscription
                    basis, and you will be charged the applicable fees monthly
                    or annually, depending on the subscriptionplan you choose
                    (“Subscription Fees”). We use third-party payment processors
                    to bill you through a payment account linked to your
                    Account. The processing of your payments will be subject to
                    the applicable payment processor's terms, conditions, and
                    privacy policies. In addition to these Terms, you will also
                    be responsible for all transaction fees relating to using
                    such payment processors (“Transaction Fees”). You may also
                    incur additional fees relating to your purchase or use of
                    any other product or service, such as the Experts
                    Marketplace or other Third Party Services (as defined below)
                    (“Additional Fees”). The Subscription Fees, Transaction
                    Fees, and Additional Fees are collectively referred to as
                    the “Fees”.
                  </p>
                  <p>
                    2. You must keep a valid payment method on file with us to
                    pay for all incurred and recurring Fees. You will be billed
                    using the billing method you select and authorize through
                    your Account (“Authorized Payment Method”), and Kajabi will
                    continue to charge the Authorized Payment Method for
                    applicable fees until your Account is terminated and all
                    outstanding Fees have been paid in full.
                  </p>
                  <p>
                    3. Through our third-party providers, we will keep your
                    detailed payment information, such as non-sensitive credit
                    card partial numbers and expiration date, on file. We do not
                    access this information except through provided programmatic
                    methods by the provider(s). You are responsible for keeping
                    your payment details up-to-date by changing the details in
                    your Account.&nbsp; When your details change or are due to
                    expire, we may obtain or receive from your payment provider
                    updated payment details, including your card number,
                    expiration date, and CVV (or equivalent).&nbsp; You
                    authorize us to continue to charge your credit card using
                    the updated information. If you have elected to pay the Fees
                    by credit card, you represent and warrant that the credit
                    card information you provide is correct, and you will
                    promptly notify Kajabi of any changes to such information.
                    Unless otherwise indicated, all Fees and other charges are
                    in U.S. dollars, and all payments shall be in U.S. currency.
                    In no event will Kajabi credit, refund, or reimburse you for
                    a foreign exchange fee charged by your credit card or any
                    difference in fees due to currency conversion.
                  </p>
                  <h4>
                    ‍<em>2.2. Subscriptions</em>
                  </h4>
                  <p>
                    We bill for our Platform on a subscription basis
                    (“Subscriptions”), and you will be billed in advance on a
                    recurring, periodic basis (each period is called a “billing
                    cycle”). Billing cycles are monthly or annual, depending on
                    your subscription plan. We will charge your Authorized
                    Payment Method at purchase confirmation and the start of
                    every new billing cycle. Your Subscription will
                    automatically renew at the end of each billing cycle unless
                    you cancel your Account through your online account
                    management page or by contacting us at service@philippa.store
                    before the end of the billing cycle. While we will never
                    want to lose a customer, you may cancel your Subscription at
                    any time, in which case your Subscription will continue
                    until the end of that billing cycle before terminating. You
                    may cancel your Subscription immediately after it starts if
                    you do not want it to renew. You can view your renewal
                    date(s), cancel, or manage Subscriptions through your online
                    account management page or by contacting us at{" "}
                    <Link onClick={toTop} to="http://philippa.store">
                    service@philippa.store
                    </Link>
                    .
                  </p>
                  <h4>
                    ‍<em>2.3. Promotional Offers</em>
                  </h4>
                  <p>
                    <em>‍</em>We may offer special promotional offers, plans, or
                    memberships anytime. Kajabi determines offer eligibility at
                    its sole discretion, and we reserve the right to revoke an
                    offer and put your account on hold if we decide you are not
                    eligible. We may use information such as payment method or
                    an account email address used with an existing or recent
                    Kajabi membership to determine offer eligibility. The
                    eligibility requirements and other limitations and
                    conditions will be disclosed when you sign-up for the offer
                    or in other communications made available to you.
                  </p>
                  <p>
                    We may offer a promotional fee or discounted trial period
                    (“Trial Period”) anytime. To activate the Trial Period, you
                    must (i) subscribe to the eligible plan of your choice; and
                    (ii) provide an Authorized Payment Method at enrollment.
                    Upon the expiration of the Trial Period, we will charge your
                    Authorized Payment Method the full price of the applicable
                    plan starting on the day the Trial Period ends unless you
                    have canceled before the expiration of the Trial Period. You
                    will then be charged monthly or annually, as applicable, at
                    the then-current rate for your selected plan until you
                    cancel or change to a different one. Before the expiration
                    of the Trial Period, we will notify you of the upcoming end
                    of your Trial Period by email to your Primary Email Address.
                    If the Authorized Payment Method we have on file for you is
                    declined, you must provide us with a new, valid Authorized
                    Payment Method, or we reserve the right, without further
                    notice, to immediately suspend or cancel your Account and
                    your continued use of the Platform.&nbsp;
                  </p>
                  <h4>
                    ‍<em>2.4. Upgrades or Downgrades</em>
                  </h4>
                  <p>
                    <em>‍</em>Any change (including any upgrade, downgrade, or
                    other modification) to any plan by you in a Trial Period
                    will end the trial immediately.&nbsp; Upon upgrading, you
                    will immediately be billed for your first term period (i.e.,
                    the first annual or monthly period). Your Authorized Payment
                    Method will automatically be charged the new rate on your
                    next billing cycle for any upgrade or downgrade in plan
                    level after any trial period. You will be billed immediately
                    for the prorated difference for the current billing cycle
                    for any upgrade to any higher-priced plan during that
                    billing cycle. If a plan downgrade causes a credit to your
                    Account, this credit will be used toward your next billing
                    cycle(s). This credit will not be refunded.
                  </p>
                  <h4>
                    ‍<em>2.5. Taxes</em>{" "}
                  </h4>
                  <p>
                    Our prices listed do not include any taxes, levies, duties,
                    or similar governmental assessments of any nature, such as
                    value-added, sales, use, or withholding taxes, assessable by
                    any jurisdiction (collectively, “Taxes”) unless otherwise
                    indicated. You are responsible for paying all Taxes
                    associated with your purchase and keeping your billing
                    information up to date. If any amount payable by you to
                    Kajabi is subject to Taxes, Kajabi may, at its sole
                    discretion, collect from you the full amount of any such
                    Taxes, even if Kajabi has not previously collected such
                    amounts. Any changes to your Account or Subscription
                    (including any upgrade, downgrade, or other modification)
                    may result in Kajabi collecting from you applicable Taxes.
                    Kajabi will notify you if the collection of Taxes results in
                    a price change to your Subscription, as detailed below in
                    Section 2.6 <em>Price Changes</em>.&nbsp;
                  </p>
                  <p>
                    1. <strong>United States Sales Tax</strong>.&nbsp; If we
                    have a legal obligation to pay or collect sales tax for
                    which you are responsible, we will calculate the sales tax
                    based on the billing information we have about you and
                    charge you that amount (which, if your billing information
                    is incomplete or inaccurate, may be the highest prevailing
                    rate then in effect), unless you provide us with a valid tax
                    exemption certificate acceptable to the appropriate taxing
                    authority within the time frame required by law. If you
                    provide us with a tax exemption certificate, you represent
                    and warrant that it accurately reflects your tax status and
                    that you will keep such document current and accurate. If we
                    subsequently determine in our sole discretion that your tax
                    exemption document is valid, we will refund the sales tax
                    collected based on applicable state tax laws.
                  </p>
                  <p>
                    2. <strong>Non-United States Sales Tax</strong>.&nbsp; If
                    applicable, we will charge you VAT, GST, or any other sales,
                    consumption, or use Taxes that arise in connection with your
                    use of the Platform unless you provide us with a tax
                    identification number that entitles you to an exemption, a
                    valid tax exemption certificate, or other documentary proof
                    issued by an appropriate taxing authority that Tax should
                    not be charged. If you are located in a jurisdiction with
                    multiple sales, consumption, or use Taxes, we may charge you
                    the highest prevailing rate if your billing information is
                    incomplete or inaccurate.&nbsp;
                  </p>
                  <p>
                    If you are legally required to withhold any Taxes from your
                    payments to Kajabi, you must provide Kajabi with an official
                    tax receipt or other appropriate documentation to support
                    such payments.
                  </p>
                  <h4>
                    ‍<em>2.6. Price Changes</em>
                  </h4>
                  <p>
                    <em>‍</em>Kajabi may change the Fees charged to you at any
                    time and will communicate any price changes to you in
                    advance and, if applicable, how to accept those changes.
                    Price changes for Subscriptions will take effect at the
                    start of the next billing cycle following the date of the
                    price change. As permitted by local law, you accept the new
                    price by continuing to use your Subscription after the price
                    change takes effect. If you do not agree with the price
                    changes, you have the right to reject the change by
                    canceling your Subscription before the price change goes
                    into effect. Please make sure you read any such notification
                    of price changes carefully.
                  </p>
                  <h4>
                    ‍<em>2.7. Refund Policy </em>
                  </h4>
                  <p>
                    <em>‍</em>We want all new Heroes to be excited about working
                    with us.&nbsp; In that regard, we provide a full
                    no-questions-asked refund within thirty (30) days of first
                    signing up with Kajabi. Simply email{" "}
                    <Link onClick={toTop} to="http://service@philippa.store">
                      service@philippa.store
                    </Link>{" "}
                    within thirty (30) days of your sign-up, letting us know you
                    are canceling your Subscription.&nbsp; After that, all
                    payments are nonrefundable, and there are no refunds or
                    credits for partially used Subscriptions.&nbsp; Following
                    any cancellation, you will continue to have access to the
                    Platform through the end of your current billing
                    cycle.&nbsp; We reserve the right to refuse refunds to
                    anyone who abuses this Refund Policy.<em>‍</em>
                  </p>
                  <h4>
                    <em>2.8. Delinquencies</em>‍
                  </h4>
                  <p>
                    1. If Kajabi cannot process payment of any Fees using an
                    Authorized Payment Method, we may make subsequent attempts
                    to process payment using any Authorized Payment Method. If
                    the outstanding Fees are not paid within 28 days of our
                    initial attempt, Kajabi may (in its sole discretion)
                    suspend, park, and/or revoke access to your Account and/or
                    the Platform. Your full access to the Platform will be
                    reactivated upon your payment of all outstanding Fees, plus
                    the Fees applicable to your next billing cycle.
                  </p>
                  <p>
                    2. Kajabi may (in its sole discretion) terminate your
                    Account and/or access to the Platform per Section 8 if the
                    outstanding Fees remain unpaid for 30 days following the
                    date of suspension or parking.&nbsp;
                  </p>
                  <p>
                    3. You may not be able to access the features of your
                    Account or the Platform during any period of suspension,
                    parking, or after termination, which may impact your ability
                    to provide your Content, products, and/or services to, or
                    process payments from, your Customers. Kajabi is in no way
                    liable for any disputes, claims, losses, injuries, or
                    damages of any kind that may arise from any impacts on your
                    relationship with your Customers as a result of any
                    suspension, parking, or termination of your Account or
                    access to the Platform. You agree to indemnify Kajabi for
                    any such disputes with your Customers per Section 11.
                  </p>
                  <h3>
                    ‍<strong>3. Your Content</strong>‍
                  </h3>
                  <h4>
                    ‍
                    <em>
                      3.1. You Retain Ownership of &amp; Responsibility for Your
                      Content
                    </em>
                    ‍
                  </h4>
                  <p>
                    1. You retain ownership of all your intellectual property
                    rights in your Content. Kajabi does not claim ownership over
                    any of your Content. You are responsible for your Content
                    and the consequences of posting or publishing it. These
                    Terms do not grant us any licenses or rights to your Content
                    except for the limited rights needed for us to provide the
                    Platform to you, and as otherwise described in these Terms.
                    Should any of your Content involve third-party content, you
                    agree that Kajabi is not responsible in any way for such
                    content and makes no representations or warranties as to
                    such third-party content.
                  </p>
                  <p>
                    2. You acknowledge and agree that the Platform is not a
                    marketplace, and any contract of sale made through the
                    Platform is directly between you and your Customers. You are
                    the seller of record for all courses and other offerings you
                    sell through the Platform. You are responsible for creating
                    and operating your Site, your Content, the courses, and
                    other offerings you may sell through the Platform, and all
                    aspects of the transactions between you and your Customers.
                    This includes, without limitation, authorizing the charge to
                    the Customer in respect of the Customer’s purchase, refunds,
                    returns, fulfilling any sales or customer service,
                    fraudulent transactions, required legal disclosures,
                    regulatory compliance, alleged or actual violation of
                    applicable laws (including but not limited to consumer
                    protection laws in any jurisdiction where you offer products
                    or services for sale), or your breach of these Terms. You
                    represent and warrant that your Site, your Content, and any
                    goods and services you sell through the Platform will be
                    true, accurate, and complete and will not violate any
                    applicable laws, regulations, or rights of third parties.
                    For the avoidance of doubt, Kajabi will not be the seller or
                    merchant of record and will have no responsibility for your
                    Site, your Content, or offerings sold to Customers through
                    the Platform.
                  </p>
                  <p>
                    3. You represent and warrant to us that: (a) you own or have
                    the necessary licenses, rights, consents, or permissions to
                    use or publish the Content that you include, submit, or use
                    through our Platform; and (b) none of your Content or your
                    Customers’ use of your Content or the Platform will violate
                    any applicable laws, any third-party intellectual property,
                    privacy, publicity, or other rights, or the{" "}
                    <Link onClick={toTop} to="/privacy">
                      Acceptable Use Policy
                    </Link>
                    . You may not represent or imply to others that your Content
                    is in any way sponsored, provided, or endorsed by
                    Kajabi.&nbsp;
                  </p>
                  <p>
                    4. You are solely responsible for determining the fees you
                    charge your Customers for use and/or access to your Content,
                    Site(s), and offerings and for any refund policy you set
                    regarding the sale or use of your Content. The Platform may
                    permit you to export your Content, including links to videos
                    created through the Platform, including by sharing to social
                    media and networking sites. You are solely responsible for
                    your distribution of such exports or links and for
                    compliance with the terms of any third-party websites,
                    applications, services, or products through which you
                    distribute such exports and links.
                  </p>
                  <p>
                    5. You may not use the Platform for any illegal or
                    unauthorized purpose (including as outlined in the{" "}
                    <Link onClick={toTop} to="/privacy">
                      Acceptable Use Policy
                    </Link>
                    ), nor may you, in the use of the Platform, violate any laws
                    in your jurisdiction, the laws applicable to you in your
                    Customers’ jurisdictions, or the laws of the United States
                    or California. You will comply with all applicable laws,
                    rules, and regulations (including but not limited to
                    obtaining any required consents from your Customers and/or
                    Contacts and obtaining and complying with requirements of
                    any license or permit that may be necessary to operate your
                    Site) in your use of the Platform and your performance of
                    the obligations under these Terms.&nbsp;
                  </p>
                  <p>
                    6. You can remove your Site anytime by deleting your
                    Account. Removing your Site does not terminate any rights or
                    licenses granted to the Content that Kajabi requires to
                    exercise any rights or perform any obligations under these
                    Terms. You agree that Kajabi can, at any time, review and
                    delete any or all of your Content, although we are not
                    required to do so.&nbsp;
                  </p>
                  <p>
                    7. Kajabi is not responsible for any actions you take
                    concerning your Content, including sharing it publicly, and
                    we assume no liability for your interactions with your
                    Customers, Contacts, or other users of your Site or Content.
                    Kajabi reserves the right but has no obligation to monitor
                    disputes between you and your Customers and Contacts. Kajabi
                    is not obligated to monitor or review any Content submitted
                    to the Platform, nor do we pre-screen any Content.&nbsp;
                  </p>
                  <h4>
                    ‍<em>3.2. Limited License to Your Content</em>
                  </h4>
                  <p>
                    While we do not claim any intellectual property rights over
                    your Content, by uploading the Content through the Platform,
                    you grant Kajabi a worldwide, royalty-free license to host,
                    use, display, distribute, make publicly available, store,
                    make available, create derivative works from and otherwise
                    exploit your Content. We may use our rights under this
                    license to operate, provide, and improve the Platform, to
                    promote Kajabi and the Platform, and to otherwise perform
                    our obligations and exercise our rights under these Terms
                    and as otherwise permitted by the{" "}
                    <Link onClick={toTop} to="/privacy">
                      Privacy Policy
                    </Link>
                    . This license continues even after you stop using our
                    Platform for aggregate and de-identified data derived from
                    your Content and any residual backup copies of your Content
                    made in the ordinary course of our business. This license
                    also extends to any trusted third parties we work with to
                    the extent necessary to provide the Platform to you. You
                    understand that your Content may be transferred unencrypted
                    and involve (a) transmissions over various networks; and (b)
                    changes to conform and adapt to the technical requirements
                    of connecting networks or devices.
                  </p>
                  <h4>
                    ‍<em>3.3. Kajabi’s Right to Use Your Name </em>
                  </h4>
                  <p>
                    <em>‍</em>You grant to Kajabi a non-exclusive, transferable,
                    sub-licensable, royalty-free, worldwide right and license to
                    use the names, trademarks, service marks, and logos
                    associated with your Site to operate, provide, market, and
                    promote the Platform and to perform our obligations and
                    exercise our rights under these Terms.&nbsp; Any goodwill
                    arising from the use of your name and logo will inure to
                    your benefit. This license will survive any termination of
                    these Terms solely to the extent that Kajabi requires the
                    license to exercise any rights or perform any obligations
                    that arise under these Terms.
                  </p>
                  <h4>
                    ‍<em>3.4. Intellectual Property Infringement.&nbsp; </em>
                  </h4>
                  <p>
                    <em>‍</em>Kajabi fully respects the intellectual property
                    rights of others, and we expect our users to do the
                    same.&nbsp;
                  </p>
                  <p>
                    1. If we receive a complaint that your Content infringes on
                    a third party’s copyright, we must respond per the U.S.
                    Digital Millennium Copyright Act (DMCA). If you believe your
                    work has been exploited in a way that constitutes copyright
                    infringement, you may notify us per our{" "}
                    <Link
                      onClick={toTop}
                      to="https://legal.kajabi.com/policies/copyright-dmca-policy"
                    >
                      Copyright (“DMCA”) Policy
                    </Link>
                    .
                  </p>
                  <p>
                    2. If you believe a Kajabi Hero infringes upon your
                    intellectual property rights other than copyrights (such as
                    trademark infringement), please contact us at{" "}
                    <Link
                      onClick={toTop}
                      to="mailto:copyright@kajabi.com?subject=copyright%40kajabi.com"
                    >
                      service@philippa.store
                    </Link>
                    .
                  </p>
                  <h3>
                    ‍<strong>4. Privacy, Security &amp; Confidentiality</strong>
                    ‍
                  </h3>
                  <h4>
                    ‍
                    <em>
                      4.1. Your Responsibility to Comply with Data Protection,
                      Security &amp; Privacy Laws
                    </em>
                  </h4>
                  <p>
                    We are firmly committed to protecting the privacy of your
                    and your Customers’ and Contacts’ personal information. By
                    using the Platform, you acknowledge and agree that our
                    Privacy Policy governs our collection, usage, and disclosure
                    of this personal information.&nbsp; Further, you agree and
                    warrant that you are solely responsible for complying with
                    all applicable data protection, security, and privacy laws
                    and regulations, including any notice and consent
                    requirements. This includes, without limitation, the
                    collection and processing by you of any personal data,
                    sending marketing or other emails, and when using cookies
                    and similar technologies on your Sites. If applicable law
                    requires, you must provide and make a legally compliant
                    privacy policy and cookie policy available to your
                    customers. You must capture valid consent, both for you and
                    us, for any cookies or similar technologies used on or
                    through your Site where required. Please see our{" "}
                    <Link onClick={toTop} to="/cookie">
                      Cookie Notice
                    </Link>{" "}
                    and our{" "}
                    <Link
                      onClick={toTop}
                      to="https://help.kajabi.com/hc/en-us/articles/1260804142350"
                    >
                      Help Center
                    </Link>{" "}
                    for more information about using cookies and similar
                    technologies
                  </p>
                  <h4>
                    ‍<em>4.2. Privacy</em>
                  </h4>
                  <p>
                    <em>‍</em>We know that by giving us your Content, you trust
                    us to treat it securely. Our Privacy Policy details how we
                    treat your Content that is considered Personal Data (as
                    defined in our{" "}
                    <Link
                      onClick={toTop}
                      to="https://assets.website-files.com/5db0d55a083f4b7cd59d2e42/6231593210fd2d9ffe15db07_Kajabi-%20DPA%20March%202022.pdf"
                    >
                      Data Protection Addendum (the “DPA”)
                    </Link>
                    ), and we agree to adhere to our Privacy Policy. You agree
                    that Kajabi may use and share your Content (including your
                    Customers’ and Contacts’ Personal Data) per our Privacy
                    Policy and applicable data protection laws (this includes
                    sharing such information with our group of Kajabi companies
                    to provide, develop, and improve the Platform and other
                    Kajabi products and services that you connect through the
                    Platform or otherwise use per the applicable terms of
                    service, including to provide you with greater insights
                    about your business and to personalize your user experience
                    within the Platform). Our DPA also applies to and
                    supplements these Terms. Where there is a conflict between
                    the DPA and these Terms, the DPA will prevail except for
                    Disclaimer and Limitation of Liability, where these Terms
                    will prevail.
                  </p>
                  <h4>
                    ‍<em>4.3. Confidentiality </em>
                  </h4>
                  <p>
                    <em>‍</em>Kajabi will treat your Content as confidential and
                    only use and disclose it per the Terms (including the{" "}
                    <Link onClick={toTop} to="/privacy">
                      Privacy Policy
                    </Link>
                    ). However, your Content is not regarded as confidential
                    information if such Content: (a) is or becomes public (other
                    than through our breach of the Terms); (b) was lawfully
                    known to Kajabi before receiving it from you; (c) is
                    received by Kajabi from a third party without our knowledge
                    of a breach of any obligation owed to you; or (d) was
                    independently developed by Kajabi without reference to your
                    Content. Kajabi may disclose your Content when required by
                    law or legal process, but only after we, if permitted by
                    law, use commercially reasonable efforts to notify you to
                    allow you to challenge the requirement to disclose.&nbsp;
                    (See our{" "}
                    <Link onClick={toTop} to="/privacy">
                      Guidelines for Information Requests
                    </Link>
                    .)<em>‍</em>
                  </p>
                  <h4>
                    <em>4.4. Security</em>&nbsp;{" "}
                  </h4>
                  <p>
                    Kajabi will store and process your Content consistent with
                    industry security standards. We have implemented appropriate
                    technical, organizational, and administrative systems,
                    policies, and procedures designed to help ensure the
                    security, integrity, and confidentiality of your Content and
                    to mitigate the risk of unauthorized access to or use of
                    your Content. Kajabi cannot, however, guarantee that
                    unauthorized third parties will never be able to defeat
                    those measures or use such Content for improper purposes.
                  </p>
                  <p>
                    If Kajabi becomes aware of any unauthorized or unlawful
                    access to, or acquisition, alteration, use, disclosure, or
                    destruction of, Personal Data related to your Account
                    (“Security Incident”), we will take reasonable steps to
                    notify you without undue delay. Such notification shall not
                    be interpreted or construed as an admission of liability or
                    fault by Kajabi. A Security Incident does not include
                    unsuccessful attempts or activities that do not compromise
                    the security of Personal Data, including unsuccessful log-in
                    attempts, pings, port scans, denial of service attacks, or
                    other network attacks on firewalls or networked systems.
                    Kajabi will also reasonably cooperate with you regarding any
                    investigations relating to a Security Incident by helping
                    prepare any required notices and providing any other
                    information reasonably requested concerning any Security
                    Incident where such information is not already available to
                    you in your account or online through updates provided by
                    Kajabi.
                    <strong>‍</strong>
                  </p>
                  <h3>
                    <strong>5. Kajabi’s Intellectual Property</strong>‍
                  </h3>
                  <h4>
                    ‍<em>5.1.&nbsp;Kajabi’s Trademarks</em>
                  </h4>
                  <p>
                    1. Neither the Terms nor your use of the Platform grants you
                    ownership in the Platform or any content you access through
                    the Platform (other than your Content). You agree that you
                    may not use any trademarks, logos, or other brand elements
                    of Kajabi, whether registered or unregistered, including but
                    not limited to the word mark KAJABI and the “K” logo
                    (“Kajabi Trademarks”) unless you are authorized to do so by
                    Kajabi in writing.&nbsp; You agree not to use or adopt any
                    marks that may be confusing with Kajabi’s Trademarks. You
                    agree that any variations or misspellings of the Kajabi
                    Trademarks would be considered confusing with the Kajabi
                    Trademarks.&nbsp;&nbsp;
                  </p>
                  <p>
                    2. You agree that you will not purchase, register, or use a
                    search engine or other pay-per-click keywords (such as
                    Google Ads), trademarks, email addresses, social media
                    names, or domain names (including without limitation
                    top-level domains, sub-domains, and page URLs) that use any
                    Kajabi Trademark and/or variations and misspellings
                    thereof.&nbsp;
                  </p>
                  <h4>
                    ‍<em>5.2. Kajabi’s Platform&nbsp;&nbsp;</em>
                  </h4>
                  <p>
                    <em>‍</em>1. We or our licensors own all right, title, and
                    interest in and to the Platform and all related technology
                    and intellectual property rights. Subject to the Terms, we
                    grant you a limited, revocable, non-exclusive,
                    non-sublicensable, non-transferable license to access and
                    use the Platform solely per the Terms. Except as provided in
                    this Section 5.2., you obtain no rights from us, our
                    affiliates, or our licensors to the Platform, including any
                    related intellectual property rights.
                  </p>
                  <p>
                    2. We reserve the right to provide our Platform to your
                    competitors and make no promise of exclusivity in any
                    particular market segment. You further acknowledge and agree
                    that Kajabi employees and contractors may also be Kajabi
                    Heroes and that they may compete with you.&nbsp;
                  </p>
                  <h4>
                    ‍<em>5.3. Feedback</em>
                  </h4>
                  <p>
                    Kajabi welcomes all ideas and suggestions regarding
                    improvements or additions to the Platform. All ideas,
                    suggestions, comments, input, feedback, and related
                    materials and any review of the Platform or any Expert (as
                    defined below), Third Party Services (as defined below), or
                    any Third Party Provider (as defined below) will
                    collectively be referred to as “Feedback”. Kajabi will have
                    no obligation of confidentiality for any such Feedback. By
                    submitting Feedback to Kajabi (whether submitted directly to
                    Kajabi or posted on any Kajabi-hosted forum or page), you
                    waive any and all rights in the Feedback and agree that
                    Kajabi is free to use, reproduce, publicly display,
                    distribute, modify, implement, or publicly perform the
                    Feedback if desired, as provided by you or as modified by
                    Kajabi, without obtaining permission or license from you or
                    any third party. Any Feedback you provide, which Kajabi may
                    use, is given entirely voluntarily and without any
                    expectation of compensation or credit.&nbsp;
                  </p>
                  <p>
                    Any reviews of an Expert, Third Party Service, or Third
                    Party Provider that you submit to Kajabi must be accurate to
                    the best of your knowledge and must not be illegal, obscene,
                    threatening, defamatory, invasive of privacy, infringing of
                    intellectual property rights, or otherwise injurious to
                    third parties or objectionable. Kajabi reserves the right
                    (but not the obligation) to remove or edit Feedback about
                    Experts, Third Party Services, or Third Party Providers but
                    does not regularly inspect posted Feedback.
                  </p>
                  <h3>
                    ‍
                    <strong>
                      6. Additional Services; Third Party Services
                    </strong>
                    ‍
                  </h3>
                  <h4>
                    ‍<em>6.1. Kajabi Email</em>
                  </h4>
                  <p>
                    <em>‍</em>You may generate or send emails from your Account
                    using Kajabi’s email services (the “Email Services”). In
                    addition to the Terms applicable to the Platform generally,
                    your use of the Email Services is subject to Kajabi’s{" "}
                    <Link onClick={toTop} to="/privacy">
                      Anti-Spam Policy
                    </Link>{" "}
                    and the following terms:
                  </p>
                  <p>
                    1. Kajabi employs specific controls to scan the content of
                    emails you deliver using the Email Services before delivery
                    (“Content Scanning”). Such Content Scanning is intended to
                    limit spam, phishing, or other malicious content that
                    violates these Terms, our Acceptable Use Policy, or the
                    Anti-Spam Policy (collectively, “Threats”). By using the
                    Email Services, you explicitly grant Kajabi the right to
                    employ such Content Scanning. Kajabi does not warrant that
                    the Email Services will be free from Threats, and each Hero
                    is responsible for all Content generated by its Site.&nbsp;
                  </p>
                  <p>
                    2. Our Email Services utilize third-party service providers,
                    including{" "}
                    <Link
                      onClick={toTop}
                      to="https://www.mailgun.com/?utm_term=mailgun&utm_campaign=750089235&utm_content=&utm_source=google&utm_medium=cpc&creative=295627675333&keyword=mailgun&matchtype=e&network=g&device=c"
                    >
                      MailGun
                    </Link>
                    . Your Email Services use is subject to such provider’s
                    terms and policies. A breach of such terms and policies is a
                    breach of Kajabi’s Terms.
                  </p>
                  <p>
                    3. We and our third-party service providers reserve the
                    right to change or discontinue the Email Services. We are
                    not responsible for third-party service providers’ actions
                    or links as a third-party service.
                  </p>
                  <p>
                    4. You are responsible for understanding and complying with
                    all applicable laws, rules, and regulations and determining
                    whether the Email Services suit you in light of such laws,
                    rules, and regulations.
                  </p>
                  <p>
                    5. We cannot guarantee the security of every data
                    transmission over the internet.
                  </p>
                  <h4>
                    ‍<em>6.2. Kajabi App</em>
                  </h4>
                  <p>
                    1. The Kajabi app is a Kajabi application that is available
                    to Heroes (the “Kajabi App”) to engage with Customers that
                    use the Kajabi App.
                  </p>
                  <p>
                    2. The Kajabi App is part of the Platform, and all terms
                    applicable to the Platform apply. Without limiting the
                    generality of the preceding sentence and subject to these
                    Terms and all applicable guidelines and policies, Kajabi
                    reserves the right to refuse a Hero access to or use of all
                    or part of the Kajabi App for any reason and at any time
                    without prior notice.&nbsp;
                  </p>
                  <p>
                    3. We may display your Content, including courses, product
                    or service listings, Trademarks, and any other content,
                    data, or information from your Site on the Kajabi App. Your
                    Content and use of the Kajabi App must comply with these
                    Terms, the Acceptable Use Policy, all other applicable
                    policies and guidelines, and applicable law.
                  </p>
                  <p>
                    4. If you do not wish your Content to be available in the
                    Kajabi App, it is your responsibility to manage or
                    deactivate this functionality. If you delete your Account,
                    your Content will be removed from the Kajabi App.
                  </p>
                  <h4>
                    ‍<em>6.3. Third-Party Services; Experts Marketplace</em>
                  </h4>
                  <p>
                    1. Kajabi may recommend or provide you access to third-party
                    software, applications, services, or websites for your
                    consideration or use (“Third Party Services”), including via
                    the{" "}
                    <Link onClick={toTop} to="https://experts.kajabi.com/">
                      Kajabi Experts Marketplace
                    </Link>
                    . These optional tools and integrations are made available
                    to you as a convenience, and your use of these third-party
                    providers is solely between you and the applicable service
                    provider (“Third Party Provider”). In addition to these
                    Terms, you agree to be bound by the terms, policies, and
                    guidelines of any Third Party Provider whose Third Party
                    Services you purchase, access, or use. You should review
                    such Third Party Provider’s applicable terms and policies,
                    including privacy and data-gathering practices. You should
                    make whatever investigation you feel necessary or
                    appropriate before proceeding with any transaction with any
                    Third Party Provider.
                  </p>
                  <p>
                    2. The Kajabi Experts Marketplace is an online directory of
                    independent third parties (“Experts”) providing Heroes with
                    specific service offerings to help you build and operate
                    your Kajabi Site. The Experts Marketplace provides
                    recommendations on Experts that can assist you with
                    different aspects of your Site. Using Experts Marketplace,
                    you can find, hire, and communicate with Experts directly
                    from your Account. For purposes of these terms, Experts are
                    deemed “Third Party Providers,” and the services they
                    provide are considered “Third Party Services”.
                  </p>
                  <p>
                    3. Any use by you of Third Party Services offered through
                    the Platform, Experts Marketplace, or otherwise through
                    Kajabi’s website is entirely at your own risk and
                    discretion, and it is your responsibility to read the terms
                    and conditions and/or privacy policies applicable to such
                    Third Party Services before using them. In some instances,
                    Kajabi may receive a revenue share from Third Party
                    Providers that we recommend to you or that you otherwise
                    engage through using the Platform, Experts Marketplace, or
                    Kajabi’s website.
                  </p>
                  <p>
                    4. We do not monitor or have any control over, and we make
                    no claim, warranty, or representation regarding any Third
                    Party Services, and we accept no responsibility for
                    reviewing changes or updates to or the quality, content,
                    policies, nature, or reliability of, any such Third Party
                    Services. The availability of any Third Party Services,
                    including through the Experts Marketplace, or the
                    integration or enabling of such Third Party Services with
                    the Platform does not constitute or imply an endorsement,
                    authorization, sponsorship, or affiliation by or with
                    Kajabi. Your use of any Third Party Services is at your own
                    risk, and you expressly release Kajabi from any and all
                    liability arising from your use of any Third Party Services.
                    You acknowledge that Kajabi has no control over such Third
                    Party Services and shall not be responsible or liable to you
                    or anyone else arising from or relating to your use of any
                    Third Party Services. Kajabi does not guarantee the
                    availability of such Third Party Services, and you
                    acknowledge that Kajabi may restrict access to any Third
                    Party Service at any time in its sole discretion and without
                    notice to you. We are not responsible or liable to anyone
                    for discontinuation or suspension of access to, or
                    disablement of, any Third Party Service. Kajabi strongly
                    recommends that you seek specialist advice before using or
                    relying on any Third Party Services to ensure they will meet
                    your needs. In particular, tax calculators should be used
                    for reference only and not as a substitute for independent
                    tax advice when assessing the correct tax rates you should
                    charge your Customers.
                  </p>
                  <p>
                    5. If you install a Third Party Service for use with the
                    Platform, (i) you represent that you have agreed to such
                    Third Party Provider’s terms and conditions and are subject
                    to such terms and conditions; and (ii) you grant us
                    permission to allow the applicable Third Party Provider to
                    access your Content or other data and to take any other
                    actions as required for the interoperation of the Third
                    Party Services with our Platform. If you do not agree to
                    abide by the applicable terms and conditions for any such
                    Third Party Service, then you should not install or use such
                    Third Party Service with the Platform. Any data exchange or
                    other Content or other interaction between you and the Third
                    Party Provider is solely between you and such Third Party
                    Provider. We are not responsible for any disclosure,
                    modification, or deletion of your data or Content or for any
                    corresponding losses or damages you may suffer due to access
                    by a Third Party Service or Third Party Provider to your
                    data or Content.
                  </p>
                  <p>
                    6. You acknowledge and agree that: (i) by submitting a
                    request for assistance or other information through Experts
                    Marketplace, you consent to be contacted by one or more
                    Experts at the Primary Email Address (or such other email
                    address provided by you) as well as the applicable user
                    email address; and (ii) Kajabi will receive all email
                    communications exchanged via Experts Marketplace or in any
                    reply emails (each a “Reply”) that originate from Experts
                    Marketplace (directly or indirectly) between yourself and
                    Experts. You further agree that Kajabi may share your
                    contact details and the background information you submit
                    via the Experts Marketplace with Experts. Experts may
                    require access to certain admin pages on your Site. You
                    choose the pages that the Experts can access.
                  </p>
                  <p>
                    7. The Experts Marketplace may allow users to post reviews
                    (e.g., a star rating) of Third Party Services and comments
                    on your or other users’ reviews.
                  </p>
                  <p>
                    <strong>Usernames Displayed</strong>. Reviews and comments
                    are posted under the name and profile of the Hero submitting
                    the content (as listed in your Account). Heroes who do not
                    want their names or other profile information to appear may
                    not post reviews or comments on the Experts Marketplace.
                  </p>
                  <p>
                    <strong>Rules for Reviews</strong>. All reviews and comments
                    must comply with Kajabi’s{" "}
                    <Link onClick={toTop} to="/privacy">
                      Acceptable Use Policy
                    </Link>{" "}
                    and the terms below. To make your reviews and comments
                    helpful to others:
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;- Reviews must be made in good faith after
                    reasonably evaluating the relevant Third Party Service.
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;- You (including anyone acting on your
                    behalf) may not review or comment on your own Third Party
                    Services, a Third Party Service owned by a company you work
                    for, or those of competitors. As an exception, you may
                    provide informational responses to support requests or other
                    inquiries directed to you within the reviews or comments
                    section of your Third Party Service listing.
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;- A review must evaluate the Third Party
                    Service itself and not be an evaluation of the underlying
                    product with which the Third Party Service integrates or
                    functions.
                  </p>
                  <p>
                    &nbsp;&nbsp;&nbsp;- Reviews or comments unrelated to the
                    relevant Third Party Service are prohibited – for example,
                    discussing Kajabi’s employees, business, or those of other
                    companies, or unrelated products or services.
                  </p>
                  <p>
                    <strong>Kajabi’s Rights</strong>. Kajabi reserves the right,
                    in its sole discretion and for any reason at any time, to
                    remove or edit any review or comment on the Experts
                    Marketplace. Kajabi does not claim ownership of the content
                    of reviews or comments you post on the Kajabi Marketplace.
                    However, you hereby grant Kajabi a nonexclusive, worldwide,
                    irrevocable, perpetual, transferable, sublicensable (through
                    multiple tiers), fully paid-up, royalty-free license to use,
                    distribute, reproduce, modify, excerpt, attribute, adapt,
                    publicly perform, and publicly display that content (in
                    whole or in part) and to incorporate it into other works in
                    any format or medium now known or later developed, and to
                    permit others to do so.
                  </p>
                  <p>
                    8. The relationship between you and any Third Party Provider
                    is strictly between you and such Third Party Provider.
                    Kajabi is not obligated to intervene in any dispute between
                    you and a Third Party Provider.
                  </p>
                  <p>
                    9. Under no circumstances will Kajabi be liable for any
                    direct, indirect, incidental, special, consequential,
                    punitive, extraordinary, exemplary, or other damages
                    resulting from any Third Party Services or your contractual
                    relationship with any Third Party Provider, including any
                    Expert. These limitations will apply even if we have been
                    advised of the possibility of such damages. The foregoing
                    limitations will apply to the fullest extent permitted by
                    applicable law.
                  </p>
                  <p>
                    10. Per Section 11, you agree to indemnify and hold us and
                    our Team harmless from any claim or demand, including
                    reasonable attorneys’ fees, arising from your use of a Third
                    Party Service or your relationship with a Third Party
                    Provider.
                  </p>
                  <h4>
                    ‍<em>6.4. Beta Services</em>‍
                  </h4>
                  <p>
                    From time to time, Kajabi may, in its sole discretion,
                    invite you to use, on a trial basis, pre-release or beta
                    features that are in development and not yet available to
                    all Heroes (“Beta Services”). Beta Services may be subject
                    to specific Beta Program Terms and may be subject to
                    additional rules or restrictions, which Kajabi will provide
                    to you before your use of the Beta Services. Beta Services
                    may be provided for evaluation or testing purposes only and,
                    therefore, may contain bugs or errors and may not be as
                    reliable as other features of the Platform. Such Beta
                    Services and all associated conversations and materials
                    relating thereto will be considered Kajabi confidential
                    information. When using our Beta Services, you agree that
                    you will not make any public statements or otherwise
                    disclose your participation in the Beta Services without
                    Kajabi’s prior written consent. Kajabi makes no
                    representations or warranties that the Beta Services will
                    function, and the Beta Services are provided as-is. Kajabi
                    may discontinue the Beta Services at any time in its sole
                    discretion, and we may decide not to make the Beta Services
                    generally available. Kajabi will have no liability for any
                    harm or damage arising from or in connection with a Beta
                    Service.
                  </p>
                  <h4>
                    ‍<em>6.5. Kajabi Communities</em>
                  </h4>
                  <p>
                    <em>‍</em>Kajabi Communities enables Heroes to create a
                    community within their Site (“Your Community”) to be offered
                    to your Customers for free or for a fee to connect, to
                    message, and to exchange information and content
                    (collectively, “Messages”). As with all other uses of the
                    Platform, when you create Your Community, you agree to
                    follow and ensure your Customers are always following our{" "}
                    <Link onClick={toTop} to="/privacy">
                      Acceptable Use Policy
                    </Link>
                    . Kajabi may terminate the ability of a Customer to send
                    Messages at any time and for any reason without notice or
                    liability to that Customer. If a Customer sends you or
                    another Customer an objectionable Message, please notify us
                    by emailing{" "}
                    <Link onClick={toTop} to="mailto:service@philippa.store">
                    service@philippa.store
                    </Link>
                    . You agree that we may monitor Messages for compliance with
                    these Terms. Therefore, Messages are not confidential or
                    proprietary, and you understand that any information sent
                    using Messages has been disclosed beyond the parties to the
                    Messages.&nbsp;
                  </p>
                  <h4>
                    ‍<em>6.6. Coaching&nbsp;&nbsp;</em>
                  </h4>
                  <p>
                    <em>‍</em>1. Our Coaching Service utilizes third-party
                    service providers, including{" "}
                    <Link onClick={toTop} to="https://www.daily.co/">
                      Daily.co
                    </Link>{" "}
                    and{" "}
                    <Link onClick={toTop} to="https://whereby.com/">
                      Whereby
                    </Link>
                    . Your use of the Coaching Service is subject to such
                    third-party service providers’ terms and conditions. A
                    breach of such third-party service providers’ terms and
                    conditions is a breach of Kajabi’s Terms.
                  </p>
                  <p>
                    2. We and our third-party service providers reserve the
                    right to change or discontinue the Coaching Services. We are
                    not responsible for third-party service providers’ actions
                    or links as a Third Party Service.
                  </p>
                  <p>
                    3. You are responsible for understanding and complying with
                    all applicable laws, rules, and regulations, including
                    recording laws, and determining whether the Coaching
                    Services suits you in light of such laws, rules, and
                    regulations. By using the Platform, you are giving Kajabi
                    consent to store recordings for any or all coaching sessions
                    if such recordings are stored in our systems.
                  </p>
                  <p>
                    4. You may not use the Platform to collect, store, or
                    process any protected health information subject to HIPAA
                    (as defined below), any applicable health privacy
                    regulation, or any other applicable law governing health
                    information processing, use, or disclosure.
                  </p>
                  <h4>
                    ‍<em>6.7. Kajabi Access</em>
                  </h4>
                  <p>
                    <em>‍</em>Kajabi Access (“Access”) is an Add-on to your
                    regular Kajabi Subscription plan and provides Heroes with
                    additional one-on-one support. There are additional Fees to
                    utilize Access, which are billed as a Subscription, as
                    detailed in Section 2.2 above. Canceling your Access
                    Subscription will not cancel your Kajabi Account. Access
                    utilizes Third Party Services, including{" "}
                    <Link
                      onClick={toTop}
                      to="https://calendly.com/?utm_source=google&utm_medium=cpc&utm_campaign=SignUps_BrandExact_Search&utm_adgroup=calendly_exact&utm_content=calendly_exact&utm_term=calendly&utm_matchtype=e&utm_targetid=kwd-309663638777&utm_location=9031545&utm_placement=&utm_device=c&gad=1&gclid=Cj0KCQjw4s-kBhDqARIsAN-ipH2n93rqa-aca7P3Miumkj2zIqynDPDB32nIj3He1h6j52BOgCOkxPEaAi5LEALw_wcB"
                    >
                      Calendly
                    </Link>
                    ,{" "}
                    <Link onClick={toTop} to="https://www.bigmarker.com/">
                      Big Marker
                    </Link>
                    , and{" "}
                    <Link onClick={toTop} to="https://zoom.us/">
                      Zoom
                    </Link>
                    . Your use of Access is subject to such third-party service
                    providers’ terms and conditions. A breach of such
                    third-party service providers’ terms and conditions is a
                    breach of Kajabi’s Terms. Kajabi will record your Access
                    sessions for you to refer to later, and you hereby consent
                    to such recording unless you request before the start of the
                    session that the session not be recorded. <br />
                    <br />
                    We and our third-party service providers reserve the right
                    to change or discontinue the Coaching Services. We are not
                    responsible for third-party service providers’ actions or
                    links as a Third Party Service. For more information on
                    Access, please see the{" "}
                    <Link
                      onClick={toTop}
                      to="https://help.kajabi.com/hc/en-us/articles/360058128333"
                    >
                      Kajabi Access FAQs
                    </Link>
                    .
                  </p>
                  <h4>
                    ‍<em>6.8. Kajabi AI Creator Hub</em>
                  </h4>
                  <p>
                    <em>‍</em>With Kajabi’s AI Creator Hub, we give you access
                    to tools, features, or functionality that utilize models
                    trained by machine learning or artificial intelligence
                    (“Kajabi AI”). When you subscribe to, use, or access Kajabi
                    AI, you agree to be bound by the{" "}
                    <Link onClick={toTop} to="/terms">
                      Kajabi AI Terms
                    </Link>
                    . You will not be allowed to use Kajabi AI unless you agree
                    to the Kajabi AI Terms.
                  </p>
                  <h4>
                    ‍<em>6.9. Cookies, Pixes &amp; Tracking Technologies</em>
                  </h4>
                  <p>
                    <em>‍</em>Kajabi allows you to add cookies, pixels, or other
                    tracking technologies (such as Google Analytics) to your
                    Site to enable you or a third party to track user events.
                    You may engage such tracking technology from within your
                    Account. You are responsible for understanding and complying
                    with all applicable laws, rules, and regulations and
                    determining whether our Platform, including landing pages
                    and Sites, suits you in light of such laws, rules, and
                    regulations. If you choose to set cookies on your landing
                    page or Site, you represent and warrant that (1) you have
                    permission from your subscribed Contacts to track activity
                    via cookies and to transfer information related to this
                    tracking to Kajabi and (2) your landing page or Site
                    adequately discloses your tracking practices and use of
                    cookies in a privacy policy, cookie statement, or other
                    disclosure.
                  </p>
                  <p>
                    Kajabi may collect information associated with the tracking
                    technology, such as how such tracking technology is used and
                    how and what scripts are added. Kajabi may use this data to
                    improve, maintain, protect, and develop the Platform.
                  </p>
                  <h3>
                    ‍<strong>7. Your Responsibilities &amp; Our Rights</strong>
                  </h3>
                  <h4>
                    <strong>‍</strong>
                    <em>7.1. General Rules</em>&nbsp;{" "}
                  </h4>
                  <p>
                    By agreeing to these Terms, you promise to follow these
                    rules:
                  </p>
                  <p>
                    1. You will not send spam, use purchased, rented, or
                    third-party lists of email addresses, and abide by all terms
                    included in our{" "}
                    <Link onClick={toTop} to="/privacy">
                      Anti-Spam Policy
                    </Link>
                    .
                  </p>
                  <p>
                    2. You will comply with our{" "}
                    <Link onClick={toTop} to="/privacy">
                      Acceptable Use Policy
                    </Link>
                    .
                  </p>
                  <p>
                    3. You may only use our bandwidth for your Content,
                    Campaigns, and Sites. We provide image and data hosting only
                    for use with our Platform in compliance with these Terms, so
                    you may not host images or data on our servers for anything
                    else.
                  </p>
                  <p>
                    4. The Platform is not directed at children under the age of
                    18, and Kajabi does not knowingly collect personal
                    information from children. In your use of the Platform, it
                    is your sole responsibility to comply with all applicable
                    laws relating to the privacy rights of children, including
                    the Children’s Online Privacy Protection Rule (COPPA). If
                    you collect any personal information about a minor and store
                    such information within your Account, you represent and
                    warrant that you have obtained valid consent for such
                    activities according to the applicable laws of the
                    jurisdiction where the minor lives.
                  </p>
                  <p>
                    5. Neither you nor your authorized users or Customers will
                    use the Platform in any manner or for any purpose other than
                    as expressly permitted by the Terms. Neither you nor any of
                    your authorized users or Customers will, or will attempt to
                    (a) modify, distribute, alter, tamper with, repair, or
                    otherwise create derivative works of the Platform; (b)
                    reverse engineer, disassemble, or decompile the Platform or
                    apply any other process or procedure to derive the source
                    code of any software included in the Platform (except to the
                    extent applicable law doesn’t allow this restriction); (c)
                    resell or sublicense the Platform, or (d) access or use the
                    Platform (i) in a way intended to avoid incurring fees or
                    exceeding usage limits or quotas (ii) to develop data sets,
                    foundation models, or other large scale models that may
                    compete with Kajabi or the Platform, (iii) for any illegal
                    or unauthorized purpose (including as set forth in the{" "}
                    <Link onClick={toTop} to="/privacy">
                      Acceptable Use Policy
                    </Link>
                    ); (iv) to violate any laws in your jurisdiction, the laws
                    applicable to you in your Customers’ jurisdictions, or the
                    laws of the United State or California; or (vii) in a way
                    that violates these Terms or any other Kajabi Policies.
                  </p>
                  <p>
                    6. You will not misrepresent or embellish the relationship
                    between you and us (including by expressing or implying that
                    we support, sponsor, endorse, or contribute to you or your
                    business endeavors). You will not imply any relationship or
                    affiliation between us and you.
                  </p>
                  <h4>
                    ‍<em>7.2. Compliance with Laws and Regulations</em>
                  </h4>
                  <p>
                    1. You must always use the Platform in compliance with, and
                    only as permitted by, applicable laws and regulations. You
                    are responsible for determining whether the Platform is
                    suitable for your use given your obligations under any
                    federal, state, and international specific laws,
                    requirements, guidelines, and/or standards (collectively,
                    “Laws”) that apply or may potentially apply to you and your
                    Content and business, including, but not limited to Data
                    Protection Laws (as defined in the{" "}
                    <Link onClick={toTop} to="/privacy">
                      Data Processing Addendum
                    </Link>
                    ), laws regarding accessibility, taxes, and subscriptions
                    and auto-renewals, the Health Insurance Portability and
                    Accountability Act (HIPAA), anti-corruption and anti-bribery
                    laws and regulations, economic sanctions, and export control
                    laws and regulations.
                  </p>
                  <p>2. You agree, represent, and warrant to Kajabi that: </p>
                  <p>
                    (a) You will post, maintain, and abide by a publicly
                    accessible privacy notice on the digital properties from
                    which the underlying data is collected that (i) satisfies
                    the requirements of applicable Data Protection Laws; (ii)
                    describes your use of the Platform; and (iii) includes a
                    link to our Privacy Policy.
                  </p>
                  <p>
                    (b) You will get and maintain all necessary permissions and
                    valid consents required to transfer data to Kajabi lawfully
                    and to enable such data to be lawfully collected, processed,
                    and shared by Kajabi to provide the Platform or as otherwise
                    directed by you.
                  </p>
                  <p>
                    (c) You will comply with all laws and regulations applicable
                    to your Campaigns sent through the Platform, including those
                    relating to (a) acquiring consents (where required) to send
                    Campaigns lawfully; (b) the Content of Campaigns; and (c)
                    your Campaign deployment practices.
                  </p>
                  <p>
                    (d)&nbsp;You will provide all notices and obtain all
                    necessary consents required by applicable Data Protection
                    Laws to enable Kajabi to deploy cookies and similar tracking
                    technologies (like web beacons or pixels) lawfully on and
                    collect data from the devices of Contacts and Customers in
                    accordance with and as described in our{" "}
                    <Link onClick={toTop} to="/cookie">
                      Cookie Notice
                    </Link>
                    .
                  </p>
                  <p>
                    (e) To the extent Kajabi processes your Content protected by
                    Data Protection Laws as a processor on your behalf (all as
                    defined in the DPA), you and Kajabi shall be subject to and
                    comply with the Kajabi{" "}
                    <Link
                      onClick={toTop}
                      to="https://assets.website-files.com/5db0d55a083f4b7cd59d2e42/6231593210fd2d9ffe15db07_Kajabi-%20DPA%20March%202022.pdf"
                    >
                      DPA
                    </Link>
                    , which is incorporated into and forms an integral part of
                    these Terms. The DPA sets out our obligations with respect
                    to data protection and security when processing your Content
                    on your behalf in connection with the Platform. In addition,
                    if you are subject to EU Data Protection Law (as defined in
                    the DPA), you acknowledge and agree that we have your prior
                    written authorization to respond, at our discretion, to any
                    data subject access requests we receive from your Contacts
                    made under EU Data Protection Law, or, alternatively, we may
                    direct any such Contacts to you so that you can respond to
                    the request accordingly.
                  </p>
                  <p>
                    3. You may not use the Platform to collect, store, or
                    process any protected health information subject to HIPAA,
                    any applicable health privacy regulation, or any other
                    applicable law governing health information processing, use,
                    or disclosure.&nbsp;
                  </p>
                  <p>
                    4. Any information provided by Kajabi in help articles,
                    examples, webinars, or the like are provided for
                    informational purposes only and are provided without any
                    warranty, express or implied, including as to their legal
                    effect and completeness.&nbsp; Kajabi cannot provide you
                    with any legal or tax advice and encourages you to consult
                    with legal and tax counsel and other expert consultants of
                    your selection and at your own expense to ensure compliance
                    with all applicable Laws.&nbsp; You agree that you are
                    solely and exclusively responsible for your Site and mobile
                    applications and the entirety of your Content, including
                    compliance with Laws and following your published policies.
                  </p>
                  <h4>
                    ‍<em>7.3. Global Trade Compliance</em>
                  </h4>
                  <p>
                    You agree to comply with all economic sanctions and export
                    control Laws. By using the Platform, you agree not to use,
                    export, import, sell, release, or transfer the Platform, the
                    Kajabi App, or any software or technology that supports the
                    Platform or your Content violating any such Laws.
                    Specifically, and without limitation, the Platform, the
                    Kajabi App, or any software or technology that supports the
                    Platform, or your Content may not be exported, transferred,
                    or released (a) into any U.S. embargoed countries (Cuba,
                    Iran, North Korea, Syria and the Crimea, Donetsk, and
                    Luhansk regions, and Russia; or (b) to anyone included in
                    the U.S. Treasury Department’s list of Specially Designated
                    Nationals or any other applicable restricted party lists.
                  </p>
                  <p>
                    In addition, you certify that neither you nor any
                    principals, officers, directors, or any person or entity
                    (including any beneficiaries, owners, affiliated and/or
                    associated parties) you know to be involved with the use of
                    the Platform are not: (a) on any sanctions lists in the
                    countries where the Platform is available; (b) doing
                    business in any of the U.S. embargoed countries; and (c) a
                    military end user as defined in 15 C.F.R § 744.
                  </p>
                  <p>
                    You will ensure that: (a) your end users do not use the
                    Platform, your Site, or your Content in violation of any
                    export restriction or embargo by the United States; and (b)
                    you do not provide access to the Platform, your Site, or
                    your Content to persons or entities on any of the above
                    lists.
                  </p>
                  <h4>
                    ‍<em>7.4. PCI Compliance</em>‍
                  </h4>
                  <p>
                    1. If you use the Platform to accept payment card
                    transactions, you agree that at all times, you shall be
                    compliant with applicable Payment Card Industry Data
                    Security Standards (“PCI-DSS”) and, as applicable, the
                    Payment Application Data Security Standards (“PA-DSS”). You
                    agree to promptly provide Kajabi with documentation
                    evidencing your compliance with PCI-DSS and/or PA-DSS upon
                    request. You also agree that you will use only PCI-DSS and
                    PA-DSS compliant service providers to store or transmit Card
                    information, including a cardholder’s account number,
                    expiration date, and CVV2. You must not store CVV2 data at
                    any time. Information on PCI-DSS can be found on the PCI
                    Council’s website. It is your responsibility to comply with
                    these standards.
                  </p>
                  <p>
                    2.<em> </em>Kajabi does not have access to, collect,
                    transmit, store, or process any Cardholder Data on your
                    behalf.&nbsp; We have partnered with third-party payment
                    providers regarding all Customer payments so that our Heroes
                    may achieve their compliance obligations with PCI DSS. It is
                    your sole responsibility as the owner of your Site to
                    activate and maintain these accounts. If you do not wish to
                    keep either of the payment accounts active, it is your
                    responsibility to deactivate them. “Cardholder Data” is
                    defined as a cardholder’s primary account number (PAN), and
                    where a full unmasked card number is present, any of the
                    following elements: cardholder name, expiration date, and/or
                    service code. YOU ACKNOWLEDGE AND AGREE THAT YOU ARE
                    PROHIBITED FROM COLLECTING OR ENTERING CARDHOLDER DATA INTO
                    ANY FORM OR DATA ENTRY FIELDS IN THE PLATFORM, EXCEPT INTO
                    THOSE FIELDS INTENDED SOLELY FOR THAT PURPOSE (i.e., where
                    Kajabi explicitly enables such data to be entered into such
                    fields). Appropriate fields are marked with labels such as
                    ‘Card number’ or by having a credit card icon precede them.
                    Similarly, excluding payment forms, you must never collect
                    or enter any “Sensitive Authentication Data”, as defined by
                    the PCI Standards (including CVC or CVV2) into any fields in
                    the Platform. You assume all responsibility for any
                    Cardholder Data entered into the Platform in violation of
                    these Terms.
                  </p>
                  <p>
                    3. Kajabi will make reasonable efforts to provide the
                    Platform in a manner consistent with PCI-DSS requirements
                    that apply to Kajabi.&nbsp;
                  </p>
                  <h4>
                    ‍<em>7.5. Public Forums</em>{" "}
                  </h4>
                  <p>
                    Kajabi’s Facebook Community and other features include
                    public forums, including, without limitation, discussion
                    forums, message boards, blogs, chat rooms, or instant
                    messaging features. You acknowledge these public forums are
                    for public and not private communications. You further
                    acknowledge that any Content you upload, submit, post,
                    transmit, communicate, share, or exchange by means of any
                    public forum may be viewed on the Internet by the general
                    public, and therefore, you have no expectation of privacy
                    concerning any such submission or posting. Further, you
                    grant to Kajabi a non-exclusive, transferable,
                    sub-licensable, royalty-free, worldwide right and license to
                    use any such Content you post on a public forum to promote,
                    advertise, and market Kajabi and the Platform in any medium
                    or format whatsoever now existing or hereafter created,
                    without consent from or compensation or credit to you. You
                    are, and shall remain, solely responsible for the Content
                    you upload, submit, post, transmit, communicate, share, or
                    exchange by means of any public forum and for the
                    consequences of submitting or posting the same. You
                    acknowledge and agree that Kajabi is not responsible for any
                    content posted on public forums by any third parties,
                    including without limitation any content that you may find
                    illegal, infringing, false, defamatory, harassing,
                    threatening, bigoted, hateful, violent, vulgar, obscene,
                    pornographic, negative, offensive, or harmful.
                  </p>
                  <h4>
                    ‍<em>7.6. Mobile Networks; Texting&nbsp; </em>
                  </h4>
                  <p>
                    <em>‍</em>When you access the Platform through a mobile
                    network, such as one of our mobile applications, or sign up
                    for our text message programs, your network or roaming
                    provider’s messaging, data, and other rates and fees may
                    apply. Not all Kajabi products or services may work with
                    your network provider or device.
                  </p>
                  <p>
                    When you sign up to receive text messages from us, you
                    acknowledge and agree you will receive such text messages
                    using an autodialer to the number you provide and that your
                    consent for us to send you text messages is not a condition
                    of purchasing the Platform or any other product or service.
                    Not all carriers may be included within our text messaging
                    programs. You can opt out of receiving text messages from us
                    at any time. Please see any additional instructions provided
                    to you at the time you sign up.
                  </p>
                  <h4>
                    ‍
                    <em>
                      7.7. Right to Review Your Content, Campaigns &amp;
                      Sites&nbsp;{" "}
                    </em>
                  </h4>
                  <p>
                    <em>‍</em>We may view, copy, and internally distribute
                    Content from your Campaigns, your Sites, your Third Party
                    Services, and your Account to create algorithms and programs
                    (“Tools”) that help us spot problem accounts and improve the
                    Platform, among other uses permitted by these Terms or our
                    Privacy Policy. For instance, we use these Tools to find
                    Heroes who violate these Terms or applicable Laws and to
                    study data internally to make the Platform smarter and
                    create better experiences for Heroes and their Customers. We
                    may aggregate and anonymize data, including data from the
                    Content of your Campaigns, Sites, and Third Party Services,
                    to create statistical information. We own all such
                    aggregated and anonymized data and may use it, including,
                    but not limited to, operating, analyzing, improving, or
                    marketing the Platform and other products and services and
                    sharing such data externally for research, marketing, or
                    other lawful purposes.
                  </p>
                  <h3>
                    ‍
                    <strong>
                      8. Suspension &amp; Termination of Your Account
                    </strong>
                    ‍
                  </h3>
                  <h4>
                    ‍<em>8.1. Term</em>
                  </h4>
                  <p>
                    <em>‍</em>The term of these Terms will commence on the date
                    you complete your Account Registration and continue until
                    terminated by us or by you as provided below.
                  </p>
                  <h4>
                    ‍<em>8.2. By You</em>&nbsp;{" "}
                  </h4>
                  <p>
                    You can terminate your Subscription at any time through your
                    Account management page or by contacting us at{" "}
                    <Link onClick={toTop} to="mailto:service@philippa.store">
                    service@philippa.store
                    </Link>
                    , in which case your Subscription will continue until the
                    end of that billing cycle before terminating. Terminations
                    are confirmed immediately, and you will only be charged
                    again for that Subscription if you purchase a new one.
                  </p>
                  <h4>
                    ‍<em>8.3. By Kajabi</em>&nbsp;
                  </h4>
                  <p>
                    Without limiting any other remedies available, we may
                    suspend or terminate your Account for any reason, without
                    notice, and at any time (unless required by Law), including
                    if we suspect that you have engaged in fraudulent activity
                    in connection with the use of the Platform or otherwise
                    violated these Terms. We may also terminate your Account if
                    your Account has been inactive for 12 months. Termination of
                    your Account and these Terms will be without prejudice to
                    any rights or obligations that arose before the termination
                    date.
                  </p>
                  <p>
                    ‍<em>8.4. Effects of Termination</em>{" "}
                  </p>
                  <p>
                    Upon termination of your Account by either party for any
                    reason:
                  </p>
                  <p>
                    1. We will cease providing you access to the Platform and
                    your Account.
                  </p>
                  <p>
                    2. Unless otherwise provided in these Terms, you will not be
                    entitled to any refunds of any Fees, pro rata or otherwise.
                  </p>
                  <p>
                    3. Any outstanding balance owed to Kajabi for your use of
                    the Platform through the effective date of the termination
                    will immediately become due and payable in full.
                  </p>
                  <p>
                    4. Your Site will be disabled, and your Customers will no
                    longer have access to your Site or your Content.
                  </p>
                  <p>
                    5. Once your Account is terminated, you acknowledge and
                    agree that we may permanently delete your Account and all
                    data associated with it, including your Contacts, your
                    Content, and your Campaigns.
                  </p>
                  <h3>
                    ‍<strong>9. Changes and Updates</strong>‍
                  </h3>
                  <h4>
                    ‍<em>9.1. Changes to Terms</em>{" "}
                  </h4>
                  <p>
                    You acknowledge and agree that Kajabi may change these Terms
                    at any time for various reasons, such as to reflect changes
                    in applicable Law or updates to Platform, and to account for
                    new features or functionality. The most current version will
                    always be posted on our website in our{" "}
                    <Link onClick={toTop} to="/privacy">
                      Legal and Policy
                    </Link>{" "}
                    center. If an amendment is material, as determined in
                    Kajabi’s sole discretion, we may notify you by email to your
                    Primary Email Address and/or posting it to the Kajabi Site
                    or upon your login to your Account. However, we may make
                    changes that materially adversely affect your use of the
                    Platform or your rights under these Terms at any time and
                    with immediate effect (i) for legal, regulatory, fraud, and
                    abuse prevention, or security reasons; or (ii) to restrict
                    products or activities that we deem unsafe, inappropriate,
                    or offensive. Unless we indicate otherwise in our notice (if
                    applicable), any changes to the Terms will be effective
                    immediately upon posting such updated terms at this
                    location. Your continued access to or use of the Platform
                    after we provide such notice, if applicable, or after we
                    post such updated terms, constitutes your acceptance of the
                    changes and consent to be bound by the Terms as amended. If
                    you do not agree to the amended Terms, you must stop
                    accessing and using the Platform.
                  </p>
                  <h4>
                    ‍<em>9.2. Changes to Fees</em>&nbsp;
                  </h4>
                  <p>
                    We may change the Fees for the Platform or any Additional
                    Services from time to time. We will provide you with at
                    least 30 days advance notice before any changes in Fees by
                    sending an email to your Primary Email Address and/or
                    posting it to the Kajabi Site or upon your login to your
                    Account.
                  </p>
                  <h4>
                    ‍<em>9.3.Changes to the Platform and Services</em>
                  </h4>
                  <p>
                    <em>‍</em>We are continually changing and improving our
                    Platform. Kajabi may add, alter, or remove functionality
                    from the Platform at any time without prior notice. Kajabi
                    may also limit, suspend, or discontinue a specific
                    functionality or feature provided to you at our discretion
                    and will give you reasonable advance notice to provide you
                    with an opportunity to obtain a copy of your Content if
                    necessary.&nbsp;
                  </p>
                  <h4>
                    ‍<em>9.4. Downgrades</em>
                  </h4>
                  <p>
                    <em>‍</em>Downgrading your Account plan may cause the loss
                    of Content, features, functionality, or capacity of your
                    Account.
                  </p>
                  <h3>
                    ‍
                    <strong>
                      10. Disclaimers &amp; Limitations of Liability
                    </strong>
                    ‍
                  </h3>
                  <h4>
                    ‍<em>10.1. No Warranties</em>&nbsp;{" "}
                  </h4>
                  <p>
                    While Kajabi strives to provide you with a great experience
                    when using the Platform (and we love to please our Heroes),
                    there are certain things we do not promise about our
                    Platform. We keep our Platform up, but it may be unavailable
                    from time to time for various reasons. EXCEPT
                    THOSE&nbsp;WARRANTIES EXPRESSLY PROVIDED IN THESE TERMS AND
                    TO THE MAXIMUM&nbsp;EXTENT PERMITTED BY APPLICABLE LAW, THE
                    PLATFORM AND ANY INFORMATION, GUIDANCE, OR RECOMMENDATIONS
                    THEREIN ARE PROVIDED “<strong>AS IS</strong>” AND KAJABI
                    DOES NOT MAKE WARRANTIES OF ANY KIND, EXPRESS, IMPLIED, OR
                    STATUTORY. THIS INCLUDES, WITHOUT LIMITATION, THOSE OF
                    MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, DATA
                    LOSS, AND NON-INFRINGEMENT OR ANY REPRESENTATIONS REGARDING
                    THE AVAILABILITY, RELIABILITY, OR ACCURACY OF THE PLATFORM.
                    KAJABI&nbsp;DOES&nbsp;NOT&nbsp;WARRANT&nbsp;THAT&nbsp;THE&nbsp;PLATFORM&nbsp;WILL&nbsp;BE&nbsp;UNINTERRUPTED&nbsp;OR
                    ERROR-FREE; NOR DOES IT MAKE ANY WARRANTY AS TO THE RESULTS
                    OR PROFITS THAT MAY BE OBTAINED THROUGH THE PLATFORM.
                  </p>
                  <h4>
                    ‍<em>10.2. Exclusion of Certain Liability</em>
                  </h4>
                  <p>
                    TO THE EXTENT PERMITTED BY APPLICABLE LAW, YOU ACKNOWLEDGE
                    AND AGREE THAT (A) YOU ASSUME FULL LIABILITY FOR ANY LOSSES
                    THAT RESULTS FROM YOUR USE OF THE PLATFORM; AND (B) KAJABI
                    AND ITS TEAM WILL NOT BE LIABLE FOR ANY INDIRECT,
                    CONSEQUENTIAL, SPECIAL, INCIDENTAL, PUNITIVE, OR EXEMPLARY
                    DAMAGES WHATSOEVER, INCLUDING LOSS OF USE, DATA, BUSINESS,
                    REVENUES, OR PROFITS (IN EACH CASE WHETHER DIRECT OR
                    INDIRECT), ARISING OUT OF OR IN CONNECTION WITH THE PLATFORM
                    AND THESE TERMS, AND WHETHER BASED ON CONTRACT, TORT, STRICT
                    LIABILITY, OR ANY OTHER LEGAL THEORY, EVEN IF KAJABI HAS
                    BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES AND EVEN IF
                    A REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
                  </p>
                  <p>
                    FOR THE AVOIDANCE OF DOUBT, IN NO INSTANCE WILL WE OR OUR
                    TEAM BE LIABLE FOR ANY LOSSES OR DAMAGES YOU SUFFER IF YOU
                    USE THE PLATFORM IN VIOLATION OF THESE TERMS, REGARDLESS OF
                    WHETHER WE TERMINATE OR SUSPEND YOUR ACCOUNT DUE TO SUCH
                    VIOLATION.
                  </p>
                  <h4>
                    ‍<em>10.3. Limitation of Liability</em>
                  </h4>
                  <p>
                    TO THE EXTENT PERMITTED BY APPLICABLE LAW, IN ANY CALENDAR
                    MONTH, THE AGGREGATE TOTAL LIABILITY OF EACH OF KAJABI AND
                    ITS TEAM ARISING OUT OF OR IN CONNECTION WITH THE PLATFORM
                    AND THESE TERMS WILL NOT EXCEED THE
                    GREATER&nbsp;OF&nbsp;EITHER&nbsp;(1)&nbsp;THE&nbsp;AMOUNT
                    YOU PAID TO KAJABI FOR YOUR USE OF THE PLATFORM THE
                    PRECEDING MONTH,
                    OR&nbsp;(2)&nbsp;TWO&nbsp;HUNDRED&nbsp;($200)&nbsp;U.S.
                    DOLLARS.
                  </p>
                  <h4>
                    ‍<em>10.4. Consumers</em>&nbsp;{" "}
                  </h4>
                  <p>
                    We acknowledge that the laws of certain jurisdictions
                    provide legal rights to consumers that may not be overridden
                    by contract or waived by those consumers. If you are such a
                    consumer, nothing in these Terms limits any of those
                    consumer rights.
                  </p>
                  <h3>
                    ‍<strong>11. Indemnification</strong>‍
                  </h3>
                  <h4>
                    ‍<em>11.1. General.</em>&nbsp;{" "}
                  </h4>
                  <p>
                    You agree that you are solely and exclusively responsible
                    for your Site, mobile applications, and Content. To the
                    fullest extent permitted by law, you will indemnify and hold
                    harmless Kajabi and its Team from all liabilities, damages,
                    and costs (including settlement costs and attorneys’ fees
                    and expenses) arising out of a third-party claim regarding
                    or in connection with (a) your, your Customers, or your
                    Contacts use of the Platform, including but not limited to
                    your Content, Sites, or Campaigns; (b) your breach of these
                    Terms or any other policies incorporated herein; (c)
                    violation of applicable Law by you, your Customers, your
                    Contacts, or your Content, Sites, mobile applications, or
                    business; (d) any misrepresentations by you; (e) a dispute
                    between you and your Customers, Contacts, or other users,
                    including but not limited to refunds, fraudulent
                    transactions, alleged or actual violation of Laws, or your
                    breach of these Terms; or (f) a third party claim alleging
                    that any of your Content infringes or misappropriates that
                    third party’s intellectual property rights.
                  </p>
                  <h4>
                    ‍<em>11.2. Process</em>&nbsp;{" "}
                  </h4>
                  <p>
                    We will (a) endeavor to give you prompt written notice of
                    the claim; (b) permit you to control the defense and
                    settlement of the claim; and (c) reasonably cooperate with
                    you in the defense and settlement of the claim. You may not
                    agree to any settlement of any claim that requires our
                    commitment without our written consent.
                  </p>
                  <h3>
                    ‍
                    <strong>
                      12.&nbsp; Resolving Disputes; Arbitration Agreement;
                      Governing Law
                    </strong>
                  </h3>
                  <h4>
                    <strong>‍</strong>
                    <em>12.1. Resolving Disputes</em>&nbsp;{" "}
                  </h4>
                  <p>
                    If you have a concern, we want the opportunity to address it
                    without needing a formal legal case. Before filing a claim
                    against Kajabi, you agree to try to resolve the dispute
                    informally by contacting{" "}
                    <Link onClick={toTop} to="http://philippa.store">
                    service@philippa.store
                    </Link>
                    . We’ll try to resolve the dispute informally by contacting
                    you via email. If a dispute is not resolved within thirty
                    (30) days of the date we receive your initial email, you or
                    Kajabi may bring a formal proceeding.
                  </p>
                  <h4>
                    ‍<em>12.2.&nbsp;Judicial Forum for Disputes</em>
                  </h4>
                  <p>
                    You and Kajabi agree that any judicial proceeding to resolve
                    claims relating to these Terms or the Platform will be
                    brought in the federal or state courts of Orange County,
                    California, subject to the mandatory arbitration provisions
                    below. Both you and Kajabi consent to venue and personal
                    jurisdiction in such courts. If you reside in a country (for
                    example, a member state of the European Union) with laws
                    that give consumers the right to bring disputes in their
                    local courts, this paragraph doesn’t affect those
                    requirements.
                  </p>
                  <h4>
                    ‍
                    <strong>
                      <em>12.3.&nbsp;Mandatory Arbitration Provisions</em>
                    </strong>
                  </h4>
                  <p>
                    <strong>
                      <em>‍</em>IF YOU’RE A U.S. RESIDENT, YOU ALSO AGREE TO THE
                      FOLLOWING MANDATORY ARBITRATION PROVISIONS:
                    </strong>
                  </p>
                  <p>
                    <strong>‍</strong>
                    <em>We Both Agree to Arbitrate</em>.&nbsp; You and Kajabi
                    agree to resolve any claims relating to these Terms or the
                    Platform through final and binding arbitration by a single
                    arbitrator, except as set forth under Exceptions to
                    Agreement to Arbitrate below. This includes disputes arising
                    from or relating to the interpretation or application of
                    this “Mandatory Arbitration Provisions” section, including
                    its enforceability, revocability, or validity.
                  </p>
                  <p>
                    ‍<em>Opt-out of Agreement to Arbitrate</em>.&nbsp; You may
                    decline these arbitration terms with respect to these Terms
                    within thirty (30) days of first registering your Account by
                    contacting us at{" "}
                    <Link onClick={toTop} to="mailto:service@philippa.store">
                    service@philippa.store
                    </Link>
                    .
                  </p>
                  <p>
                    ‍<em>Arbitration Procedures</em>.&nbsp; The American
                    Arbitration Association (AAA) will administer the
                    arbitration under its Commercial Arbitration Rules and the
                    Supplementary Procedures for Consumer Related Disputes. The
                    arbitration will be held in the United States county where
                    you live or work, Orange County, CA, or any other location
                    we agree to. The AAA rules will govern payment of all
                    arbitration fees. The AAA Rules and Forms are available
                    online at{" "}
                    <Link onClick={toTop} to="http://www.adr.org">
                      www.adr.org
                    </Link>
                    , or by calling the AAA at 1-800-778-7879.
                  </p>
                  <p>
                    ‍<em>Exceptions to Agreement to Arbitrate</em>.&nbsp; Either
                    you or Kajabi may assert claims, if they qualify, in small
                    claims court in Orange County, CA, or any United States
                    county where you live or work. Either party may bring a
                    lawsuit solely for injunctive relief to stop unauthorized
                    use or abuse of the Platform or intellectual property
                    infringement (for example, trademark, trade secret,
                    copyright, or patent rights) without first engaging in
                    arbitration or the informal dispute-resolution process
                    described above. If the agreement to arbitrate is found not
                    to apply to you or your claim, you agree to the exclusive
                    jurisdiction of the state and federal courts in Orange
                    County, California to resolve your claim.
                  </p>
                  <p>
                    ‍<em>NO CLASS ACTIONS</em>.&nbsp; You may only resolve
                    disputes with us individually. You may not bring a claim as
                    a plaintiff or a class member in a class, consolidated, or
                    representative action. Class arbitrations, class actions,
                    private attorney general actions, and consolidation with
                    other arbitrations aren’t allowed. If this specific
                    paragraph is held unenforceable, then this “Mandatory
                    Arbitration Provisions” section will be deemed void.
                  </p>
                  <h4>
                    ‍<em>12.4. Governing Law</em>
                  </h4>
                  <p>
                    <em>‍</em>These Terms will be governed by California law
                    except for its conflicts of laws principles. However, some
                    countries (including those in the European Union) have laws
                    that require agreements to be governed by the local laws of
                    the consumer's country. This paragraph doesn’t override
                    those laws.
                  </p>
                  <h3>
                    ‍<strong>13. Other Terms</strong>‍
                  </h3>
                  <h4>
                    ‍<em>13.1. &nbsp; Assignment</em>.&nbsp;{" "}
                  </h4>
                  <p>
                    These Terms, and any rights and licenses granted hereunder,
                    may not be transferred or assigned by you without our prior
                    written consent, but may be assigned by us without consent
                    or other restriction.
                  </p>
                  <h4>
                    ‍<em>13.2. Entire Agreemen</em>t
                  </h4>
                  <p>
                    These Terms, and any other policy located in our{" "}
                    <Link onClick={toTop} to="/privacy">
                      Legal and Policy Center
                    </Link>
                    , constitute the entire agreement between you and Kajabi,
                    and they supersede any other prior or contemporaneous
                    agreements, terms and conditions, written or oral concerning
                    its subject matter.
                  </p>
                  <h4>
                    ‍<em>13.3. Independent Contractors</em>
                  </h4>
                  <p>
                    The relationship between you and Kajabi is that of
                    independent contractors and not legal partners, employees,
                    or agents of each other.
                  </p>
                  <h4>
                    ‍<em>13.4. Interpretation</em>&nbsp;{" "}
                  </h4>
                  <p>
                    The use of the terms “includes”, “including”, “such as”, and
                    similar terms, will be deemed not to limit what else might
                    be included.
                  </p>
                  <h4>
                    ‍<em>13.5. No Waiver</em>‍
                  </h4>
                  <p>
                    A party’s failure or delay to enforce a provision under
                    these Terms is not a waiver of its right to do so later.
                  </p>
                  <h4>
                    ‍<em>13.6. Severability</em>&nbsp;{" "}
                  </h4>
                  <p>
                    If any provision of these Terms is determined to be
                    unenforceable by a court of competent jurisdiction, that
                    provision will be severed, and the remainder of the terms
                    will remain in full effect.
                  </p>
                  <h4>
                    ‍<em>13.7. Third-Party Beneficiaries</em>&nbsp;{" "}
                  </h4>
                  <p>
                    Except as otherwise provided herein, there are no
                    third-party beneficiaries to these Terms.
                  </p>
                  <h4>
                    ‍<em>13.8. Survival</em>&nbsp;{" "}
                  </h4>
                  <p>
                    On termination, all related rights and obligations under
                    these Terms immediately terminate, except that (a) you will
                    remain responsible for performing all of your obligations in
                    connection with transactions entered into before termination
                    and for any liabilities that accrued before or as a result
                    of the termination; and (b) Section 2 (Payment of Fees),
                    Sections 3.2, 3.3, and 3.6 (Your Content), Section 4
                    (Privacy, Security, and Confidentiality), Section 5
                    (Kajabi’s Intellectual Property), Section 8 (Suspension and
                    Termination of Your Account), Section 10 (Disclaimers and
                    Limitations of Liability), Section 11 (Indemnification),
                    Section 12 (Resolving Disputes; Arbitration Agreement;
                    Governing Law), and Section 13 (Other Terms) and any other
                    provision that by its nature, should survive termination or
                    expiration of these Terms, will survive the expiration or
                    termination of these Terms.
                  </p>
                  <h4>
                    ‍<em>13.9. Language</em>{" "}
                  </h4>
                  <p>
                    These Terms are prepared and written in English. To the
                    extent that any translated version conflicts with the
                    English version, the English version controls, except where
                    prohibited by applicable law.
                  </p>
                  <h4>
                    ‍<em>13.10. California Residents</em>‍
                  </h4>
                  <p>
                    If you are a California resident, per Cal. Civ. Code §
                    1789.3, you may report complaints to the Complaint
                    Assistance Unit of the Division of Consumer Services of the
                    California Department of Consumer Affairs by contacting them
                    in writing at 1625 North Market Blvd., Suite N 112
                    Sacramento, CA 95834, or by telephone at (800) 952-5210.
                  </p>
                  <h4>
                    ‍<em>13.11. How to Contact Us</em>&nbsp;{" "}
                  </h4>
                  <p>
                    You may contact us regarding the Platform or these Terms at
                    880 Newport Center Dr., Suite 100, Newport Beach, CA 92660,
                    or by email at{" "}
                    <Link onClick={toTop} to="mailto:service@philippa.store">
                    service@philippa.store
                    </Link>
                    .
                  </p>
                </div>
              </div>
            </div>
          </section>
        </main>

        <Footer />
      </div>
    </body>
  );
}

export default Terms;
