import React from "react";
import { Link } from "react-router-dom"; 
import Footer from "./Footer";
import Header from "./Header";

function Privacy() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <body>
      <div className="page-wrapper">
        <Header />
        <main className="main-wrapper">
          <section className="section_header">
            <div className="padding-global padding-section-medium">
              <div className="container-large">
                <div className="breadcrumb_wrapper">
                  <Link
                    onClick={toTop}
                    to="/"
                    className="breadcrumb_link text-style-muted"
                  >
                    Policies
                  </Link>
                  <div className="text-style-muted">→</div>
                  <Link
                    onClick={toTop}
                    to="/privacy"
                    aria-current="page"
                    className="breadcrumb_link w--current"
                  >
                    Policies
                  </Link>
                </div>
                <div className="padding-bottom padding-large" />
                <div className="max-width-large">
                  <h1 className="heading-style-h3-feature">Privacy Notice</h1>
                  <div className="padding-bottom padding-small" />
                  <div className="w-embed">
                    <div>Last Edit: May 13, 2024</div>
                  </div>
                </div>
                <div className="padding-bottom padding-large" />
                <div
                  fs-richtext-element="rich-text"
                  className="text-rich-text w-richtext"
                >
                  <h3>
                    <strong>1. Introduction</strong>
                  </h3>
                  <p>
                    Kajabi, LLC, Kajabi Financial, LLC, and our affiliated
                    entities (collectively the "Kajabi," "we," "our," "us")
                    knows that you care about how your personal information is
                    used and shared. We take your privacy seriously and want to
                    outline the measures we employ in safeguarding your personal
                    information. All capitalized terms not defined herein shall
                    have the meaning attributed to them in Kajabi’s Platform
                    Terms of Services (“Platform Terms”).
                  </p>
                  <p>
                    This Privacy Notice covers Kajabi's treatment of information
                    collected when you access the Platform.&nbsp;
                  </p>
                  <p>
                    <strong>
                      We recommend that you read this Privacy Notice carefully
                      as it provides important information about your personal
                      information and your rights under the law.
                    </strong>
                  </p>
                  <h3>
                    <strong>2. Who This Privacy Notice Applies to</strong>
                  </h3>
                  <p>This Privacy Notice applies to information we collect:</p>
                  <ul>
                    <li>on the Platform;&nbsp;</li>
                    <li>
                      at one of our on-site locations, events, or conferences;
                      or
                    </li>
                    <li>
                      in email, text or other electronic messages between you
                      and the Platform.
                    </li>
                  </ul>
                  <p>
                    It does not apply to information collected by any third
                    party, including through any application or content that may
                    link to or be accessible from or on the Platform.
                    Furthermore, it does not apply to Customers. If you are a
                    Customer of a Hero, Kajabi only acts as a service provider
                    or processor of your data. If you have any questions or
                    would like to exercise your data rights in your applicable
                    jurisdiction, please contact the Hero directly. Finally,
                    this does not apply to job applicants, candidates, or
                    workforce members of Kajabi. If you are an applicant,
                    candidate, or workforce member of Kajabi, please visit our{" "}
                    <Link onClick={toTop} to="/privacy">
                      Candidate Privacy Notice
                    </Link>{" "}
                    or contact HR for a copy of the Employee Privacy Notice.
                  </p>
                  <p>
                    Please read this Privacy Notice, along with our{" "}
                    <Link onClick={toTop} to="/cookie">
                      Cookie Notice
                    </Link>
                    , carefully to understand our policies and practices
                    regarding your information and how we will treat it.
                    &nbsp;If you do not agree with our policies and practices,
                    you must stop using the Platform. &nbsp;By accessing or
                    using the Platform or using any of our services, you
                    acknowledge that you have read and accept the practices and
                    policies outlined in this Privacy Notice, our Platform
                    Terms, and any other Kajabi policies or agreements in place
                    at that time. Your visit and any dispute over privacy is
                    subject to this policy and our Platform Terms, including
                    limitations on damages and binding arbitration. We reserve
                    the right to make changes to this Privacy Notice from time
                    to time. &nbsp;Your continued use of this Platform after we
                    make changes is deemed to be acceptance of those changes, so
                    please check periodically for updates. This Privacy Notice
                    will be updated at minimum, annually, or as may be required
                    to conform to applicable laws.
                  </p>
                  <p>
                    <strong>IF YOU ARE CALIFORNIA RESIDENT</strong>: If you are
                    a resident of California, this entire Privacy Notice applies
                    to you. However, please see Additional Information for
                    California Consumers below, which will inform you in detail
                    about our information collection practices and your specific
                    rights.
                  </p>
                  <p>
                    <strong>IF YOU ARE NEVADA RESIDENT</strong>: If you are a
                    resident of Nevada, you have the right to opt-out of the
                    sale of certain personal information to third parties who
                    intend to license or sell that personal information. Please
                    note that we do not currently sell your personal information
                    as sales are defined in Nevada Revised Statutes Chapter
                    603A.
                  </p>
                  <p>
                    <strong>IF YOU ARE EEA/UK RESIDENT:</strong> If you are a
                    resident of the EEA or the UK, this entire Privacy Notice
                    applies to you. However, please see Additional Information
                    for European Consumers below, which will inform you in
                    detail about our information collection practices and your
                    specific rights.
                  </p>
                  <h3>
                    <strong>3. Users Must Be At Least 18</strong>
                  </h3>
                  <p>
                    <em>
                      Users of our Platform must be at least 18 years old; any
                      user under 18 is not authorized. &nbsp;Our Platform is not
                      targeted to children under age 18. Additionally, we do not
                      knowingly collect any personal information from children
                      under 18 years of age.
                    </em>
                  </p>
                  <p>
                    {" "}
                    <em>
                      If you have reason to believe that a child under the age
                      of 18 has provided personal information to Kajabi through
                      this Platform, please contact us, and we will endeavor to
                      delete that information from our databases.{" "}
                    </em>
                    <strong>‍</strong>
                  </p>
                  <h3>
                    <strong>
                      4. Information We Collect and How We Collect It
                    </strong>
                  </h3>
                  <p>
                    We collect several types of information from and about users
                    of the Platform or services, including information:
                  </p>
                  <ul>
                    <li>
                      by which you may be personally identified, such as name,
                      postal address, e-mail address, phone number, or any other
                      identifier by which you may be contacted online or offline
                      ("personal information");
                    </li>
                    <li>you may submit to us, including any Content;</li>
                    <li>about your preferences and demographics;</li>
                    <li>geolocation, including precise and regional;</li>
                    <li>about you individually but does not identify you;</li>
                    <li>
                      about your internet connection, the equipment you use to
                      access our Platform, and Platform usage details;
                    </li>
                    <li>account information and login credentials;</li>
                    <li>
                      chat modules, messaging and email data, including related
                      metrics;
                    </li>
                    <li>occupation history;</li>
                    <li>financial and economic information;</li>
                    <li>survey and general research data;&nbsp;</li>
                    <li>contests, sweepstakes and prize drawings data;</li>
                  </ul>
                  <p>We collect this information:</p>
                  <ul>
                    <li>
                      directly from you when you provide it to us, such as when
                      you create and account, or contact us with inquiries; and
                    </li>
                    <li>
                      automatically as you use the Platform. &nbsp;Information
                      collected automatically may include usage details, and
                      information collected through cookies.
                    </li>
                  </ul>
                  <p>
                    <strong>
                      By voluntarily providing us with any such information, you
                      are consenting to our use of it in accordance with this
                      Privacy Notice.
                    </strong>{" "}
                    &nbsp;You can choose not to provide us with certain
                    information, but then you may not be able to take advantage
                    of many features or opportunities.
                  </p>
                  <p>
                    <em>Automatically Collected Information</em>
                  </p>
                  <p>
                    Upon engaging the Platform or services, the Kajabi
                    automatically receives and records information on our server
                    logs from your browser, including but not limited to your IP
                    address, the type of browser used, identity and type of
                    device used, cookie information, the page you requested, and
                    duration of activity on our Platform. Kajabi may store such
                    information itself or such information may be included in
                    databases owned and maintained by Kajabi affiliates within
                    our corporate family, agents or service providers. This
                    information may be used to customize the content you see on
                    our Platform or to communicate with you about opportunities.
                    This information provides us with the type of user who is
                    accessing our Platform and certain browsing activities of
                    that user. We may make extensive use of this data at an
                    aggregated level in order to understand, among other things,
                    how our Platform is being used. Kajabi may share this
                    aggregate data with its affiliates within our corporate
                    family, agents and business partners. &nbsp;Kajabi may also
                    disclose aggregated user statistics in order to describe our
                    services to current and prospective business partners, and
                    to other third parties for other lawful purposes.
                    &nbsp;Please note that we have the ability to retain any
                    data that you provide or that we collect on our Platform,
                    even if you have not created an account, logged in or if you
                    do not use our Platform. For example, such contact details
                    and data may be used to contact you to share opportunities
                    or for marketing purposes.
                  </p>
                  <p>
                    <em>Cookies</em>
                  </p>
                  <p>
                    We use "cookies" and other tracking technologies to improve
                    your experience and to save you time and when visiting the
                    Platform. Cookies are little tags that are placed onto your
                    computer. We assign a cookie to your computer when you first
                    visit us in order to enable us to recognize you each time
                    you return. Cookies allows us to customize the Platform to
                    your individual preferences in order to create a more
                    personalized experience. On most web browsers, you will find
                    a “help” section on the toolbar. Please refer to this
                    section for information on how to receive notification when
                    you are receiving a new cookie and how to turn cookies off.
                    We recommend that you leave cookies turned on because they
                    allow you to take advantage of some of the Platform’s
                    features. For more information on our use of cookies and the
                    data they collect, see our{" "}
                    <Link onClick={toTop} to="/cookie">
                      Cookie Notice
                    </Link>
                    .
                  </p>
                  <p>
                    <em>Do Not Track</em>
                  </p>
                  <p>
                    We may track users’ use of the Platform, but because there
                    is no common understanding of what a “Do Not Track” signal
                    is supposed to mean, we will not respond to browser Do Not
                    Track (DNT) signals. For more information on DNT settings
                    generally, please visit{" "}
                    <Link onClick={toTop} to="https://allaboutdnt.com">
                      https://allaboutdnt.com
                    </Link>
                    .
                  </p>
                  <p>
                    <em>Google API</em>
                  </p>
                  <p>
                    Kajabi uses Google’s Application Programming Interface (API)
                    Services to enable the use of certain Platform features
                    (i.e. use of your Gmail account to calendar events from the
                    Platform’s Coaching feature). Kajabi’s use of information
                    received from Google APIs will adhere to the{" "}
                    <Link
                      onClick={toTop}
                      to="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
                    >
                      Google API Services User Data Policy
                    </Link>
                    , including the{" "}
                    <Link
                      onClick={toTop}
                      to="https://support.google.com/cloud/answer/9110914#explain-types"
                    >
                      Limited Use requirements
                    </Link>
                    . The information collected and used with these API services
                    includes personal data such as email addresses, calendar
                    information, and first and last names. Your Google
                    information is used by our system developers to provide or
                    improve user-facing features that are prominent to your user
                    experience. For purpose of clarity, Kajabi does not use the
                    information received through Google API&nbsp;Services for
                    marketing purposes and does not share the same to third
                    parties, except with your consent.
                  </p>
                  <h3>
                    <strong>5. How We Use Your Information</strong>
                  </h3>
                  <p>
                    We use information that we collect about you or that you
                    provide to us, including any personal information:
                  </p>
                  <ul>
                    <li>To provide the Platform and its contents to you.</li>
                    <li>
                      To process transactions and fulfill orders, including
                      billing and collections.
                    </li>
                    <li>To register a user.</li>
                    <li>
                      To provide you with marketing and promotions, and for us
                      to audit the same.
                    </li>
                    <li>
                      To provide you with information, opportunities, or
                      services, including customer support, that you request
                      from us.
                    </li>
                    <li>
                      To provide you with notices about your account,
                      promotions, partner agreement, or other agreements.
                    </li>
                    <li>
                      To carry out our obligations and rights arising from any
                      contracts entered into between you and us.
                    </li>
                    <li>To defend against claims and litigation.</li>
                    <li>
                      To enforce our policies and protect against security
                      incidents, including to prevent fraud, malicious,
                      deceptive or illegal activity.
                    </li>
                    <li>
                      To notify you about changes to the Platform or any
                      products or services we offer to provide through it.
                    </li>
                    <li>
                      To fulfill any other purpose for which you provide it.
                    </li>
                    <li>
                      In any other way we may describe when you provide the
                      information.
                    </li>
                    <li>For any purpose with your consent.</li>
                  </ul>
                  <p>
                    You may notify us to opt-out from receiving certain
                    marketing emails and each marketing email will include an
                    opt-out button or unsubscribe link.
                  </p>
                  <h3>
                    <strong>6. Disclosure of Your Information</strong>
                  </h3>
                  <p>
                    Kajabi is not in the business of selling your information.
                    We have the right to disclose aggregated information about
                    our users, and information that does not identify any
                    individual. &nbsp;We may disclose personal information that
                    we collect or you provide in accordance with this Privacy
                    Notice:
                  </p>
                  <ul>
                    <li>
                      To our subsidiaries and affiliates within our corporate
                      family.
                    </li>
                    <li>
                      To contractors, service providers, and other third parties
                      we use to support our business.
                    </li>
                    <li>
                      To a buyer or other successor in the event of a merger,
                      divestiture, restructuring, reorganization, dissolution,
                      or other sale or transfer of some or all of Kajabi's
                      assets, whether as a going concern or as part of a
                      bankruptcy, liquidation, or similar proceeding, in which
                      personal information held by Kajabi about our Platform
                      users is among the assets transferred.
                    </li>
                    <li>To fulfill the purpose for which you provide it.</li>
                    <li>
                      For any other purpose disclosed by us when you provide the
                      information.
                    </li>
                    <li>
                      To our professional advisors, including lawyers,
                      accountants, consultants, security professionals and other
                      similar parties when disclosure is reasonably necessary.
                    </li>
                    <li>With your consent.</li>
                    <li>
                      To third party advertising partners who may use data to
                      serve ads for us and provide us with analytics.
                    </li>
                    <li>
                      To third party vendors who may use data to provide you
                      additional services and products.
                    </li>
                  </ul>
                  <p>We may also disclose your personal information:</p>
                  <ul>
                    <li>
                      To comply with a subpoena, court order, administrative or
                      governmental order, or any other requirement of law.
                    </li>
                    <li>
                      If Kajabi, in its sole discretion, deems it necessary in
                      order to protect its rights or the rights of others, to
                      prevent harm to persons or property, to fight fraud and
                      credit risk.
                    </li>
                    <li>
                      To enforce or apply our Platform Terms, and other
                      agreements or policies, including for billing and
                      collection purposes.
                    </li>
                  </ul>
                  <h3>
                    <strong>7. Data Security</strong>
                  </h3>
                  <p>
                    Kajabi takes commercially reasonable steps to protect the
                    personal information provided via the Platform from loss,
                    misuse, and unauthorized access, disclosure, alteration, or
                    destruction. including but not limited to encryption in
                    transit and at rest, role based access controls, strong
                    authentication requirements, and active monitoring of all
                    information shared. However, not all Internet or e-mail
                    transmissions are fully secure or error free. In particular,
                    e-mail or forms sent to or from this Platform may not be
                    fully secure in transit. Therefore, you should take special
                    care in deciding what information you send to us via e-mail
                    or through a form. Please keep this in mind when disclosing
                    any personal information to Kajabi via the Internet. Your
                    Kajabi account personal information is protected by a
                    password and, in some scenarios, we may offer Multi-factor
                    Authentication (MFA) for your privacy and security. You are
                    responsible for protecting against unauthorized access to
                    your account and personal information by selecting and
                    protecting your password appropriately and limiting access
                    to your computer and browser by signing off after you have
                    finished accessing your account. Any transmission of
                    personal information is at your own risk. &nbsp;We are not
                    responsible for circumvention of any privacy settings or
                    security measures contained on the Platform.
                  </p>
                  <h3>
                    <strong>8. Links to Other Websites</strong>
                  </h3>
                  <p>
                    This Privacy Notice applies only to the Platform. This
                    Platform may contain links to other web sites not operated
                    or controlled by Kajabi (the “Third Party Sites”), e.g.,
                    Facebook, Twitter, Instagram, YouTube, LinkedIn, and TikTok.
                    The policies and procedures we described here do not apply
                    to the Third Party Sites. The links from this Platform do
                    not imply that Kajabi endorses or has reviewed the Third
                    Party Sites. We suggest contacting those sites directly for
                    information on their privacy policies.
                    <strong>‍</strong>
                  </p>
                  <h3>
                    <strong>9. Social Media</strong>
                  </h3>
                  <p>
                    We use social media plug-ins (e.g., Facebook, Twitter,
                    Instagram, TikTok). These features may collect your IP
                    address and/or other information as set forth in each social
                    platform’s Privacy Notice, which page you are visiting on
                    our Platform, and may set a cookie to enable the feature to
                    function properly. Kajabi also has a presence on some social
                    media platforms, including YouTube, Instagram, LinkedIn,
                    TikTok, and Facebook. Through our social media platform or
                    our accounts on social medial platforms, we may in some
                    instances collect personal information when you interact
                    with the Kajabi accounts or otherwise communicate with us
                    through those accounts or on these platforms. Any
                    information that you post on social media is governed by
                    each social platform’s privacy notices, and any personal
                    information that we collect via our social media accounts or
                    on our Kajabi forums will be processed in accordance with
                    this Privacy Notice.
                  </p>
                  <h3>
                    <strong>10. Other Terms and Conditions</strong>
                  </h3>
                  <p>
                    Your access to and use of this Platform is subject to the
                    Platform’s{" "}
                    <Link onClick={toTop} to="/terms">
                      Platform Terms
                    </Link>
                    .
                  </p>
                  <h3>
                    <strong>
                      11. Access to Information; Managing Communications;
                      Contacting Kajabi
                    </strong>
                  </h3>
                  <p>
                    To keep your personal information accurate, current, and
                    complete, please contact us as specified below. We will take
                    reasonable steps to update or correct personal information
                    in our possession that you have previously submitted via
                    this Platform. Note that you may also manage your
                    communications preferences and the receipt of any commercial
                    communication by clicking the “unsubscribe” link included at
                    the bottom of all emails from Kajabi.
                  </p>
                  <p>
                    If you have any questions or concerns regarding this Privacy
                    Notice and our privacy practices, please send us a detailed
                    message to service@philippa.store and we will make every effort
                    to resolve your concerns. You may also contact us at the
                    address below:
                  </p>
                  <p>
                    Kajabi, LLC 880 Newport Center Dr., Suite 100, Newport
                    Beach, CA 92660.
                  </p>
                  <h3>
                    <strong>
                      12. Additional Information for California Residents
                    </strong>
                  </h3>
                  <p>
                    This Additional Information for California Residents
                    supplements the information contained in the Privacy Notice
                    and applies solely to all visitors, users, and others who
                    reside in the State of California (“
                    <strong>Consumers</strong>”). We have created this notice in
                    order to comply with the California Consumer Privacy Act of
                    2018, and the later, California Privacy Rights Act of 2020
                    (collectively, “<strong>CCPA</strong>”). All capitalized
                    terms not defined in this Section XII shall have the
                    respective meanings given to them in the CCPA.
                  </p>
                  <p>
                    <strong>Categories of Information We Collect</strong>
                  </p>
                  <p>
                    We collect Personal Information as that term is defined in
                    CCPA: information that identifies, relates to, describes,
                    references, is capable of being associated with, or could
                    reasonably be linked, directly or indirectly, with a
                    particular Consumer or device. In particular, within the
                    last twelve (12) months, we have collected the following
                    categories of Personal Information from Consumers:
                  </p>
                  <ul>
                    <li>Identifiers</li>
                    <li>
                      Personal Information categories listed in the California
                      Customer Records statute (Cal. Civ. Code § 1798.80(e))
                    </li>
                    <li>
                      Protected classification characteristics under California
                      or federal law
                    </li>
                    <li>Professional or employment-related information</li>
                    <li>Commercial information</li>
                    <li>Internet or other similar network activity</li>
                    <li>Inferences drawn from other Personal Information</li>
                    <li>Geolocation Data</li>
                    <li>Messages, email, and chat data</li>
                    <li>Sensory Data</li>
                    <li>Government identifiers</li>
                    <li>Complete account access credentials</li>
                    <li>Other personal information</li>
                  </ul>
                  <p>
                    <em>Personal Information does not include:</em>
                  </p>
                  <ul>
                    <li>
                      Publicly available information from government records.
                    </li>
                    <li>Deidentified or aggregated Consumer information.</li>
                    <li>
                      Certain other information that is already regulated by
                      other laws or regulations.
                    </li>
                  </ul>
                  <p>
                    As explained in more detail here, we obtain the categories
                    of Personal Information listed above from the following
                    categories of sources:
                  </p>
                  <p>
                    <em>Directly from you</em>. For example, when you sign up on
                    the Platform or for services. This includes the following
                    categories of Personal Information:
                  </p>
                  <ul>
                    <li>Identifiers</li>
                    <li>
                      Personal Information categories listed in the California
                      Customer Records statute (Cal. Civ. Code § 1798.80(e))
                    </li>
                    <li>
                      Protected classification characteristics under California
                      or federal law
                    </li>
                    <li>Professional or employment-related information</li>
                    <li>Commercial information</li>
                    <li>Government identifiers</li>
                    <li>Complete account access credentials</li>
                    <li>Other personal information</li>
                  </ul>
                  <p>
                    <em>Indirectly</em>. For example, from observing your
                    actions on our Platform or by use of our services, including
                    by the use of cookies or from third parties. This includes
                    the following categories of Personal Information:
                  </p>
                  <ul>
                    <li>Internet or other similar network activity</li>
                    <li>Inferences drawn from other Personal Information</li>
                  </ul>
                  <p>
                    <em>
                      By observing Consumers’ behavior through their activity on
                      the Platform.
                    </em>{" "}
                    This includes the following categories of Personal
                    Information:
                  </p>
                  <ul>
                    <li>Internet or other similar network activity</li>
                    <li>Inferences drawn from other Personal Information</li>
                  </ul>
                  <p>
                    <strong>Use of Personal Information</strong>
                  </p>
                  <p>
                    We may use, or disclose the Personal Information we collect
                    for one or more of the following business purposes:
                  </p>
                  <figure
                    style={{ maxWidth: "1049pxpx" }}
                    className="w-richtext-align-fullwidth w-richtext-figure-type-image"
                  >
                    <div>
                      <img
                        src="https://cdn.prod.website-files.com/6700100176cb5aa470b9e365/6709407340ec0c383145d073_65988b8346735abf92562b01_Screenshot%25202024-01-05%2520at%25203.04.12%25E2%2580%25AFPM.png"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  </figure>
                  <p>‍</p>
                  <figure
                    style={{ maxWidth: "1046pxpx" }}
                    className="w-richtext-align-fullwidth w-richtext-figure-type-image"
                  >
                    <div>
                      <img
                        src="https://cdn.prod.website-files.com/6700100176cb5aa470b9e365/6709407340ec0c383145d058_65988bb66c2ae9acaa20a370_Sensitive%2520PII%2520Table.png"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  </figure>
                  <p>
                    Kajabi will not collect additional categories of Personal
                    Information or use the Personal Information we collected for
                    materially different, unrelated, or incompatible purposes
                    without providing you notice.
                  </p>
                  <p>
                    <strong>
                      How Long Do We Keep Your Personal information?
                    </strong>
                  </p>
                  <p>
                    <em>General Retention Periods</em>
                  </p>
                  <p>
                    We use the following criteria to determine our retention
                    periods: the amount, nature and sensitivity of your
                    information, the reasons for which we collect and process
                    your personal data, the length of time we have an ongoing
                    relationship with you and provide you with access to our
                    services and/or Platform, and applicable legal requirements.
                    We will retain personal information we collect from you
                    where we have an ongoing legitimate business need to do so
                    (for example, to comply with applicable legal, tax or
                    accounting requirements). We will also retain personal
                    information we collect from you, for your convenience, where
                    you have an active account, have not requested to delete or
                    you have asked us to retain it. Additionally, we cannot
                    delete information when it is needed for the establishment,
                    exercise or defense of legal claims (also known as a
                    “litigation hold”). In this case, the information must be
                    retained as long as needed for exercising respective
                    potential legal claims.
                  </p>
                  <p>
                    When we have no ongoing legitimate business need to process
                    your personal information, we will either delete or
                    anonymize it or, if this is not possible (for example,
                    because your personal information has been stored in backup
                    archives or you have asked us to keep it or we believe it
                    will be most convenient for you), we will securely store
                    your personal information and isolate it from any analytics
                    tracking until deletion is possible.
                  </p>
                  <p>
                    For any questions about data retention, please contact:
                    service@philippa.store.
                  </p>
                  <p>
                    <strong>Disclosure of Personal Information</strong>
                  </p>
                  <p>
                    Kajabi may disclose your Personal Information to a third
                    party for a business purpose. When we disclose Personal
                    Information for a business purpose, we enter a contract that
                    describes the purpose and requires the recipient to both
                    keep that Personal Information confidential and not use it
                    for any purpose except performing the services for us.
                  </p>
                  <p>
                    As explained in more detail above, we share your Personal
                    Information with certain categories of third parties who
                    assist us in providing our Platform and products and with
                    our business.
                  </p>
                  <p>
                    <em>
                      Disclosures of Personal Information for a Business Purpose
                    </em>
                  </p>
                  <p>
                    In the preceding twelve (12) months, Kajabi has disclosed
                    the following categories of Personal Information for a
                    business purpose:
                  </p>
                  <ul>
                    <li>Identifiers</li>
                    <li>
                      Personal Information categories listed in the California
                      Customer Records statute (Cal. Civ. Code § 1798.80(e))
                    </li>
                    <li>Commercial information</li>
                    <li>Internet or other similar network activity</li>
                    <li>Inferences drawn from other Personal Information</li>
                    <li>
                      Protected classification characteristics under California
                      or federal law
                    </li>
                    <li>Government identifiers</li>
                    <li>Complete account access credentials</li>
                    <li>Other personal information</li>
                  </ul>
                  <p>
                    We disclose your Personal Information for a business purpose
                    to the following categories of service providers or third
                    parties:
                  </p>
                  <ul>
                    <li>
                      Companies that do things to help us provide the Platform
                      and/or our services: hosting service providers, user
                      engagement and email service providers, certain analytics
                      providers, payment service providers, communication tools,
                      identity verification providers or other tools we may
                      provide you.
                    </li>
                    <li>
                      Professional service providers, such as auditors, lawyers,
                      consultants, accountants and insurers.
                    </li>
                  </ul>
                  <p>
                    <strong>Your Rights and Choices</strong>
                  </p>
                  <p>
                    The CCPA provides Consumers with specific rights regarding
                    their Personal Information, provided that we are able to
                    verify their identities as explained below. This section
                    describes some of your CCPA rights and explains how to
                    exercise those rights.
                  </p>
                  <p>
                    <em>
                      Access to Specific Information and Data Portability Rights
                    </em>{" "}
                  </p>
                  <p>
                    You have the right to request that we disclose certain
                    information to you about our collection and use of your
                    Personal Information over the past 12 months. Once we
                    receive your request and verify your identity (see
                    Exercising Access, Data Portability, and Deletion Rights),
                    we will disclose to you:
                  </p>
                  <ul>
                    <li>
                      The categories of Personal Information we collected about
                      you.
                    </li>
                    <li>
                      The categories of sources for the Personal Information we
                      collected about you.
                    </li>
                    <li>
                      Our business or commercial purpose for collecting or
                      selling that Personal Information.
                    </li>
                    <li>
                      The categories of third parties with whom we share that
                      Personal Information.
                    </li>
                    <li>
                      The specific pieces of Personal Information we collected
                      about you (also called a data portability request).
                    </li>
                    <li>
                      If we sold or disclosed your Personal Information for a
                      business purpose, two separate lists disclosing:
                    </li>
                  </ul>
                  <ul>
                    <li>
                      sales, identifying the Personal Information categories
                      that each category of recipient purchased; and
                    </li>
                    <li>
                      disclosures for a business purpose, identifying the
                      Personal Information categories that each category of
                      recipient obtained.
                    </li>
                  </ul>
                  <p>
                    <em>Deletion Request and Correction Request Rights</em>
                  </p>
                  <p>
                    You have the right to request that we delete or correct any
                    of your Personal Information that we collected from you and
                    retained, subject to certain exceptions. Once we receive
                    your request and verify your identity (see Exercising
                    Access, Data Portability, and Deletion Rights), we will
                    delete (and direct our service providers to delete) or
                    correct (and direct our service providers to correct) your
                    Personal Information from our records, unless an exception
                    applies. As an alternative to correction, we may delete the
                    inaccurate information if it does not negatively impact you
                    or if you consent to this deletion. Kajabi reserves the
                    right to deny a correction request if allowed under the law
                    or if we determine that the contested information is more
                    likely than not accurate, based on the totality of
                    circumstances. We may deny your deletion request if
                    retaining the information is necessary for us or our service
                    provider(s) to:
                  </p>
                  <ol>
                    <li>
                      Complete the transaction for which the Personal
                      Information was collected, fulfill the terms of a written
                      warranty or product recall conducted in accordance with
                      federal law, provide products or service requested by you,
                      or reasonably anticipated within the context of our
                      ongoing business relationship with you, or otherwise
                      perform our contract with you.
                    </li>
                    <li>
                      Detect security incidents, protect against malicious,
                      deceptive, fraudulent, or illegal activity, or prosecute
                      those responsible for such activities.
                    </li>
                    <li>
                      Debug products to identify and repair errors that impair
                      existing intended functionality.
                    </li>
                    <li>
                      Exercise free speech, ensure the right of another Consumer
                      to exercise that Consumer’s right of free speech, or
                      exercise another right provided for by law.
                    </li>
                    <li>
                      Comply with the California Electronic Communications
                      Privacy Act (Cal. Penal Code § 1546 <em>et. seq.</em>).
                    </li>
                    <li>
                      Enable solely internal uses that are reasonably aligned
                      with Consumer expectations based on your relationship with
                      us.
                    </li>
                    <li>Comply with a legal obligation.</li>
                    <li>
                      Make other internal and lawful uses of that information
                      that are compatible with the context in which you provided
                      it.
                    </li>
                  </ol>
                  <p>
                    <em>
                      Limit Use and Disclosure of Sensitive Personal Information
                    </em>
                  </p>
                  <p>
                    You have the right to request that we delete any of your
                    Sensitive Personal Information that we collected from you
                    and retained, subject to certain exceptions. Once we receive
                    your request and verify your identity (see Exercising
                    Access, Data Portability, and Deletion Rights), we will
                    delete (and direct our service providers to delete) your
                    Personal Information from our records, unless an exception
                    applies. We may deny your request to limit certain uses and
                    disclosures of your Sensitive Personal Information for the
                    following purposes:&nbsp;
                  </p>
                  <ol>
                    <li>
                      Providing the Platform or our products or services as
                      reasonably expected of an average Consumer;
                    </li>
                    <li>
                      Detecting security incidents affecting personal
                      information on our Platform, provided that the use of the
                      Consumer’s Sensitive Personal Information is reasonably
                      necessary and proportionate for this purpose;
                    </li>
                    <li>
                      Resisting malicious, fraudulent, or illegal actions
                      against us and prosecuting persons responsible for such
                      actions, provided that the use of the Consumer’s Sensitive
                      Personal Information is reasonably necessary and
                      proportionate for this purpose;
                    </li>
                    <li>
                      Ensuring the physical safety of an individual, provided
                      that the use of the Consumer’s Sensitive Personal
                      Information is reasonably necessary and proportionate for
                      this purpose;
                    </li>
                    <li>
                      Short-term, transient use, including non-personalized
                      advertising shown as part of a Consumer’s current
                      interaction with our Platform, provided that we do not
                      build a profile about the Consumer or alter the Consumer’s
                      experience outside their current interaction with us;
                    </li>
                    <li>
                      Performing services on behalf of another business (
                      <em>g.</em>, maintaining accounts, processing orders or
                      transaction);
                    </li>
                    <li>
                      Verifying or maintaining the quality or safety of a
                      service or device that is owned, manufactured,
                      manufactured for, or controlled by us, or improving,
                      upgrading, or enhancing the services or device owned,
                      manufactured, manufactured for, or controlled by us; or
                    </li>
                    <li>
                      To collect or process sensitive personal information where
                      such collection or processing is not for the purpose of
                      inferring characteristics about a consumer.
                    </li>
                  </ol>
                  <p>
                    <em>
                      Exercising Access, Data Portability, and Deletion Rights
                    </em>{" "}
                  </p>
                  <p>
                    Only you, or a person registered with the California
                    Secretary of State that you authorize to act on your behalf,
                    may make a verifiable Consumer request related to your
                    Personal Information. You may also make a verifiable
                    Consumer request on behalf of your minor child.
                  </p>
                  <p>
                    An authorized agent is a natural person or a business entity
                    registered with the Secretary of State that a Consumer has
                    authorized to act on his or her behalf. When a Consumer uses
                    an authorized agent to submit a request to know or a request
                    to delete, we may require that the Consumer provide the
                    authorized agent written permission to do so and verify
                    their own identity directly with us, unless the Consumer has
                    provided the authorized agent with a valid power of
                    attorney. We may deny a request from an agent that does not
                    submit proof that they have been authorized by the Consumer
                    to act on his or her behalf.
                  </p>
                  <p>
                    To exercise the access, data portability, and deletion
                    rights described above, please submit a verifiable request
                    to us by either: toll free call at{" "}
                    <strong>(800)-452-5224</strong> or online at{" "}
                    <Link
                      onClick={toTop}
                      to="https://kajabi.com/info/data-request"
                    >
                      <strong>Data Request Portal</strong>
                    </Link>
                    . You may only make a verifiable Consumer request for access
                    or data portability twice within a 12-month period. The
                    verifiable Consumer request must:
                  </p>
                  <ul>
                    <li>
                      Provide sufficient information that allows us to
                      reasonably verify you are the person about whom we
                      collected Personal Information or an authorized
                      representative.
                    </li>
                    <li>
                      Describe your request with sufficient detail that allows
                      us to properly understand, evaluate, and respond to it.
                    </li>
                  </ul>
                  <p>
                    <strong>
                      We cannot respond to your request or provide you with
                      Personal Information if we cannot verify your identity or
                      authority to make the request and confirm the Personal
                      Information relates to you.
                    </strong>
                  </p>
                  <p>
                    Making a verifiable Consumer request does not require you to
                    create an account with us. However, we do consider requests
                    made through our portal with a verified email as a
                    reasonable step toward verification when the request relates
                    to Personal Information associated with that specific
                    account.. Please note that the methods for verification are
                    set forth in the CCPA, which also requires us to consider a
                    number of factors, such as type, sensitivity and value of
                    the Personal Information or risk of harm posed by
                    unauthorized access or deletion, on a case-by-case basis.
                  </p>
                  <p>
                    We will only use Personal Information provided in a
                    verifiable Consumer request to verify the requestor’s
                    identity or authority to make the request.
                  </p>
                  <p>
                    <em>Response Timing and Format</em>
                  </p>
                  <p>
                    We endeavor to respond to a verifiable Consumer request
                    within forty-five (45) days of its receipt. If we require
                    more time (up to 90 days), we will inform you of the reason
                    and extension period in writing.
                  </p>
                  <p>
                    If you have an account with us, we will deliver our written
                    response to that account. If you do not have an account with
                    us, we will deliver our written response by mail or
                    electronically, at your option.
                  </p>
                  <p>
                    Any disclosures we provide will only cover the 12-month
                    period preceding our receipt of the verifiable Consumer’s
                    request. If applicable, our response we provide will also
                    explain the reasons we cannot comply with a request. For
                    data portability requests, we will select a format to
                    provide your Personal Information that is readily useable
                    and should allow you to transmit the information from one
                    entity to another entity without hindrance.
                  </p>
                  <p>
                    We do not charge a fee to process or respond to your
                    verifiable Consumer request unless it is excessive,
                    repetitive, or manifestly unfounded. If we determine that
                    the request warrants a fee, we will tell you why we made
                    that decision and provide you with a cost estimate before
                    completing your request.
                  </p>
                  <p>
                    <em>Sale; Non-Discrimination</em>
                  </p>
                  <p>
                    Kajabi does not sell personal information. However, certain
                    privacy laws and regulations can define "sale"&nbsp;to
                    broadly include nearly all online business activity. For
                    example, under the CCPA, “personal information” includes
                    information that is not necessarily directly tied to an
                    individual’s identity but may be associated with a device.
                    This includes identifiers such as IP addresses, web cookies,
                    web beacons, and mobile Ad IDs. In many cases, this type of
                    information does not directly identify you, but they are
                    unique identifiers that could be linked to you and therefore
                    covered by the CCPA. The term “sell” is broadly defined by
                    the CCPA to include not just selling in exchange for money,
                    but also sharing personal information (including information
                    that does not directly identify an individual as described
                    above) in exchange for anything of value. Under the CCPA’s
                    broad definition of “sell,” which includes even the common
                    flow of information in the digital analytics and advertising
                    ecosystem, Kajabi could be defined as selling personal
                    information. Like most companies that operate websites,
                    Kajabi uses online analytics to measure the ways users
                    engage with our Platform and to provide targeted marketing.
                    These analytics, in turn, inform how we perform online
                    advertising. In order to provide these analytics and
                    facilitate online advertising, we use third-parties that
                    collect device identifiers and place tags, cookies, beacons,
                    and similar tracking mechanisms on our digital properties
                    and on third-party digital properties as set forth in our{" "}
                    <Link onClick={toTop} to="/cookie">
                      Cookie Notice
                    </Link>
                    . &nbsp;You may opt-out of this sharing by{" "}
                    <Link onClick={toTop} to="https://kajabi.com/info/my-data">
                      clicking here
                    </Link>
                    .
                  </p>
                  <p>
                    We will not discriminate against you in a manner prohibited
                    by the CCPA because you exercise your CCPA rights. While you
                    may request to delete your Personal Information under CCPA,
                    such deletions may affect Kajabi’s ability to offer the
                    Platform or to sell our products to you.
                  </p>
                  <h3>
                    <strong>
                      13. Additional Information for European Economic Area
                      (“EEA”) or United Kingdom (“U.K.”) Residents.
                    </strong>
                  </h3>
                  <p>
                    This Additional Information for EEA and U.K. Residents
                    supplements the information contained in the Privacy Notice
                    and applies solely to all visitors, users, and others who
                    reside in EEA and U.K. We have created this notice in order
                    to comply with the European Union’s General Data Protection
                    Regulation and later U.K. General Data Protection Regulation
                    (collectively, “GDPR”). All capitalized terms not defined in
                    this Section XIII shall have the respective meanings given
                    to them in the GDPR.&nbsp;
                  </p>
                  <p>
                    <strong>Categories of Recipients of Personal Data</strong>
                  </p>
                  <p>
                    The categories of recipients of Personal Data with whom we
                    may share your personal data are listed in Disclosure of
                    Your Personal Information above.
                  </p>
                  <p>
                    <strong>Purpose of the Processing and Legal Bases</strong>
                  </p>
                  <p>
                    Kajabi processes your personal information for a number of
                    different purposes. Some are essential for us to provide the
                    Platform you use or to fulfill our legal obligations, some
                    help us run the Platform efficiently and effectively, and
                    some enable us to provide you with more relevant and
                    personalized offers and information. In all cases we must
                    have a reason and a legal ground for processing your
                    personal information. Some of the most common legal grounds
                    we rely on are briefly explained below.
                  </p>
                  <ul>
                    <li>
                      <strong>Performance of a Contract:</strong> We may process
                      your personal data for the purpose of performing under the
                      terms of a contract to which you are a party - in other
                      words, your ability to use the Platform. For instance, if
                      you process payments for your end customers through the
                      Platform, we will process your data in order to carry out
                      the payment transaction.
                    </li>
                    <li>
                      <strong>Legitimate Interests</strong> We may process
                      personal data where it is necessary for our legitimate
                      business interests, but only to the extent that they are
                      not outweighed by your own interests or fundamental rights
                      and freedoms. We generally rely on legitimate interests to
                      provide and maintain the Platform that work well and
                      securely, to carry out fraud prevention, and to generally
                      improve the Platform. When we rely on this legal basis, if
                      required, we will carry out a legitimate interest
                      assessment to ensure we consider and balance any potential
                      impact on you (both positive and negative), and your
                      rights under data protection laws.
                    </li>
                    <li>
                      <strong>Consent:</strong> Kajabi will rely on consent
                      where it is required, such as when we are asking you to
                      confirm your marketing preferences or when you submit
                      sensitive data. When we rely on consent, you will be asked
                      to confirm that you give your permission to Kajabi to
                      process your personal data. You have the right to withdraw
                      your consent at any time if you no longer want to be part
                      of the Kajabi processing activity where your consent was
                      sought.
                    </li>
                    <li>
                      <strong>Legal Obligation</strong> Kajabi will on occasion
                      be under a legal obligation to obtain and disclose your
                      personal data or may cooperate in a legal or governmental
                      investigation. Where possible, we will notify you when
                      processing your data due to a legal obligation; however,
                      this may not always be possible. Kajabi may determine that
                      it needs to provide your data in order to prevent criminal
                      activity or help to detect criminal activity; in which
                      case, we may share information with law enforcement
                      without notifying you. It is essential that Kajabi
                      complies with its legal, regulatory, and contractual
                      requirements, so if you object to this processing, Kajabi
                      will not be able to provide its Platform to you.
                    </li>
                  </ul>
                  <p>
                    Below are the general purposes and corresponding legal bases
                    (in brackets) for which we may use your personal
                    information:
                  </p>
                  <ul>
                    <li>
                      <strong>
                        Providing you access to and use of the Platform
                      </strong>{" "}
                      [depending on the context, performance of a contract,
                      legitimate interests, and in some cases, legal claims].
                    </li>
                    <li>
                      <strong>
                        Processing and completing transactions, and sending you
                        related information, including purchase confirmations
                        and invoices and important notices
                      </strong>{" "}
                      [depending on the context, performance of a contract or
                      legitimate interests].
                    </li>
                    <li>
                      <strong>
                        Responding to your queries and requests, or otherwise
                        communicating directly with you
                      </strong>{" "}
                      [depending on the context, performance of a contract,
                      legitimate interests, and in some cases, legal claims].
                    </li>
                    <li>
                      <strong>
                        Improving the content and general administration of the
                        Platform, including system maintenance and upgrades,
                        enabling new features and enhancements{" "}
                      </strong>
                      [legitimate interests].
                    </li>
                    <li>
                      <strong>
                        Detecting fraud, illegal activities or security breaches
                      </strong>{" "}
                      [legitimate interests].
                    </li>
                    <li>
                      <strong>Ensuring compliance with applicable laws</strong>{" "}
                      [compliance with a legal obligation].
                    </li>
                    <li>
                      <strong>
                        Conducting statistical analyses and analytics by
                        monitoring and analyzing trends, usage, and activities
                        in connection with the Platform
                      </strong>{" "}
                      [consent where required (e.g. 3rd-party cookies), or
                      legitimate interests].
                    </li>
                    <li>
                      <strong>
                        Increasing the number of customers who use our Platform
                        through marketing and advertising
                      </strong>{" "}
                      [consent where required, or legitimate interests].
                    </li>
                    <li>
                      <strong>
                        Sending commercial communications, in line with your
                        communication preferences, about products and services,
                        features, newsletters, offers, promotions, and events
                      </strong>{" "}
                      [consent and in some cases, depending on location, with
                      existing customers, legitimate interests].
                    </li>
                    <li>
                      <strong>
                        Providing information to regulatory bodies when legally
                        required, and only as outlined below in this Privacy
                        Notice
                      </strong>{" "}
                      [legal obligation, legal claims, legitimate interests].
                    </li>
                  </ul>
                  <p>
                    <strong>How Long Do We Keep Your Personal Data?</strong>
                  </p>
                  <p>
                    We use the following criteria to determine our retention
                    periods: the amount, nature and sensitivity of your
                    information, the reasons for which we collect and process
                    your personal data, the length of time we have an ongoing
                    relationship with you and provide you with access to our
                    Platform, and applicable legal requirements. We will retain
                    personal information we collect from you where we have an
                    ongoing legitimate business need to do so (for example, to
                    comply with applicable legal, tax, or accounting
                    requirements), when we are unable to reasonably verify your
                    identity, or as may otherwise be required under GDPR.
                    Additionally, we cannot delete information when it is needed
                    for the establishment, exercise, or defense of legal claims
                    (also known as a “litigation hold”). In this case, the
                    information must be retained as long as needed for
                    exercising respective potential legal claims.
                  </p>
                  <p>
                    When we have no ongoing business need to process your
                    personal information, we will either delete or anonymize it.
                    If you have questions about, or need further information
                    concerning, our data retention periods, please send an email
                    at{" "}
                    <Link onClick={toTop} to="mailto:service@philippa.store">
                      service@philippa.store
                    </Link>
                    .&nbsp;
                  </p>
                  <p>
                    <strong>
                      Staying in Control of Your Information: Your Rights
                    </strong>
                  </p>
                  <p>
                    If you are in the EEA or the U.K., you have certain rights
                    in relation to your personal data, including those set forth
                    below. Please note that in some circumstances, we may not be
                    able to fully comply with your request, such as if it is
                    frivolous or extremely impractical, if it jeopardizes the
                    rights of others, if an exception applies, or if it is not
                    required by law, but in those circumstances, we will still
                    respond to notify you of such a decision. In some cases, we
                    may also need you to provide us with additional information,
                    which may include personal data, if necessary to verify your
                    identity and the nature of your request.
                  </p>
                  <ul>
                    <li>
                      <strong>Access</strong>: You can request more information
                      about the personal data we hold about you and request a
                      copy of such personal data.
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <strong>Rectification</strong>: If you believe that any
                      personal data we are holding about you is incorrect or
                      incomplete, you can request that we correct or supplement
                      such data.
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <strong>Erasure</strong>: You can request that we erase
                      some or all of your personal data from our systems.
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <strong>Withdrawal of Consent</strong>: If we are
                      processing your personal data based on your consent (as
                      indicated at the time of collection of such data), you
                      have the right to withdraw your consent at any time.
                      Please note, however, that if you exercise this right, you
                      may have to then provide consent on a case-by-case basis
                      for the use or disclosure of certain of your personal
                      data, if such use or disclosure is necessary to enable you
                      to utilize some or all of our Platform.
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <strong>Portability</strong>: You can ask for a copy of
                      your personal data in a machine-readable format. You can
                      also request that we transmit the data to another
                      controller where technically feasible.
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <strong>Objection</strong>: You can contact us to let us
                      know that you object to the further use or disclosure of
                      your personal data for certain purposes, such as for
                      direct marketing purposes.
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <strong>Restriction of Processing</strong>: You can ask us
                      to restrict further processing of your personal data.
                    </li>
                  </ul>
                  <p>
                    You will not have to pay a fee to access your personal data
                    (or to exercise any of the other rights) unless your request
                    is clearly unfounded, repetitive, or excessive.
                    Alternatively, we may refuse to comply with your request
                    under those circumstances.
                  </p>
                  <p>
                    We will respond to all legitimate requests within one month.
                    Occasionally, it may take us longer than a month if your
                    request is particularly complex or you have made a number of
                    requests. In this case, we will notify you and keep you
                    updated as required by law.
                  </p>
                  <p>
                    In addition, if you no longer wish to receive our
                    marketing/promotional information, we remind you that you
                    may withdraw your consent to direct marketing at any time
                    directly from the unsubscribe link included in each
                    electronic marketing message we send to you. If you do so,
                    we will promptly update our databases, and will take all
                    reasonable steps to meet your request at the earliest
                    possible opportunity, but we may continue to contact you to
                    the extent necessary for the purposes of providing our
                    Platform.
                  </p>
                  <p>
                    Finally, you have the right to make a complaint at any time
                    to the supervisory authority for data protection issues in
                    your country of residence. A list of Supervisory Authorities
                    is available here:{" "}
                    <Link
                      onClick={toTop}
                      to="https://edpb.europa.eu/about-edpb/board/members_en"
                    >
                      https://edpb.europa.eu/about-edpb/board/members_en
                    </Link>
                    . We would, however, appreciate the chance to address your
                    concerns before you approach the supervisory authority, so
                    please contact us directly first.
                  </p>
                  <p>
                    <strong>Exercising Your Rights</strong>
                  </p>
                  <p>
                    To exercise the access, data portability, and deletion
                    rights described above, please submit a verifiable request
                    to us by either: email at{" "}
                    <Link onClick={toTop} to="mailto:service@philippa.store">
                      <strong>service@philippa.store</strong>
                    </Link>{" "}
                    or online at{" "}
                    <Link
                      onClick={toTop}
                      to="https://kajabi.com/info/data-request"
                    >
                      <strong>Data Request Portal</strong>
                    </Link>
                    .
                  </p>
                  <p>
                    <strong>
                      Contacting our Representative in the EEA or the U.K.
                    </strong>
                  </p>
                  <ul>
                    <li>
                      <strong>If you are in the EEA</strong>: Kajabi has
                      appointed the European Data Protection Office (EDPO) as
                      its European Representative pursuant to Article 27 of the
                      GDPR. You can contact EDPO regarding matters pertaining to
                      the GDPR:
                    </li>
                  </ul>
                  <ul>
                    <li>
                      by using EDPO’s online request form:{" "}
                      <Link
                        onClick={toTop}
                        to="https://edpo.com/gdpr-data-request/"
                      >
                        https://edpo.com/gdpr-data-request/
                      </Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      by writing to EDPO at Avenue Huart Hamoir 71, 1030
                      Brussels, Belgium
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <strong>If you are in the U.K.</strong>: Kajabi has
                      appointed EDPO UK Ltd as its representative in the U.K.
                      pursuant to the Data Protection Act 2018 (“U.K. GDPR”).
                      You can contact EDPO UK regarding matters pertaining to
                      the U.K. GDPR:
                    </li>
                  </ul>
                  <ul>
                    <li>
                      by using EDPO’s online request form:{" "}
                      <Link
                        onClick={toTop}
                        to="https://edpo.com/uk-gdpr-data-request/"
                      >
                        https://edpo.com/uk-gdpr-data-request/
                      </Link>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      by writing to EDPO UK at 8 Northumberland Avenue, London
                      WC2N 5BY, United Kingdom
                    </li>
                  </ul>
                  <h3>
                    <strong>14. International Transfers</strong>
                  </h3>
                  <p>
                    In compliance with the EU-U.S. DPF and the UK Extension to
                    the EU-U.S. DPF and the Swiss-U.S. DPF, Kajabi commits to
                    cooperate and comply respectively with the advice of the
                    panel established by the EU data protection authorities
                    (DPAs) and the UK Information Commissioner’s Office (ICO)
                    and the Swiss Federal Data Protection and Information
                    Commissioner (FDPIC) with regard to unresolved complaints
                    concerning our handling of personal data received in
                    reliance on the EU-U.S. DPF and the UK Extension to the
                    EU-U.S. DPF and the Swiss-U.S. DPF.
                  </p>
                  <p>
                    Kajabi complies with the EU-U.S. Data Privacy Framework
                    (EU-U.S. DPF) and the UK Extension to the EU-U.S. DPF, and
                    the Swiss-U.S. Data Privacy Framework (Swiss-U.S. DPF) as
                    set forth by the U.S. Department of Commerce.&nbsp; Kajabi
                    has certified to the U.S. Department of Commerce that it
                    adheres to the EU-U.S. Data Privacy Framework Principles
                    (EU-U.S. DPF Principles) with regard to the processing of
                    personal data received from the European Union and the
                    United Kingdom in reliance on the EU-U.S. DPF and the UK
                    Extension to the EU-U.S. DPF. Kajabi has certified to the
                    U.S. Department of Commerce that it adheres to the
                    Swiss-U.S. Data Privacy Framework Principles (Swiss-U.S. DPF
                    Principles) with regard to the processing of personal data
                    received from Switzerland in reliance on the Swiss-U.S.
                    DPF.&nbsp; If there is any conflict between the terms in
                    this privacy policy and the EU-U.S. DPF Principles and/or
                    the Swiss-U.S. DPF Principles, the Principles shall
                    govern.&nbsp; To learn more about the Data Privacy Framework
                    (DPF) Program, and to view our certification, please visit
                    https://www.dataprivacyframework.gov/
                  </p>
                  <p>
                    Further, Kajabi's compliance with the DPF is subject to the
                    investigatory and enforcement powers of the U.S. Federal
                    Trade Commission. In accordance with the DPF, Kajabi is also
                    liable for onward transfers to third parties that process
                    personal information in a way that does not follow the DPF
                    unless Kajabi was not responsible for the event giving rise
                    to any alleged damage.
                  </p>
                  <p>
                    <strong>
                      If you are accessing or using our Platform or otherwise
                      providing personal information to us, you are agreeing and
                      consenting to the processing of your personal information
                      in the United States and other jurisdictions in which we
                      operate.
                    </strong>
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </section>
        </main>

        <Footer />
      </div>
    </body>
  );
}

export default Privacy;
