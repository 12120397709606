import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/contactus.css";

function Contact() {
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [category, setCategory] = useState("Forgot Email");
  const [resetEmails, setResetEmails] = useState("");
  const [spamChecked, setSpamChecked] = useState("No");
  const [kajabiUrl, setKajabiUrl] = useState("");
  const [permission, setPermission] = useState("No");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [file, setFile] = useState(null);

  const [errors, setErrors] = useState({
    email: false,
    subject: false,
    resetEmails: false,
  });

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handleSubjectChange = (e) => setSubject(e.target.value);
  const handleCategoryChange = (e) => setCategory(e.target.value);
  const handleResetEmailsChange = (e) => setResetEmails(e.target.value);
  const handleSpamCheckedChange = (e) => setSpamChecked(e.target.value);
  const handleKajabiUrlChange = (e) => setKajabiUrl(e.target.value);
  const handlePermissionChange = (e) => setPermission(e.target.value);
  const handleAdditionalInfoChange = (e) => setAdditionalInfo(e.target.value);

  const handleFileChange = (e) => setFile(e.target.files[0]);

  const validateForm = () => {
    let formIsValid = true;
    let newErrors = { ...errors };

    if (!email) {
      newErrors.email = "Email is required.";
      formIsValid = false;
    }
    if (!subject) {
      newErrors.subject = "Subject is required.";
      formIsValid = false;
    }
    if (!resetEmails) {
      newErrors.resetEmails =
        "Please provide the email addresses you tried to reset.";
      formIsValid = false;
    }

    setErrors(newErrors);
    return formIsValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      alert("Form submitted successfully!");
    } else {
      alert("Please fill all fields!");
    }
  };

  return (
    <body className="body-visible">
      <Header />
      <main role="main" className="contact_main">
        <div className="mx-auto max-w-screen-xl px-4 mt-8 mb-16">
          <h1 className="request-form-title text-5xl font-extrabold mt-4 mb-4 text-warm-gray-400 dark:text-zinc-50 leading-tight">
            Trouble logging in to Kajabi?
          </h1>
          <div>
            <div className="container">
              <div id="main-content" className="form">
                <div
                  id="new_request"
                  className="request-form"
                  data-gtm-form-interact-id={0}
                  style={{ display: "block" }}
                >
                  <input
                    name="utf8"
                    type="hidden"
                    defaultValue="✓"
                    autoComplete="off"
                  />

                  <div className="form-field string required request_anonymous_requester_email">
                    <label htmlFor="request_anonymous_requester_email">
                      Your email address
                    </label>
                    <input
                      type="text"
                      name="request[anonymous_requester_email]"
                      id="request_anonymous_requester_email"
                      value={email}
                      onChange={handleEmailChange}
                      aria-required="true"
                    />
                    {errors.email && (
                      <span className="error">{errors.email}</span>
                    )}
                  </div>

                  <div className="form-field string required request_subject">
                    <label id="request_subject_label" htmlFor="request_subject">
                      Summarize your request in a few words
                    </label>
                    <input
                      type="text"
                      name="request[subject]"
                      id="request_subject"
                      value={subject}
                      onChange={handleSubjectChange}
                      maxLength={150}
                      size={150}
                      aria-required="true"
                      aria-labelledby="request_subject_label"
                    />
                    {errors.subject && (
                      <span className="error">{errors.subject}</span>
                    )}
                  </div>

                  <div className="form-field string required request_custom_fields_28269572389915">
                    <label
                      id="request_custom_fields_28269572389915_label"
                      htmlFor="request_custom_fields_28269572389915"
                    >
                      Choose a category
                    </label>
                    <select
                      className="contact_select"
                      value={category}
                      onChange={handleCategoryChange}
                    >
                      <option value="Forgot Email">Forgot Email</option>
                      <option value="Forgot Password">Forgot Password</option>
                      <option value="MFA (Multi-Factor Authentication)">
                        MFA (Multi-Factor Authentication)
                      </option>
                    </select>
                  </div>

                  <div className="form-field text required request_description">
                    <label
                      id="request_description_label"
                      htmlFor="request_description"
                    >
                      Which email address(es) did you try to reset?
                    </label>
                    <textarea
                      name="request[description]"
                      id="request_description"
                      value={resetEmails}
                      onChange={handleResetEmailsChange}
                      aria-required="true"
                      aria-labelledby="request_description_label"
                    />
                    {errors.resetEmails && (
                      <span className="error">{errors.resetEmails}</span>
                    )}
                  </div>

                  <div className="form-field string required request_custom_fields_22980912215579">
                    <label
                      id="request_custom_fields_22980912215579_label"
                      htmlFor="request_custom_fields_22980912215579"
                    >
                      Did you check the spam/junk folder for the above email
                      address(es)?
                    </label>
                    <select
                      className="contact_select"
                      value={spamChecked}
                      onChange={handleSpamCheckedChange}
                    >
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>

                  <div className="form-field string optional request_custom_fields_23008204699547">
                    <label
                      id="request_custom_fields_23008204699547_label"
                      htmlFor="request_custom_fields_23008204699547"
                    >
                      What is the URL of your Kajabi website?
                      <span className="optional">(optional)</span>
                    </label>
                    <input
                      type="text"
                      name="request[custom_fields][23008204699547]"
                      id="request_custom_fields_23008204699547"
                      value={kajabiUrl}
                      onChange={handleKajabiUrlChange}
                    />
                  </div>

                  <div className="form-field string required request_custom_fields_1260827704249">
                    <label
                      id="request_custom_fields_1260827704249_label"
                      htmlFor="request_custom_fields_1260827704249"
                    >
                      Does our team have permission to access your Kajabi
                      account to assist with your request?
                    </label>
                    <select
                      className="contact_select"
                      value={permission}
                      onChange={handlePermissionChange}
                    >
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>

                  <div className="form-field text optional request_custom_fields_23008319188635">
                    <label
                      id="request_custom_fields_23008319188635_label"
                      htmlFor="request_custom_fields_23008319188635"
                    >
                      Is there anything else you'd like us to know?
                      <span className="optional">(optional)</span>
                    </label>
                    <textarea
                      name="request[custom_fields][23008319188635]"
                      id="request_custom_fields_23008319188635"
                      value={additionalInfo}
                      onChange={handleAdditionalInfoChange}
                    />
                  </div>

                  <div className="form-field">
                    <label htmlFor="request-attachments">
                      Attachments<span className="optional">(optional)</span>
                    </label>
                    <div id="upload-dropzone" className="upload-dropzone">
                      <input
                        type="file"
                        multiple="true"
                        id="request-attachments"
                        data-fileupload="true"
                        data-dropzone="upload-dropzone"
                        onChange={handleFileChange}
                      />
                      <span>
                        <a>Add file</a> or drop files here
                      </span>
                    </div>
                  </div>

                  <footer>
                    <input
                      type="submit"
                      name="commit"
                      value="Submit"
                      onClick={handleSubmit}
                    />
                  </footer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </body>
  );
}

export default Contact;
